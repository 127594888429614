import {
  UPDATE_USER_PROFILE,
  UPDATE_USER_PROFILE_SUCCESS,
  UPDATE_USER_CONFIG_SUCCESS,
  FAIL_TO_UPDATE_DATA,
  GET_USER_PROFILE_SUCCESS,
  ON_ADD_USER_TEAM_SUCCESS,
  ON_ADD_USER_MEET_SUCCESS,
} from "../../../constants/ActionTypes";

import { baseURL } from '../../../util/config'

const INIT_STATE = {
  profile: {},
  config: {},
  defaultSetting: {},
  teamsMetadata: [],
  meetsMetadata: {},
  roles: [],
  features: {}
};


export default (state = INIT_STATE, action) => {
  let profile = {}
  if (action.payload && action.payload.firstName) {
    const { username, firstName, lastName, email, phone, avatar, url, displayName, socialHandler } = action.payload
    profile = {
      username,
      displayName: displayName || `${firstName} ${lastName}`,
      firstName,
      lastName,
      email,
      phone,
      url,
      socialHandler,
      avatar: avatar ? `${avatar.url}` : undefined
    }
  }
  let config = {}
  if (action.payload && action.payload.config) config = action.payload.config
  let roles = []
  if (action.payload && action.payload.roles) roles = action.payload.roles
  let features = {}
  if (action.payload && action.payload.features) features = action.payload.features
  let defaultSetting = {}
  if (action.payload && action.payload.defaultSetting) defaultSetting = action.payload.defaultSetting
  let teamsMetadata = []
  if (action.payload && action.payload.teamsMetadata) teamsMetadata = action.payload.teamsMetadata
  let meetsMetadata = {}
  if (action.payload && action.payload.meetsMetadata) meetsMetadata = action.payload.meetsMetadata
  switch (action.type) {
    case GET_USER_PROFILE_SUCCESS: {
      return {
        ...state,
        profile,
        config,
        defaultSetting,
        teamsMetadata,
        meetsMetadata,
        roles,
        features
      }
    }
    case ON_ADD_USER_TEAM_SUCCESS: {
      return {
        ...state,
        teamsMetadata: { ...state.teamsMetadata, ...teamsMetadata }
      }
    }
    case ON_ADD_USER_MEET_SUCCESS: {
      return {
        ...state,
        meetsMetadata: { ...state.meetsMetadata, ...meetsMetadata }
      }
    }
    case UPDATE_USER_PROFILE: {
      return {
        ...state,
        profile,
        config,
        defaultSetting
      }
    }
    case UPDATE_USER_PROFILE_SUCCESS: {
      return {
        ...state,
        profile,
        config,
        defaultSetting,
        teamsMetadata,
        meetsMetadata
      }
    }
    case UPDATE_USER_CONFIG_SUCCESS: {
      return {
        ...state,
        config: action.payload,
      }
    }
    case FAIL_TO_UPDATE_DATA: {
      return {
        ...state
      }
    }
    default:
      return state;
  }
}
