import { useMutation, useQueryCache } from "react-query";
import axios from '../../axiosWrapper'
import { query } from '../../util/config'

export default function useAcceptPendingMember(id) {
  /**
   * LOGIC LOOKS TOO BIG
   * MEMBER GETS ADDED BEFORE THE SUCCESS
   */
  const cache = useQueryCache();
  const team = cache.getQueryData(["teams", id]);
  let pendingMembers = []
  let members = []
  if (team) {
    pendingMembers = team && team.pendingMembers ? team.pendingMembers : [];
    members = team && team.members ? team.members : [];
  }
  return useMutation(async (mem) => {
    let pendingIds = pendingMembers.map(admin => admin.id)
    if (pendingIds.includes(mem.id)) {
      pendingMembers = [...pendingMembers.filter(a => a.id !== mem.id)]
      members = [...members, mem]
      const uniquePending = pendingMembers.map(item => item.id).filter((value, index, self) => self.indexOf(value) === index)
      const uniqueMembers = members.map(item => item.id).filter((value, index, self) => self.indexOf(value) === index)
      return axios.put(`teams/${id}`, { pendingMembers: uniquePending, members: uniqueMembers })
    }
    // return -1;
  }, {
    onSuccess: (updated) => {
      if (updated && updated.id) {
        cache.setQueryData(["teams", updated.id], (prev) => {
          prev.pendingMembers = updated.pendingMembers;
          prev.members = updated.members;
          return prev;
        })
      }
    },
    onError: (error, variables, context) => {
      return error
    }
  }, query.initialQuery)
}
