const createMeetingPayload = (meetData) => {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const { organizationId, teams, registrants, type = 2, prefix, topic, agenda = '', start_date, end_date, duration, password, canRegister, host, speaker, provider, joinUrl } = meetData;
  return {
    organizationId,
    teams,
    canRegister,
    provider,
    host,
    speaker,
    topic,
    joinUrl,
    startTime: start_date,
    endTime: end_date,
    duration,
    timezone,
    password,
    type,
    prefix,
    registrants
  }
}

module.exports = { createMeetingPayload }