import { useMutation, useQueryCache } from "react-query";
import axios from '../../axiosWrapper'
import { query } from '../../util/config'

export default function useUpdateMeeting(meetingId) {
  const cache = useQueryCache();
  return useMutation(meeting => {
    return axios.put(`meetings/${meetingId}`, meeting)
  },
    {
      onSuccess: (updated) => {
        if (updated && updated["Topic"]) {
          cache.setQueryData(["meetings"], (prev) => prev.map(meet=>meet.id === updated.id?updated:meet))
        }
      }
    }, query.initialQuery)
}
