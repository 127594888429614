import { useQuery } from "react-query";
import axios from '../../axiosWrapper'
import { query } from '../../util/config'

export default function useGoogleConnect(code) {
  query.initialQuery.retry = 0
  const provider = 'googlemeet'
  return useQuery(
    [code],
    () => axios.post('apps', { code, provider }),
    query.initialQuery
  )
}
