import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useQueryCache } from "react-query";
// import { CSVDownload } from "react-csv";
import Auxiliary from "util/Auxiliary";
import { Row, Col, Tabs, Input, Button, notification, Select, Form } from "antd";
import UserDashCard from "components/Widgets/UserDashCard";
//Data imports
import useApps from '../../../hooks/apps/useApps'
import useDashboard from '../../../hooks/dashboard/useDashboard'
import useUpdateDashboard from '../../../hooks/dashboard/useUpdateDashboard'
import useFetchRooms from '../../../hooks/dashboard/useFetchRooms'
import useMeetings from '../../../hooks/meetings/useMeetings'
import useTeams from '../../../hooks/teams/useTeams'
import useZoomMeetings from '../../../hooks/meetings/useZoomMeetings'

// import userRole from '../../../services/userRole'
const TabPane = Tabs.TabPane;
const Option = Select.Option;
const FormItem = Form.Item;

const teams = [{ displayName: 'Kunal & Rupaam', invitee: [1, 2, 3], inviter: [2, 3] }, { displayName: 'Yajat Kamble', invitee: [3, 4, 5, 4, 5, 6, 7], inviter: [3, 4, 5] }]
const members = [
  { displayName: 'Kunal & Rupaam', invitee: [1, 2, 3] },
  { displayName: 'Pratik Thorat', invitee: [1, 2] },
  { displayName: 'Anand C', invitee: [1, 2, 3, 4] }
]

const RenderMembersCard = ({ member }) => {
  const membersCard = []
  members && members.map(member => {
    membersCard.push(<Col key={member.displayName} xl={6} lg={12} md={12} sm={12} xs={12} className="gx-col-full">
      <UserDashCard member={member} />
    </Col>)
  })
  return membersCard
}


const Settings = (props) => {
  const { getFieldDecorator } = props.form;
  const [state, setState] = useState({ defaultMeetingId: "", hideTeams: [], isSet: false, rooms: [], breakoutRooms: false });
  const { status, isLoading, isFetching, isFetched, isError, data: dashboards } = useDashboard();
  const { status: zmStatus, isLoading: zmLoading, isError: zmError, data: zmMeetings } = useZoomMeetings();
  const { status: statusTeams, isLoading: isLoadingTeams, isFetching: isFetchingTeams, isFetched: isFetchedTeams, isError: isErrorTeams, data: dataTeams } = useTeams();
  const cache = useQueryCache();
  const teams = cache.getQueryData(['teams'])

  let teamNames = []
  if (teams) teamNames = teams.map(team => {
    const { id, name } = team
    return { id, name }
  })
  let zoomApp = false
  const { data: apps } = useApps();
  if (apps && apps.length) zoomApp = apps.find(app => app.name = 'zoom')
  const [mutateUpdateDashboard, { status: mutateStatus, data: mutateData, error: mutateError }] = useUpdateDashboard();
  const [fetchRooms, { status: roomStatus, error: roomError }] = useFetchRooms();
  const updateState = (state) => setState(state);

  if (dashboards && dashboards.length && !state.defaultMeetingId && !state.isSet) {
    const { defaultMeetingId, filter } = dashboards[0]
    if (filter && filter.hideTeams) {
      updateState({ ...state, defaultMeetingId, hideTeams: filter.hideTeams, isSet: true })
    } else {
      updateState({ ...state, defaultMeetingId, isSet: true })
    }
  }

  const onSave = async () => {
    const id = dashboards && dashboards[0] ? dashboards[0].id : ""
    await mutateUpdateDashboard({ ...state, id })
    if (mutateError) {
      notification['error']({
        message: 'Updation Failed',
        description: mutateError.message ? mutateError.message : "Invalid Meeting ID!",
      });
    } else {
      notification['success']({
        message: 'Updated!',
        description: 'Meeting ID has been updated.',
      });
    }
  }
  const onDownload = async () => {
    const id = dashboards && dashboards[0] ? dashboards[0].id : ""
    if (dashboards && dashboards[0]) {
      const meetingId = dashboards[0].meeting.id
      const data = await fetchRooms(meetingId)
      const { csv } = data
      if (csv.length) setState({ ...state, rooms: csv, breakoutRooms: true })
    }
  }
  const handleHideTeamChange = (hideTeams) => {
    hideTeams = handleSelectAll(hideTeams);
    setState({ ...state, hideTeams })
  }
  const onChangeMeetHandler = (defaultMeetingId) => {
    updateState({ ...state, defaultMeetingId })
  }
  const handleSelectAll = (value) => {
    if (value && value.length && value.includes("all")) {
      if (value.length === teams.length + 1) {
        return [];
      }
      return [...teams.map(t => t.name)];
    }
    return value;
  }
  // const profile = userProfile.data || {};
  return (
    <Auxiliary>
      <Tabs defaultActiveKey="basic">
        <TabPane tab="Basic" key="basic">
          {zoomApp && dashboards && dashboards[0] && <div>
            <h2 className="gx-mb-4">Zoom Settings</h2>
            <Row>
              <Col xl={8} lg={12} md={12} sm={12} xs={24}>
                <div className="gx-form-group">
                  <label className="gx-mb-1">Set Default Meeting ID</label>
                  <Select
                    name="defaultMeetingId"
                    placeholder="Select Dashboard Meeting ID"
                    mode="single"
                    style={{ width: '100%' }}
                    value={state.defaultMeetingId}
                    onChange={onChangeMeetHandler}>
                    {zmMeetings && zmMeetings.map(item => <Option key={item['Meeting ID']}>{item['Meeting ID']} - {item['Topic']}</Option>)}
                  </Select>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xl={8} lg={12} md={12} sm={12} xs={24}>
                <label className="gx-mb-1">Select Team(s) to hide from the dashboard</label>
                {/* <FormItem className="gx-ml-0" label="Select Teams (Optional)">
                  {getFieldDecorator('teams', {
                    getValueFromEvent: handleSelectAll,
                    rules: [{ required: false, message: 'Please AIO Meet topic!' }], initialValue: state.hideTeams
                  })( */}
                <Select
                  mode="multiple"
                  onChange={handleHideTeamChange}
                  value={state.hideTeams}
                  style={{ minWidth: '450px' }}
                  placeholder="Select Team(s)">
                  <Option value="all" key="all">Select All</Option>
                  {teamNames && teamNames.map(item => <Option value={item.name} key={item.id}>{item.name}</Option>)}
                </Select>
                {/* )}
                </FormItem> */}
              </Col>
            </Row>
            <Row className="gx-mt-3">
              <Col xl={12} lg={12} md={12} sm={12} xs={24}>
                <Button>
                  <Link to="./myprofile">
                    Cancel
                  </Link>
                </Button>
                <Button className="gx-aio-gray-btn update-dashboard-btn"
                  loading={(mutateStatus === 'loading')}
                  disabled={(mutateStatus === 'loading')}
                  onClick={() =>
                    onSave()} >{(mutateStatus === 'loading') ? ' Updating...' : 'Update'}</Button>
                {/* {state.breakoutRooms && <CSVDownload filename={'breakout-room.csv'} data={state.rooms} />} */}
              </Col>
            </Row>
            <Row>
              <Col xl={12} lg={12} md={12} sm={12} xs={24}>
                <Button className="gx-aio-gray-btn update-dashboard-btn"
                  loading={(roomStatus === 'loading')}
                  disabled={(roomStatus === 'loading')}
                  onClick={onDownload} >{(roomStatus === 'loading') ? ' Generating...' : 'Generate Breakout Rooms'}</Button>
              </Col>
            </Row>
          </div>}
        </TabPane>
        {/* <TabPane tab="Advanced" key="advanced">
          <h2>Design Dashboard</h2>
        </TabPane> */}
      </Tabs>
      <div className="gx-position-absolute gx-top-0 gx-mt-4 gx-right-0 gx-mr-5">
        <Link to={`/main/dashboard`}>
          <span className="gx-link gx-profile-setting">
            <i className="icon icon-arrow-left gx-fs-lg gx-mr-2 gx-mr-sm-3 gx-d-inline-flex gx-vertical-align-middle" />
            <span className="gx-d-inline-flex gx-vertical-align-middle gx-ml-1 gx-ml-sm-0">Dashboard</span>
          </span>
        </Link>
      </div>
    </Auxiliary >
  )
}

const HorizontalLoginForm = Form.create()(Settings)
export default HorizontalLoginForm