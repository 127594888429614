import {
  FETCH_TEAMS_SUCCESS,
  GET_USER_TEAMS,
  ADD_USER_TEAMS,
  ON_ADD_USER_TEAM_SUCCESS,
  ON_ADD_TEAM_SUCCESS,
  ADD_TEAM_MEMBER,
  ON_ADD_TEAM_MEMBER_SUCCESS,
  GET_TEAM,
  ON_GET_TEAM_SUCCESS,
  DELETE_TEAM,
  ON_DELETE_TEAM_SUCCESS,
  DELETE_TEAM_MEMBER,
  ON_DELETE_TEAM_MEMBER_SUCCESS,
  UPDATE_USER_TEAMS,
  ON_UPDATE_USER_TEAM_SUCCESS

} from "../../constants/ActionTypes";

export const fetchUserTeamsSuccess = (teams) => {
  return (dispatch) => {
    dispatch({
      type: FETCH_TEAMS_SUCCESS,
      payload: teams
    });
  };
};

export const fetchUserTeams = () => {
  return (dispatch) => {
    dispatch({ type: GET_USER_TEAMS });
  };
};

export const onAddTeam = (team, cb) => {
  return (dispatch) => {
    dispatch({ type: ADD_USER_TEAMS, team: team, cb });
  }
};
export const addUserTeamSuccess = (team) => {
  return (dispatch) => {
    dispatch({
      type: ON_ADD_USER_TEAM_SUCCESS,
      payload: { teamsMetadata: team }
    })
    dispatch({
      type: ON_ADD_TEAM_SUCCESS,
      payload: { ...team }
    });
  }
}

export const onUpdateTeam = (team, cb) => {
  return (dispatch) => {
    dispatch({ type: UPDATE_USER_TEAMS, team: team, cb });
  }
};
export const updateTeamSuccess = (team) => {
  return (dispatch) => {
    dispatch({
      type: ON_UPDATE_USER_TEAM_SUCCESS,
      payload: { ...team }
    })
  }
}


export const addTeamMember = (teamId, member, cb) => {
  return (dispatch) => {
    dispatch({ type: ADD_TEAM_MEMBER, teamId, member, cb })
  }
}

export const addTeamMemberSuccess = (member) => {
  return (dispatch) => {
    dispatch({
      type: ON_ADD_TEAM_MEMBER_SUCCESS,
      payload: member
    })
  }
}

export const onGetTeamById = (teamId, cb) => {
  return (dispatch) => {
    dispatch({
      type: GET_TEAM,
      teamId,
      cb
    })
  }
}

export const getTeamMembersSuccess = (team) => {
  return (dispatch) => {
    dispatch({
      type: ON_GET_TEAM_SUCCESS,
      payload: { team }
    })
  }
}

export const onDeleteTeam = (team, cb) => {
  return (dispatch) => {
    dispatch({ type: DELETE_TEAM, team: team, cb });
  }
};

export const deleteTeamSuccess = (team) => {
  return (dispatch) => {
    dispatch({
      type: ON_DELETE_TEAM_SUCCESS,
      payload: { team }
    })
  }
}

export const onDeleteTeamMember = (teamId, member, cb) => {
  return (dispatch) => {
    dispatch({ type: DELETE_TEAM_MEMBER, teamId, member, cb });
  }
}

export const deleteTeamMembersSuccess = (team) => {
  return (dispatch) => {
    dispatch({
      type: ON_DELETE_TEAM_MEMBER_SUCCESS,
      payload: { team }
    })
  }
}