import React from "react";
import { useQueryCache } from 'react-query'
import { Menu } from "antd";
import { useDispatch } from "react-redux";
import { userSignOut } from "appRedux/actions/Auth";
import { Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPowerOff } from '@fortawesome/free-solid-svg-icons'
import CustomScrollbars from "util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";
import UserProfile from "./UserProfile";
import {
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  THEME_TYPE_LITE
} from "../../constants/ThemeSetting";
import IntlMessages from "../../util/IntlMessages";

//Data imports
import useSubscriptions from 'hooks/user/useSubscriptions'
import useProfile from 'hooks/user/useProfile'

const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;

const SidebarContent = () => {

  const { pathname } = useLocation();

  const cache = useQueryCache()
  const { data } = useSubscriptions();
  const features = []
  data && data.forEach(item => features.push(...item.features))

  const userProfile = useProfile();
  const profile = userProfile.data || {};

  const navStyle = "NAV_STYLE_NO_HEADER_MINI_SIDEBAR"
  // const pathname = "/main/dasuboard"
  const themeType = "THEME_TYPE_LITE"
  const dispatch = useDispatch();
  const getNoHeaderClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR || navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR) {
      return "gx-no-header-notifications";
    }
    return "";
  };
  const getNavStyleSubMenuClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
      return "gx-no-header-submenu-popup";
    }
    return "";
  };

  let selectedKeys = pathname.substr(1).split('/');
  selectedKeys = `${selectedKeys[0]}/${selectedKeys[1]}`
  if (selectedKeys === '/settings/connect') selectedKeys = '/settings'
  const defaultOpenKeys = selectedKeys.split('/')[1];

  const signOut = () => {
    cache.clear()
    dispatch(userSignOut())
  }
  return (
    <>
      <SidebarLogo />
      <div className="gx-sidebar-content">
        <div className={`gx-sidebar-notifications ${getNoHeaderClass(navStyle)}`}>
          <UserProfile profile={profile} avatar={profile.avatar} />
        </div>
        <CustomScrollbars className="gx-layout-sider-scrollbar">
          <Menu
            defaultOpenKeys={[defaultOpenKeys]}
            selectedKeys={[selectedKeys]}
            theme={themeType === THEME_TYPE_LITE ? 'lite' : 'dark'}
            mode="inline">

            <MenuItemGroup key="main" className="gx-menu-group" title={<IntlMessages id="sidebar.main" />}>

              {(features.indexOf('dashboard') !== -1) && <Menu.Item key="main/dashboard" className="menu-dashbaord">
                <Link to="/main/dashboard"><i className="icon icon-dashboard" />
                  <span><IntlMessages id="sidebar.dashboard" /></span></Link>
              </Menu.Item>}
              {(features.indexOf('calendar') !== -1) && <Menu.Item key="main/calendar" className="menu-calendar">
                <Link to="/main/calendar" className="calendar"><i className="icon icon-calendar-new" />
                  <span><IntlMessages id="sidebar.calendar" /></span></Link>
              </Menu.Item>}

              {(features.indexOf('contacts') !== -1) && <Menu.Item key="main/contacts" className="menu-contacts">
                <Link to="/main/contacts" className="contacts"><i className="icon icon-contacts" />
                  <span><IntlMessages id="sidebar.contacts" /></span></Link>
              </Menu.Item>}

              {(features.indexOf('notes') !== -1) && <Menu.Item key="main/notes" className="menu-notes">
                <Link to="/main/notes" className="notes"><i className="icon icon-ckeditor" />
                  <span><IntlMessages id="sidebar.notes" /></span></Link>
              </Menu.Item>}

              {(features.indexOf('teams') !== -1) && <Menu.Item key="main/teams" className="menu-teams">
                <Link to="/main/teams" className="teams"><i className="icon icon-team" />
                  <span><IntlMessages id="sidebar.teams" /></span></Link>
              </Menu.Item>}

              {(features.indexOf('meets') !== -1) && <Menu.Item key="main/meets" className="menu-meets">
                <Link to="/main/meets" className="meets"><i className="icon icon-schedule" />
                  <span><IntlMessages id="sidebar.meets" /></span></Link>
              </Menu.Item>}

              {(features.indexOf('meetings') !== -1) &&
                <Menu.Item key="main/meetings" className="menu-meetings">
                  <Link to="/main/meetings" className="meetings"><i className="icon icon-calendar" />
                    <span><IntlMessages id="sidebar.meetings" /></span></Link>
                </Menu.Item>
              }

              {(features.indexOf('reports') !== -1) && <Menu.Item key="main/reports" className="menu-reports">
                <Link to="/main/reports" className="reports"><i className="icon icon-timeline" />
                  <span><IntlMessages id="sidebar.reports" /></span></Link>
              </Menu.Item>}
              {/* <Menu.Item key="main/attendees">
                <Link to="/main/meetings/attendees"><i className="icon icon-team"/>
                  <span><IntlMessages id="sidebar.attendees"/></span></Link>
              </Menu.Item> */}


              {(features.indexOf('settings') !== -1) && <SubMenu key="settings" className={getNavStyleSubMenuClass(navStyle)}
                title={<span> <i className="icon icon-setting" />
                  <span><IntlMessages id="sidebar.settings" /></span></span>}>
                {(features.indexOf('apps') !== -1) && <Menu.Item key="settings/connect" className="menu-app-connect">
                  <Link to="/settings/connect" className="connect">
                    <i className="icon icon-apps" />
                    <span><IntlMessages id="sidebar.settings.connect" /></span>
                  </Link>
                </Menu.Item>}
                {(features.indexOf('email') !== -1) && <Menu.Item key="settings/email">
                  <Link to="/settings/email">
                    <i className="icon icon-email" />
                    <span><IntlMessages id="sidebar.settings.email" /></span>
                  </Link>
                </Menu.Item>}
              </SubMenu>}

              <Menu.Item key="main/signout" className="logout-btn gx-mt-2">
                <Link to="#" onClick={() => signOut()}>
                  <i className="icon icon-signin" />
                  <span><IntlMessages id="sidebar.signout" /></span>
                </Link>
              </Menu.Item>
            </MenuItemGroup>
          </Menu>
        </CustomScrollbars>
      </div>
    </>
  );
};

SidebarContent.propTypes = {};
export default SidebarContent;

