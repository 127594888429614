import React, { useState } from "react";
import { useParams, Link } from "react-router-dom";
import { useQueryCache } from "react-query";
import PullToRefresh from 'react-simple-pull-to-refresh'

import IntlMessages from "util/IntlMessages";
import { Col, Row, notification } from "antd";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Widget from "components/Widget";
import Auxiliary from "util/Auxiliary";
import TeamHeader from "./TeamHeader";
import TeamDetailsSlide from './teamDetailsSlide';
import { InviteRequest } from './InviteRequest';
import CircularProgress from "components/CircularProgress/index";
import Error401 from "../../errorPages/401"
// import Wall from "./Wall/index"

//Data imports
import useGetTeam from '../../../hooks/teams/useGetTeam'
import useGetTeamMeetings from '../../../hooks/teams/useGetTeamMeetings'
import useFetchTeam from '../../../hooks/teams/useFetchTeam'

const InviteLink = (props) => {
  const { inviteLink } = props

  const inviteHandle = () => {
    notification['success']({
      message: 'Copied invite link!',
      description: 'Invite link has been copied to your clipboard. Now you can share this to invite members to your team. Link is valid for 3 days only.',
    });
  }
  return (
    <CopyToClipboard text={inviteLink}
      onCopy={() => inviteHandle()}>
      <span className="gx-text-white gx-ml-3 gx-link"><i className="icon-link" /> Copy Invite Link</span>
    </CopyToClipboard>
  )
}

const RenderView = (props) => {
  const { currentTeam, url } = props
  const cache = useQueryCache();
  const profile = cache.getQueryData(["profile"])
  const [request, toggleRequest] = useState(false);

  const [fetchTeam, { status: addStatus, error: addError }] = useFetchTeam();

  const handleRefresh = () => {
    return fetchTeam(currentTeam.id)
  }
  return (
    <Auxiliary>
      {/* <Wall team={currentTeam} /> */}
      <TeamHeader authUser={profile && profile.id} data={currentTeam} />
      <div className="gx-profile-content">
        <div className="gx-mb-2">
          <Link to="/main/teams" className="team-back-btn">
            <span className="gx-link gx-profile-setting gx-text-white">
              <i className="icon icon-arrow-left gx-fs-lg gx-mr-sm-1 gx-d-inline-flex gx-vertical-align-middle" />
              <span className="gx-d-inline-flex gx-vertical-align-middle gx-ml-0 gx-ml-sm-0"><IntlMessages id="chat.teams" /></span>
            </span>
          </Link>
          {currentTeam.inviteLink && <InviteLink inviteLink={currentTeam.inviteLink} className="team-invite-link" />}
          {/* <Link to="#" onClick={()=>{toggleRequest(true)}} className="team-back-btn gx-ml-3">
            <span className="gx-link gx-profile-setting gx-text-white">
              <i className="icon icon-user gx-fs-lg gx-mr-sm-1 gx-d-inline-flex gx-vertical-align-middle" />
              <span className="gx-d-inline-flex gx-vertical-align-middle gx-ml-0 gx-ml-sm-0">Invite Request</span>
            </span>
          </Link> */}
          {/* {request && <InviteRequest teamId={currentTeam.id} toggleRequest={toggleRequest} />} */}
        </div>
        <Row>
          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
            <Widget title="" styleName="gx-card-profile-sm">
              <PullToRefresh onRefresh={handleRefresh}>
                <TeamDetailsSlide team={currentTeam} />
              </PullToRefresh>
            </Widget>
          </Col>
        </Row>
      </div>

    </Auxiliary>
  )
}


const TeamDetails = (props) => {
  const cache = useQueryCache();
  const { teamId } = useParams();
  const { status, isLoading, isFetching, isFetched, isError: isTeamError, data } = useGetTeam(teamId);
  const currentTeam = data || cache.getQueryData(['teams', teamId])
  const { data: meetings } = useGetTeamMeetings(teamId);
  if (currentTeam) currentTeam.meetings = meetings || cache.getQueryData(["teams", teamId, 'meetings']);
  const subscriptions = cache.getQueryData(["subscriptions"]);
  const features = []
  subscriptions && subscriptions.forEach(item => features.push(...item.features))

  return (
    <div>
      {isLoading && <CircularProgress />}
      {isTeamError && <Error401 />}
      {!isTeamError && currentTeam && <RenderView currentTeam={currentTeam} />}
    </div>
  );
}

export default TeamDetails
