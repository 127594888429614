//UI Imports
import React, { useState, useEffect } from "react";
import { Col, Input, Modal, Form, Row, notification, Select, message } from "antd";
import IntlMessages from "../../../util/IntlMessages";
import Errors from '../Errors';

//Data Imports
import { useQueryCache } from "react-query";
import useZoomRegistration from '../../../hooks/meetings/useZoomRegistration'
import useContacts from '../../../hooks/contacts/useContacts'
import { startCase } from 'lodash';
import EventCard from "components/Widgets/EventCard";


const FormItem = Form.Item;
const Option = Select.Option;

const AddEditForm = (props) => {
  const [disabled, setDisable] = useState(true);
  const [callContacts, setCall] = useState(false);
  const setDisabled = (val) => setDisable(val)
  const { updateRequest, onContactClose, userId: invitedBy, meetingId, teamId, teamName, contacts, form, card, meeting, fromCalendar, teams } = props;
  const { getFieldDecorator, validateFields } = form;
  const [mutateRegister, { status: regStatus, error: regError, data: regData }] = useZoomRegistration();
  const { status, isLoading, isFetching, isFetched, isError, data: ContactData } = useContacts();
  const temp = useContacts(callContacts);

  const cache = useQueryCache();
  const store = cache.getQueryData(["contacts"]);

  const [data, setData] = useState(undefined);
  const setMember = (val) => setData(val)

  const validator = (rule, value, callback) => {
    if (value !== undefined && value === "") {
      setDisabled(true);
      return callback();
    }
    if (rule.field === "email") {

      if (value.length > 0) {
        let lastAtPos = value.lastIndexOf('@');
        let lastDotPos = value.lastIndexOf('.');

        if (!(lastAtPos < lastDotPos && lastAtPos > 0 && value.indexOf('@@') === -1 && lastDotPos > 2 && (value.length - lastDotPos) > 2)) {
          setDisabled(true);
          return callback();
        }
      }
      else {
        setDisabled(false);
      }
      if (disabled) {
        var x = document.getElementsByName("emailConfirm")[0].value;
        if (x && x === value) {
          validateFields(['emailConfirm'])
        }
      }
      else {
        var x = document.getElementsByName("emailConfirm")[0].value;
        if (x && x !== value) {
          validateFields(['emailConfirm'])
        }
      }
    }
    else if (rule.field === "emailConfirm") {
      if (value.length > 0) {
        let lastAtPos = value.lastIndexOf('@');
        let lastDotPos = value.lastIndexOf('.');

        if (!(lastAtPos < lastDotPos && lastAtPos > 0 && value.indexOf('@@') === -1 && lastDotPos > 2 && (value.length - lastDotPos) > 2)) {
          setDisabled(true);
          return callback();
        }
        var x = document.getElementsByName("email")[0].value;
        if (x && x !== value) {
          setDisabled(true);
          return callback(true);
        }
      }
    }
    setDisabled(false);
    callback();
  }

  const handleChange = async (value) => {
    const guest = ContactData.find(mem => mem.id === value)
    await setMember(guest)
    validateFields();
  }
  return (
    <Modal
      title="Register guest"
      visible={true}
      okButtonProps={{ disabled: (regStatus === 'loading') || disabled }}
      confirmLoading={(regStatus === 'loading')}
      closable={false}
      okText="Register"
      onOk={() => {
        validateFields(async (err, values) => {
          if (!err) {
            //Old request payload for guest registration
            // const payload = {...values,teamId,invitedBy}
            // await mutateRegister(payload,meetingId:meeting.id);

            //New request payload
            const { firstName, lastName, email, roomNumber, team } = values;
            const registrant = { firstName: startCase(firstName.toLowerCase()), lastName: startCase(lastName.toLowerCase()), email: email.toLowerCase() }
            if (team) {
              const selectedTeam = teams.find(t => t.id === team)
              const teamLoad = { id: selectedTeam.id, name: selectedTeam.name }
              const payload = { registrant, team: teamLoad, roomNumber }
              await mutateRegister({ payload, meetingId: meeting.id, teamId: selectedTeam.id });
              if (!regError && regStatus === "idle") {
                notification['success']({
                  message: 'Guest registered!',
                  description: `Guest has been successfully registered for the event.`,
                });
                onContactClose(false);
              }
              else if (regStatus === "error") {
                notification['error']({
                  message: 'Guest registration Failed!',
                  description: `Unable to register guest, please try after some time or contact admin.`,
                });
              }
            }
            else if (teams && teams.length === 1) {
              const { id, name } = teams[0]
              const teamLoad = { id, name }
              const payload = { registrant, team: teamLoad, roomNumber }
              await mutateRegister({ payload, meetingId: meeting.id, teamId: id });
              if (!regError && regStatus === "idle") {
                notification['success']({
                  message: 'Guest registered!',
                  description: `Guest has been successfully registered for the event.`,
                });
                onContactClose(false);
              }
              else if (regStatus === "error") {
                notification['error']({
                  message: 'Guest registration Failed!',
                  description: `Unable to register guest, please try after some time or contact admin.`,
                });
              }
            }
            else {
              message.error("Issue with guest registration..No team linked to meeting.. Contact Admin!")
            }
          }
          else {
            setDisabled(true)
          }
        })
      }}
      onCancel={() => onContactClose(false)}>
      <div className="gx-ml-auto">
        <Row>
          <Col xl={24} lg={24} md={24} sm={24} xs={24}><EventCard meeting={meeting} isAdmin={false} showOnModal={true} teamId={teamId} /></Col>
        </Row>
        <Row className="gx-mt-1">
          {regError && <Errors errors={data} />}
          {
            (isLoading || isFetching) ?
              <h2>
                {/* {isLoading && <CircularProgress />} */}
          Loading Contacts <span>{(status === 'loading') && '...'}</span>
              </h2>
              : (isError) ?
                <p>Error getting Contacts...</p>
                : ContactData && ContactData.length > 0 ?
                  <Select className="gx-ml-3 gx-mb-3"
                    showSearch
                    style={{ width: 228 }}
                    placeholder="Add contact to event"
                    optionFilterProp="children"
                    onChange={handleChange}
                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                    {ContactData.map(mem => <Option key={mem.id} value={mem.id}>{mem.firstName + " " + mem.lastName}</Option>)}
                  </Select>
                  :
                  <h3>No Contacts</h3>
          }

        </Row>

        <Form layout="inline" className="gx-form-inline-label-up">
          {teams && teams.length > 1 && <FormItem label="Select Team">
            {getFieldDecorator('team', {
              rules: [{ required: true, message: 'Please select team to register from!' }],
            })(
              <Select
                style={{ minWidth: '400px' }}
                placeholder="Select team to register from">
                {teams && teams.map(item => <Option value={item.id} key={item.id}>{item.name}</Option>)}
              </Select>
            )}
          </FormItem>}
          <FormItem className="gx-form-item-two-fourth">
            {getFieldDecorator('firstName', {
              rules: [{ required: true, message: 'Please input First Name!', max: 25 },
              { validator: validator }
              ], initialValue: (data ? data.firstName : "")
            })(
              <Input type="text" name={"firstName"} placeholder="First Name" autoComplete="off" />
            )}
          </FormItem>
          <FormItem className="gx-form-item-two-fourth">
            {getFieldDecorator('lastName', {
              rules: [{ required: true, message: 'Please input Last Name!', max: 25 },
              { validator: validator }
              ], initialValue: (data ? data.lastName : "")
            })(
              <Input type="text" name={"lastName"} placeholder="Last Name" autoComplete="off" />
            )}
          </FormItem>
          <FormItem className="gx-form-item-two-fourth">
            {getFieldDecorator('email', {
              rules: [{ required: true, message: 'Please input valid Email!', type: 'email', max: 50 },
              { validator: validator, message: 'Email already exist!' }
              ], initialValue: (data ? data.email : "")
            })(
              <Input type="email" name={"email"} placeholder="Email" autoComplete="off" />
            )}
          </FormItem>
          <FormItem className="gx-form-item-two-fourth">
            {getFieldDecorator('emailConfirm', {
              rules: [{ required: true, message: 'Please input valid Email!', type: 'email', max: 50 },
              { validator: validator, message: 'Email did not match!' }
              ], initialValue: (data ? data.email : "")
            })(
              <Input type="email" name={"emailConfirm"} placeholder="Confirm Email" autoComplete="off" />
            )}
          </FormItem>
          <FormItem className="gx-form-item-two-fourth">
            {getFieldDecorator('roomNumber', {
              rules: [{ message: 'Please select Room!' },
              { validator: validator }
              ], initialValue: meeting && meeting.breakoutRooms && meeting.breakoutRooms.length > 0 ? meeting.breakoutRooms[0] : "Room 1"
            })(
              <Select
                showSearch
                placeholder="Select room"
                optionFilterProp="children"
                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                <Option key='select' value={null}>No Breakout Room</Option>
                {meeting && meeting.breakoutRooms && meeting.breakoutRooms.length > 0 ?
                  meeting.breakoutRooms.map(room => <Option key={room} value={room}>{room}</Option>) :
                  ["Room 1", "Room 2", "Room 3", "Room 4"].map(room => <Option key={room} value={room}>{room}</Option>)}
              </Select>
            )}
          </FormItem>
        </Form>
      </div>
    </Modal>
  );
}

export const WrappedRegisterGuest = Form.create()(AddEditForm);
