import React from "react";
import { Route, Switch } from "react-router-dom";

import User from "./user/index";
import Main from "./main/index";
import Settings from "./settings/index";

//Data imports
import useProfile from '../hooks/user/useProfile'

const App = ({ match }) => {
  useProfile();
  return (
    <div className="gx-main-content-wrapper">
      <Switch>
        <Route path={`${match.url}user`} component={User} />
        <Route path={`${match.url}main`} component={Main} />
        <Route path={`${match.url}settings`} component={Settings} />
      </Switch>
    </div>
  )
}

export default App;
