import React from "react";
import { useParams } from "react-router-dom";
import { format } from 'date-fns';

//UI imports
import Auxiliary from "util/Auxiliary";
import ColorTimeLine from "components/TimeLine/Color"

//Data imports
import useContactEventLogs from 'hooks/contacts/useContactEventLogs'
import useContacts from 'hooks/contacts/useContacts'

const ContactTimeline = () => {
  let { id } = useParams();
  const { data: contacts } = useContacts();
  let contact = {}
  if (id && contacts) contact = contacts.find(c => c.id === id)
  const { data } = useContactEventLogs(id);
  let events = []
  if (data) {
    events = data.map(d => {
      d.time = format(new Date(d.time), 'MM/dd/yyyy p')
      return d
    })
  }
  return (
    <Auxiliary>
      <ColorTimeLine events={events} contact={contact} />
    </Auxiliary>
  )

}

export default ContactTimeline;
