import React from "react";
import {Link} from "react-router-dom";
import {Card, Table} from "antd";
import QRCode from "qrcode.react"

const columns = [{
  title: 'ID',
  dataIndex: 'id',
  render: id => <Link to={"/main/meetings/"+id+"/attendees/"} className="gx-link">{id}</Link>,
}, {
  title: 'Password',
  dataIndex: 'type',
  render: type => <span>{type === 8 ? 'Password' : 'Open'}</span>,
}, {
  title: 'Topic',
  dataIndex: 'topic',
}, {
  title: 'Start Date',
  dataIndex: 'start_time',
}, {
  title: 'Duration',
  dataIndex: 'duration',
  render: text => <span>{text} min</span>,
}, {
  title: 'Join',
  dataIndex: 'join_url',
  render: text => <QRCode value={text} />,
}];

// rowSelection object indicates the need for row selection
const rowSelection = {
  onChange: (selectedRowKeys, selectedRows) => {
    console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
  },
  getCheckboxProps: record => ({
    disabled: record.name === 'Disabled User', // Column configuration not to be checked
    name: record.name,
  }),
};

class Selection extends React.Component {
  render() {
    const {meetings} = this.props;
    return (
      <Card title="Meetings">
        <Table rowKey={record => Math.random()} className="gx-table-responsive" rowSelection={rowSelection} columns={columns} dataSource={meetings}/>
      </Card>
    )
  }
}

export default Selection