import { useMutation, useQueryCache } from "react-query";
import axios from '../../axiosWrapper'
import { query } from '../../util/config'

export default function useCreateMeeting() {
  const cache = useQueryCache();
  return useMutation(meeting => {
    return axios.post(`meetings`, meeting)
  },
    {
      onSuccess: (created) => {
        if (created && created["Topic"]) {
          cache.setQueryData(["meetings"], (prev) => {
            if (prev) {
              return ([
                created, ...prev
              ])
            }
            return [created]
          })
        }
      }
    }, query.initialQuery)
}
