import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Col, Row } from "antd";
import Auxiliary from "util/Auxiliary";

import { useQueryCache } from "react-query";
import useGetTeamMeetings from '../../../../hooks/teams/useGetTeamMeetings'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import BreakoutCard from 'components/Widgets/BreakoutCard';
import useUpdateBreakoutRooms from '../../../../hooks/meetings/useUpdateBreakoutRooms';
// import { teams, meetings } from "../../MeetCalendar/data"
import WrappedSetBreakoutRoom from './SetBreakoutRoom';
import { Breadcrumb } from 'antd';
import useGetTeam from "../../../../hooks/teams/useGetTeam";


const BreakoutRooms = () => {
  const { teamId, meetingId } = useParams();
  const { data } = useGetTeamMeetings(teamId);
  const { data: teamData, isLoading, isError } = useGetTeam(teamId);
  const cache = useQueryCache();
  let meeting = undefined
  let meetings = []
  meetings = data || cache.getQueryData(["teams", teamId, 'meetings']);
  const [state, setState] = useState({});
  const [teamName, setTeamName] = useState('Team');
  const [registrants, setRegistrants] = useState([]);
  const [invitedBy, setInvitors] = useState({});
  const [changeRoomModal, showChangeRoomModal] = useState(false);
  const [invitedByNames, setInvitorsNames] = useState({});
  const [mutateUpdateRooms, { status, error }] = useUpdateBreakoutRooms();
  const [roomChangeRequest, setRoomChangeRequest] = useState({
    droppableId: null,
    index: null,
    draggableId: null,
    userName: '',
  })

  useEffect(() => {
    let breakoutRooms = {}
    if (meeting && meeting.breakoutRooms) {
      meeting.breakoutRooms.forEach(element => {
        breakoutRooms[element] = []
      });
      let invite = {}
      let inviteNames = {}
      meeting.registrants.forEach(r => {
        if (r['Breakout Room']) {
          if (r['Invited By'] && r['Invited By'] !== "") {
            if (!invite[r['Invited By'].email]) {
              invite[r['Invited By'].email] = r['Breakout Room']
              inviteNames[r['Invited By'].email] = r['Invited By'].name
            }
          }
          breakoutRooms[r['Breakout Room']] ? breakoutRooms[r['Breakout Room']].push({ ...r, id: r['id'] }) : breakoutRooms[r['Breakout Room']] = [{ ...r, id: (r['id']) }]
        }
      })
      setInvitors(invite)
      setInvitorsNames(inviteNames)
      setState(breakoutRooms)
      setRegistrants(meeting.registrants)
    }
  }, [meeting]);

  useEffect(() => {
    if(!isLoading && !isError){
      setTeamName(teamData.name);
    }
  }, [isLoading]);
  if (meetings) {
    meeting = meetings.find(m => {
      return m.id === meetingId
    })
    if (meeting) meeting.breakoutRooms = ['Room 1', 'Room 2', 'Room 3', 'Room 4']
  }

  const grid = 4;

  const getItemStyle = (isDragging, draggableStyle) => ({
    //some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,

    //change background colour if dragging
    background: isDragging ? "lightgreen" : "lightgrey",
    //styles we need to apply on draggables
    ...draggableStyle
  });

  const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? "lightblue" : "lightgrey",
    padding: grid,
    width: 400,
    height: "100%",
    minHeight: "200px",
  });


  function onDragEnd(result) {
    const { source, destination } = result;
    // dropped outside the list
    if (!destination) {
      return;
    }

    if (source.droppableId !== destination.droppableId) {
      if (source.index >= state[source.droppableId].length) {
        const newState = { ...invitedBy };
        newState[result.draggableId] = destination.droppableId
        setInvitors(newState);
      }
      else {
        const newState = { ...state };
        newState[destination.droppableId].push(state[source.droppableId][source.index])
        newState[source.droppableId] = newState[source.droppableId].filter(reg => reg.id !== state[source.droppableId][source.index].id)
        if (newState[source.droppableId].indexOf(r => r['Invited By'] && state[source.droppableId][source.index]['Invited By'] && (r['Invited By'].email === state[source.droppableId][source.index]['Invited By'].email)) === -1) {
          const newInvitedBy = { ...invitedBy };
          newInvitedBy[state[source.droppableId][source.index]['Invited By'].email] = destination.droppableId
          setInvitors(newInvitedBy);
        }
        let toUpdateIndex = registrants.findIndex(t => t.Name === state[source.droppableId][source.index].Name)
        if (toUpdateIndex !== -1) {
          const newRegistrants = [...registrants]
          newRegistrants[toUpdateIndex]['Breakout Room'] = destination.droppableId
          setRegistrants(newRegistrants)
        }
        setState(newState);
      }
    }
  }

  const createRoomChangeRequest = (droppableId, index, draggableId, userName) => {
    setRoomChangeRequest({
      droppableId, index, draggableId, userName
    })
    showChangeRoomModal(true);
  };

  const handleRoomChangeRequest = (destinationRoomId) => {
    const source = {
      droppableId: roomChangeRequest.droppableId,
      index: roomChangeRequest.index
    }
    const destination = {
      droppableId: destinationRoomId
    }
    onDragEnd({source, destination, draggableId: roomChangeRequest.draggableId});
    showChangeRoomModal(false);
  };

  const handleSave = async () => {
    await mutateUpdateRooms(registrants)
  }
  return (
    <Auxiliary>
      <Breadcrumb>
        <Breadcrumb.Item>
          <a href="/main/teams">Teams</a>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <a href={`/main/teams/${teamId}`}>{teamName}</a>
        </Breadcrumb.Item>
      </Breadcrumb>
      <span style={{ display: 'flex', marginTop: '8px' }}>
        <h1>Manage your breakout rooms</h1>
        {/* <span style={{ marginLeft: "auto" }} justify="flex-end">
          <Button loading={(status === 'loading')} disabled={(status === 'loading')} className="gx-aio-gray-btn" onClick={handleSave}>Save</Button>
        </span> */}
      </span>
      <Row>
        <DragDropContext onDragEnd={onDragEnd}>
          {Object.keys(state).map(ind => (
            <Col className="gx-mt-3" key={Math.random()} xl={6} lg={12} md={12} sm={24} xs={24}>
              <Droppable key={ind} droppableId={ind} index={parseInt(Object.keys((state)).indexOf(ind))}>
                {(provided, snapshot) => {
                  let elements = []
                  Object.entries((state)[ind]).map(([indx, reg]) => {
                    const i = parseInt(indx)
                    elements.push(<Draggable
                      isDragDisabled={true}
                      key={reg.id}
                      draggableId={reg.id}
                      index={i}>
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                          )}>
                          <BreakoutCard
                            reg={{ ...reg, type: 'reg' }}
                            onCardClick={()=> createRoomChangeRequest(ind, i, reg.id, reg.Name)}
                            del={() => {
                              const newState = { ...state };
                              newState[ind] = newState[ind].filter(r => r.id !== reg.id)
                              const newRegistrants = registrants.filter(r => r.id !== reg.id && r['Breakout Room'] !== undefined);
                              const newRegistrantsIndex = registrants.findIndex(r => r.id === reg.id)

                              const regis = [...registrants]
                              if (newRegistrantsIndex !== -1) {
                                regis[newRegistrantsIndex]['Breakout Room'] = undefined;
                              }
                              const counter = newRegistrants.filter(r => r['Invited By'] && reg['Invited By'] && (r['Invited By'].email === reg['Invited By'].email) && r['Breakout Room'] !== undefined)
                              if (!counter || counter.length === 0) {
                                const newState = { ...invitedBy };
                                newState[reg['Invited By'].email] = ""
                                setInvitors(newState);
                              }
                              setState(newState);
                              setRegistrants(regis);
                            }}
                          />
                        </div>
                      )}
                    </Draggable>)
                  })
                  const inviters = Object.fromEntries(Object.entries(invitedBy).filter(([k, v]) => v === ind));
                  Object.keys(inviters).forEach(element => {
                    const j = Object.keys(state).length + parseInt(Object.keys((inviters)).indexOf(element))
                    const invName = invitedByNames[element] ? invitedByNames[element] : element
                    elements.push(<Draggable
                      isDragDisabled={true}
                      key={element}
                      draggableId={element}
                      index={j}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                          )}
                        >
                          <BreakoutCard
                            reg={{ Name: invName, email: element, type: 'inv' }}
                            onCardClick={()=> createRoomChangeRequest(ind, j, element, invName)}
                            del={() => {
                              const newState = { ...invitedBy };
                              newState[element] = ""
                              setInvitors(newState);
                            }}
                          />
                        </div>
                      )}
                    </Draggable>)
                  });
                  return (<div
                    ref={provided.innerRef}
                    key={Math.random(3)}
                    style={getListStyle(snapshot.isDraggingOver)}
                    {...provided.droppableProps}
                  >
                    <h3 className="gx-m-2" >Breakout {ind}</h3>
                    {elements}
                    {provided.placeholder}
                  </div>
                  )
                }}
              </Droppable></Col>
          ))}
        </DragDropContext>
      </Row>
      {changeRoomModal && <WrappedSetBreakoutRoom
        onModalClose={()=> showChangeRoomModal(false)}
        onRoomChange={(roomNumber) => handleRoomChangeRequest(roomNumber)}
        breakoutRooms={meeting.breakoutRooms.filter(rm=>rm !== roomChangeRequest.droppableId)}
        userName={roomChangeRequest.userName}
      />}
    </Auxiliary>
  );
}

export default BreakoutRooms
