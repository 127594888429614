import React from "react";
import Widget from "components/Widget/index";
// import { Avatar } from "antd";


const WorkStatusCard = ({ teamName, members, guests, avatar }) => {
  const favCardClass = guests.length >= 5 ? 'gx-card-full gx-blue-cyan-gradient' : 'gx-card-full'
  const favCardFont = guests.length >= 5 ? 'gx-text-white' : ''
  return (
    <Widget styleName={favCardClass}>
      <div className="gx-user-wid-row">
        <div className="gx-user-wid-body gx-py-3 gx-pr-3 gx-ml-3">
          {/* <Avatar size={100} className="gx-mr-1" src={avatar} /> */}
          <h4 className={"gx-font-weight-bold gx-mb-2 " + favCardFont}>{teamName}</h4>
          <h4 className={favCardFont}>Guests: {guests.length}</h4>
          <h4 className={favCardFont}>Members: {members.length}</h4>
        </div>
      </div>
    </Widget>
  );
};

export default WorkStatusCard;
