import { update } from "lodash";
import { useMutation, useQueryCache } from "react-query";
import axios from '../../axiosWrapper'
import { query } from '../../util/config'

export default function useGuestRegistration() {
  const cache = useQueryCache();
  const contacts = cache.getQueryData(["contacts"]);
  const profile = cache.getQueryData(["profile"]);

  return useMutation(async ({ payload: guest, meetingId, teamId }) => {
    const response = await axios.post(`meetings/${meetingId}/registerZoomRegistrant`, guest)
    const team = cache.getQueryData(['teams', teamId])
    const meetings = await axios.get(`teams/${teamId}/meetings`)
    team.meetings = meetings
    cache.setQueryData(['teams', teamId, 'meetings'], meetings)
    cache.setQueryData(['teams', teamId], team)
    return response
  }, {
    onSuccess: (updated) => {
      if (updated && updated.contact && updated.contact.id) {
        const contact = updated.contact
        const { email, firstName, lastName, id } = contact
        const data = { id, firstName, lastName, email, phone: "", user: profile.email }
        cache.setQueryData(["contacts"], (prev) => {
          return ([
            ...prev, data
          ])
        })
      }
    },
    onError: (error) => {
      return error
    }
  }, query.initialQuery)
}
