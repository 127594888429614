import React from "react";
import { Link } from "react-router-dom";
import ReactMarkdown from 'react-markdown'
import { Card, Timeline } from "antd";
import IntlMessages from "util/IntlMessages";

const Color = ({ events, contact }) => {
  let name = ''
  if (contact) name = `${contact.firstName} ${contact.lastName}`
  return (
    <Card title={<div><Link to="/main/contacts"><IntlMessages id="chat.contacts" /></Link> / Activities for {name}</div>} className="gx-card">
      {(events && !events.length) && <h3>No contact activities found.</h3>}
      <Timeline>
        {events && events.map(event => {
          return (
            <Timeline.Item key={event.id} color={event.color}>
              <div>{event.time}</div>
              <ReactMarkdown>{event.text}</ReactMarkdown>
            </Timeline.Item>
          )
        })}
      </Timeline>
    </Card>
  );
};

export default Color;
