import { useMutation, useQueryCache } from "react-query";
import axios from '../../axiosWrapper'
import { query } from '../../util/config'

export default function useArchiveTeam() {
  const cache = useQueryCache();
  return useMutation(id => axios.put(`teams/${id}`, { archive: true }), {
    onSuccess: (updated) => {
      if (updated && updated.id) {
        cache.setQueryData(["teams", updated.id], (prev) => {
          if (prev.archive) {
            prev.archive = updated.archive
            return prev
          }
          else {
            return ({ ...prev, archive: updated.archive })
          }

        })
        cache.setQueryData(["teams"], (prev) => {
          return prev.filter(team => team.id !== updated.id);
        })
      }
    }
  }, query.initialQuery)
}
