import { useMutation, useQueryCache } from "react-query";
import axios from '../../axiosWrapper'
import { query } from '../../util/config'

export default function useDeleteMeeting() {
  const cache = useQueryCache();
  return useMutation(meetingId => axios.delete(`meetings/${meetingId}`), {
    onSuccess: (removed) => {
      cache.setQueryData(["meetings"], (prev) => {
        return prev.filter(meet => meet.id !== removed.id);
      })
    }
  }, query.initialQuery)
}