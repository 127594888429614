import { useMutation, useQueryCache } from "react-query";
import axios from '../../axiosWrapper'
import { query } from '../../util/config'

export default function useArchiveMeeting() {
  const cache = useQueryCache();
  return useMutation(meetingId => axios.put(`meetings/${meetingId}`, { archive: true }), {
    onSuccess: (archived) => {
      cache.setQueryData(["meetings"], (prev) => {
        return prev.filter(meet => meet.id !== archived.id);
      })
    }
  }, query.initialQuery)
}