import { useMutation, useQueryCache } from "react-query";
import axios from '../../axiosWrapper'
import { query } from '../../util/config'

export default function useRemoveInviteLink() {
  const cache = useQueryCache();
  return useMutation(teamId => axios.get(`teams/${teamId}/removeInviteLink`), {
    onSuccess: (removed) => {
      cache.setQueryData(["teams", removed.id], (prev) => {
        return (
          removed
        )
      })
    }
  }, query.initialQuery)
}