import { useMutation, useQueryCache } from "react-query";
import axios from '../../axiosWrapper'
import { query } from '../../util/config'

export default function useCreateContact() {
  const cache = useQueryCache();
  return useMutation(contact=>axios.post('contacts',contact),{
    onSuccess:(data) => {
      cache.setQueryData(["contacts"],(prev) =>{
        return([
          ...prev,data
        ])
      })
    }
  },query.initialQuery)
}