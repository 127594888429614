import { useQuery, useQueryCache } from "react-query";
import axios from '../../axiosWrapper'
import { query } from '../../util/config'

export default function useNotes() {
  const cache = useQueryCache();
  const notes = cache.getQueryData(["notes"]);
  return useQuery(
    ["notes"],
    () => notes || axios.get('notes'),
    query.initialQuery
  )
}
