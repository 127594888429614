import React from "react";
import { Dropdown, Icon } from "antd";
import Widget from "components/Widget/index";
import defaultAvatar from "../../assets/images/placeholder.png"

class MemberCard extends React.Component {
  render() {
    const { user, teamName, status, resend, cb, menu, id } = this.props;
    const { firstName, lastName, email, phone, avatar, displayName } = user
    return (
      <Widget styleName="gx-card-full gx-dot-arrow-hover">
        <div className="gx-user-wid-row">
          <div className="gx-user-wid gx-mr-3">
            <img alt="..." src={avatar || defaultAvatar} className="gx-object-cover" />
          </div>
          <div className="gx-user-wid-body gx-py-3 gx-pr-3">
            <div className="ant-row-flex">
              <h2 className="h4 gx-mr-1 gx-mb-1">{`${firstName} ${lastName}`}</h2>
            </div>
            <p className="gx-mb-1 gx-text-grey gx-fs-sm">{status}<br /> @{teamName}</p>
            <div className="gx-dot-arrow">
            {menu && <Dropdown overlay={() => menu(user)} id={id} trigger={['click']} className="ant-menu-icon">
            <div className="gx-dot">
                <i className="icon icon-ellipse-v gx-text-primary" />
              </div>
            </Dropdown>}
            </div>
          </div>
        </div>
      </Widget>
    )
  }
};
export default MemberCard;