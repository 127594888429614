import { useMutation, useQueryCache } from "react-query";
import axios from '../../axiosWrapper'
import { query } from '../../util/config'

export default function useCreateNote() {
  const cache = useQueryCache();
  let notes = cache.getQueryData(['notes'])
  return useMutation(note => axios.post('notes', note), {
    onSuccess: (note) => {
      cache.setQueryData(["notes", note.id], () => {
        if (notes) {
          notes.push(note)
        } else {
          notes = [note]
        }
        cache.setQueryData(['notes'], notes)
        return note
      })
    }
  }, query.initialQuery)
}