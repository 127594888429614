import { useQuery, useQueryCache } from "react-query";
import axios from '../../axiosWrapper'
import { query } from '../../util/config'

export default function useSubscriptions() {
  const cache = useQueryCache();
  const subscriptions = cache.getQueryData(["subscriptions"]);
  // call axios directly rather than calling it from api/contacts
  return useQuery(
    ["subscriptions"],
    async () => {
      if (subscriptions) {
        return subscriptions
      } else {
        const [userSubscriptions] = await axios.get('user-subscriptions')
        // Temporaty SUBSCRIPTIONS
        if (userSubscriptions.features && userSubscriptions.features.length === 3) {
          userSubscriptions.features = [
            "teams",
            "contacts",
            "addContact",
            "notes",
            "activity"
          ]
        }
        return [userSubscriptions]
      }
    },
    query.initialQuery
  )
}