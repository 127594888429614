import {
  UPDATE_USER_PROFILE,
  UPDATE_USER_PROFILE_SUCCESS,
  UPDATE_USER_CONFIG_SUCCESS,
  FAIL_TO_UPDATE_DATA,
  GET_USER_PROFILE_SUCCESS,
  GET_USER_PROFILE,
  FETCH_START
} from "../../../constants/ActionTypes";

export const onGetUserProfile = (user) => {
  return (dispatch) => {
    dispatch({
      type: GET_USER_PROFILE,
      payload: user
    })
  }
};

export const fetchProfileSuccess = (user) => {
  return {
    type: GET_USER_PROFILE_SUCCESS,
    payload: user
  }
};

export const onUpdateUserProfile = (user) => {
  return {
    type: UPDATE_USER_PROFILE,
    user
  }
};

export const updateProfileSuccess = (user) => {
  return {
    type: UPDATE_USER_PROFILE_SUCCESS,
    user
  }
};

export const onUpdateUserConfig = (config) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    dispatch({
      type: UPDATE_USER_CONFIG_SUCCESS,
      payload: config
    });
    dispatch({ type: FAIL_TO_UPDATE_DATA });
  };
};