import React, { useEffect } from "react";
import { Button, Form, Input, message } from "antd";
import IntlMessages from "util/IntlMessages";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  hideMessage,
  showAuthLoader,
  userFrogotPassword
} from "appRedux/actions/Auth";

const FormItem = Form.Item;

const ForgotPassword = (props) => {

  const dispatch = useDispatch();
  const { loader, alertMessage, showMessage } = useSelector(({ auth }) => auth);
  const history = useHistory();

  useEffect(() => {
    if (showMessage) {
      setTimeout(() => {
        dispatch(hideMessage());
      }, 100);
    }
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    props.form.validateFields((err, values) => {
      const callBack = () => {
        console.log('Password reset email hasbeen sent.')
        history.push('/');
      }
      if (!err) {
        console.log('Received values of form: ', values);
        dispatch(showAuthLoader());
        dispatch(userFrogotPassword(values, callBack));
      }
    });
  };

  const { getFieldDecorator } = props.form;

  return (
    <div className="gx-app-login-wrap">
      <div className="gx-app-login-container">
        <div className="gx-app-login-main-content">
          <div className="gx-app-logo-content gx-aio-gray-back">
            <div className="gx-app-logo gx-mt-4">
              <img alt="aiomeet" src={require("assets/images/instazoom-logo-tr-white.png")} />
            </div>

          </div>
          <div className="gx-app-login-content">
            <div className="gx-mb-4">
              <h2>Forgot Your Password ?</h2>
              <p><IntlMessages id="app.userAuth.forgot" /></p>
            </div>
            <Form layout="vertical" onSubmit={handleSubmit} className="gx-login-form gx-form-row0">

              <FormItem>
                {getFieldDecorator('email', {
                  rules: [{
                    type: 'email', message: 'The input is not valid E-mail!'
                  }, {
                    required: true, message: 'Please input your E-mail!',
                  }],
                })(
                  <Input type="email" placeholder="E-Mail Address" />
                )}
              </FormItem>

              <FormItem>
                <Button
                  loading={loader}
                  disabled={loader}
                  className="gx-mb-0 gx-aio-gray-btn" htmlType="submit">
                  <IntlMessages id="app.userAuth.send" />
                </Button>
                <span><IntlMessages id="app.userAuth.or" /></span> <Link to="/"><IntlMessages
                  id="app.userAuth.login" /></Link>
              </FormItem>
            </Form>
          </div>
          {showMessage ?
            message.error(alertMessage.toString()) : null}
        </div>
      </div>
    </div>
  )
}

const WrappedForgotPasswordForm = Form.create()(ForgotPassword);

export default (WrappedForgotPasswordForm);
