import { useMutation, useQueryCache } from "react-query";
import axios from '../../axiosWrapper'
import { query } from '../../util/config'

export default function useUpdateDashboard() {
  const cache = useQueryCache();
  const dashboards = cache.getQueryData(["dashboards"]);
  const dashboard = dashboards && dashboards[0] ? dashboards[0] : undefined;
  return useMutation(({ id, defaultMeetingId, hideTeams }) => axios.put(`dashboards/${id}`, { defaultMeetingId, filter: { hideTeams } }), {
    onSuccess: async (updated) => {
      if (updated && updated.id) {
        if (dashboard && dashboard.defaultMeetingId !== updated.defaultMeetingId) {
          const newDashboard = await axios.get('dashboards')
          cache.setQueryData(["dashboards"], newDashboard)
        }
        else if(dashboard) {
          dashboard.filter = updated.filter;
          cache.setQueryData(['dashboards'], [dashboard])
        }
      }
    }
  }, query.initialQuery)
}