import { useMutation, useQueryCache } from "react-query";
import axios from '../../axiosWrapper'
import { query } from '../../util/config'

export default function useSaveTemplate() {
  const cache = useQueryCache();
  return useMutation(template => {
    //return axios.post(`meetings`, template)
    return template
  },
    {
      onSuccess: (created) => {
        if (created) {
          cache.setQueryData(["templates"], (prev) => {
            return ([
              ...prev, created
            ])
          })
        }
      }
    }, query.initialQuery)
}