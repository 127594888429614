import React from "react";
import moment from 'moment'
import { useParams } from "react-router-dom"
import { Tabs, Select, Row, Col, Card } from "antd";
//Data imports
import { useQueryCache } from "react-query";
//UI imports
import Auxiliary from "util/Auxiliary";
import SimpleLineChart from "components/Widgets/SimpleLineChart";
import UserImages from "components/Widgets/UserImages";
import CircularProgress from "components/CircularProgress/index";
import useTeams from '../../../hooks/teams/useTeams'
import useMeetings from '../../../hooks/teams/useGetTeamMeetings'
import defaultAvatar from "../../../assets/images/teamicon.png"

const TabPane = Tabs.TabPane;
const Option = Select.Option;

const getStatistics = (meeting, teamId) => {
  const { analytics, teams } = meeting
  const members = {
    present: 0,
    absent: 0
  }
  const guests = {
    present: 0,
    absent: 0
  }
  let teamsStat = []
  if (analytics && teams) {
    const team = teams.find(t => {
      return t.id === teamId
    })
    const membersPresent = analytics.filter(a => (a.Team.id === teamId || a.Team == team.name) && !a['Invited By'].name && a.att) || [];
    const membersAbsent = analytics.filter(a => (a.Team.id === teamId || a.Team == team.name) && !a['Invited By'].name && !a.att) || [];
    const guestsPresent = analytics.filter(a => (a.Team.id === teamId || a.Team == team.name) && a['Invited By'].name && a.att) || [];
    const guestsAbsent = analytics.filter(a => (a.Team.id === teamId || a.Team == team.name) && a['Invited By'].name && !a.att) || [];
    members.present = members.present + membersPresent.length
    members.absent = members.absent + membersAbsent.length
    guests.present = guests.present + guestsPresent.length
    guests.absent = guests.absent + guestsAbsent.length

    teamsStat.push({
      id: teamId,
      name: team.name,
      avatar: team.avatar || defaultAvatar,
      total: (membersPresent.length) + (guestsPresent.length),
      members: {
        present: membersPresent,
        presentCount: membersPresent.length,
        absent: membersAbsent,
        absentCount: membersAbsent.length,
        totalMembers: membersPresent.length + membersAbsent.length
      },
      guests: {
        present: guestsPresent,
        presentCount: guestsPresent.length,
        absent: guestsAbsent,
        absentCount: guestsAbsent.length,
        totalGuests: guestsPresent.length + guestsAbsent.length
      }
    })

    teamsStat = teamsStat.sort((a, b) => {
      return b.total - a.total
    })
    return { meeting: { id: meeting.id, dateTime: meeting.startTime, topic: meeting.topic }, teamsStat, members, guests }
  } else {
    return teamsStat
  }
}


const EventAnalytics = (props) => {
  const authUser = localStorage.getItem('user_id')
  let { teamId, meetingId } = useParams();
  const { status, isLoading, isFetching, isFetched, isError, data: teamsData } = useTeams();
  const { status: meetstatus, isLoading: meetisLoading, isFetching: meetisFetching, isFetched: meetisFetched, isError: meetError, data: meetingsData } = useMeetings(teamId);
  const cache = useQueryCache();
  // const meetings = meetingsData || cache.getQueryData(["meetings"])
  const meetings = meetingsData || cache.getQueryData(["teams", teamId, 'meetings']) || [];
  const teams = teamsData || cache.getQueryData(["teams"])
  let meetingsMap = {}
  let statistics = null
  let meetingName = ''
  let meetingSpeaker = ''
  let membersData = []
  let guestsData = []
  let meetStats = []
  if (meetings && meetingId) {
    const diff = meetings.length - 7
    meetings.slice(0, meetings.length > 7 ? -diff : 7).map(m => {
      if (m.analytics) {
        m.teams = teams
        const stats = getStatistics(m, teamId)
        const { teamsStat: stat, meeting: currentMeet, members, guests } = stats
        meetStats.push({
          id: currentMeet.id,
          name: currentMeet.topic,
          dateTime: moment(currentMeet.dateTime,).format("DD MMM YYYY"),
          members,
          guests
        })
        meetingsMap[m.id] = stat
      }
    })
    meetStats = meetStats.sort((a, b) => {
      return new Date(a.dateTime).getTime() - new Date(b.dateTime).getTime()
    })
    membersData = meetStats.map(m => {
      return {
        name: `${m.dateTime}`,
        total: m.members.present + m.members.absent,
        present: m.members.present,
        absent: m.members.absent,
      }
    })
    guestsData = meetStats.map(m => {
      return {
        name: `${m.dateTime}`,
        total: m.guests.present + m.guests.absent,
        present: m.guests.present,
        absent: m.guests.absent,
      }
    })

    statistics = meetingsMap[meetingId]
    const currentMeet = meetings.find(m => m.id === meetingId)
    if (currentMeet) {
      meetingName = currentMeet.topic
      if (currentMeet.speaker) meetingSpeaker = `${currentMeet.speaker.firstName} ${currentMeet.speaker.lastName}`
    }
  }

  return (
    <Auxiliary>
      {(isLoading || meetisLoading) && <CircularProgress />}
      <h3 className="gx-mt-3 gx-ml-3">{meetingName} - {meetingSpeaker}</h3>
      {/* <Row> */}
      <Row>
        {/* <Col lg={24} md={24} sm={24} xs={24}>
          {statistics && <UserImages memberImageList={statistics} />}
        </Col> */}
        <Col lg={12} md={12} sm={24} xs={24}>
          <Card className="gx-card" title="Members Attendance">
            {membersData && <SimpleLineChart data={membersData} key1="present" key2="absent" key3="total" />}
          </Card>
        </Col>
        <Col lg={12} md={12} sm={24} xs={24}>
          <Card className="gx-card" title="Guests Attendance">
            {guestsData && <SimpleLineChart data={guestsData} key1="present" key2="absent" key3="total" />}
          </Card>
        </Col>
      </Row>
      {/* </Row> */}
    </Auxiliary>
  )
}

export default EventAnalytics
