import { useMutation, useQueryCache } from "react-query";
import axios from '../../axiosWrapper'
import { query } from '../../util/config'

export default function useArchiveNote() {
  const cache = useQueryCache();
  return useMutation(noteId => axios.put(`notes/${noteId}`, { archive: true }), {
    onSuccess: (archived) => {
      cache.setQueryData(["notes"], (prev) => {
        return prev.filter(note => note.id !== archived.id);
      })
    }
  }, query.initialQuery)
}