import { useQuery, useQueryCache } from "react-query";
import axios from '../../axiosWrapper'
import { query } from '../../util/config'

export default function useApps() {
  const cache = useQueryCache();
  const contacts = cache.getQueryData(["apps"]);
  return useQuery(
    ["apps"],
    () => contacts || axios.get('apps'),
    query.initialQuery
  )
}
