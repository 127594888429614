import { update } from "lodash";
import { useMutation, useQueryCache } from "react-query";
import axios from '../../axiosWrapper'
import { query } from '../../util/config'

export default function useUpdateBreakoutRooms(id) {
  const cache = useQueryCache();

  return useMutation(async (registrants) => {
    console.log("🚀 ~ file: useUpdateBreakoutRooms.js ~ line 10 ~ returnuseMutation ~ registrants", registrants)
    //return await axios.post(`meetings/${id}`, registrants)
    return true;
  }, {
    onSuccess: (updated) => {
      if (updated) {
        //code for update local cache 
      }
    }
  }, query.initialQuery)
}
