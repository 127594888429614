import {
  FETCH_MEETS_SUCCESS,
  FETCH_MEET_REPORT_SUCCESS,
  FETCH_MEET_REPORT,
  GET_USER_MEETS,
  ADD_USER_MEETS,
  ON_ADD_USER_MEET_SUCCESS,
  ON_ADD_MEET_SUCCESS,
  ADD_MEET_MEMBER,
  ON_ADD_MEET_MEMBER_SUCCESS,
  GET_MEET,
  ON_GET_MEET_SUCCESS} from "../../constants/ActionTypes";

export const fetchUserMeetsSuccess = (meets) => {
  return (dispatch) => {
    dispatch({
      type: FETCH_MEETS_SUCCESS,
      payload: meets
    });
  };
};

export const fetchUserMeetReportSuccess = (report) => {
  return (dispatch) => {
    dispatch({
      type: FETCH_MEET_REPORT_SUCCESS,
      payload: report
    })
  }
}

export const fetchUserMeetReport = (reportMeeting) => {
  return (dispatch) => {
    dispatch({ type: FETCH_MEET_REPORT, reportMeeting: reportMeeting })
  }
}

export const fetchUserMeets = () => {
  return (dispatch) => {
    dispatch({type: GET_USER_MEETS});
  };
};

export const onAddMeet = (meet, cb) => {
  return (dispatch) => {
    dispatch({type: ADD_USER_MEETS, meet: meet, cb});
  }
};

export const addUserMeetSuccess = (meet) => {
  return (dispatch) => {
    dispatch({
      type: ON_ADD_USER_MEET_SUCCESS,
      payload: {meetsMetadata: meet}
    })
    dispatch({
      type: ON_ADD_MEET_SUCCESS,
      payload: {...meet}
    });
  }
}

export const addMeetMember = (meetId, member, cb) => {
  return (dispatch) => {
    dispatch({type: ADD_MEET_MEMBER, meetId, member, cb})
  }
}

export const addMeetMemberSuccess = (member) => {
  return (dispatch) => {
    dispatch({
      type: ON_ADD_MEET_MEMBER_SUCCESS,
      payload: member
    })
  }
}

export const onGetMeetById = (meetId, cb) => {
  return (dispatch) => {
    dispatch({
      type: GET_MEET,
      meetId,
      cb
    })
  }
}

export const getMeetMembersSuccess = (meet) => {
  return (dispatch) => {
    dispatch({
      type: ON_GET_MEET_SUCCESS,
      payload: {meet}
    })
  }
}