import React from "react";
// External Libraries
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button as FloatButton, lightColors } from 'react-floating-action-button'
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'
//Data imports
import { useQueryCache } from "react-query";
import useTeams from 'hooks/teams/useTeams'
import _ from 'lodash';
//UI imports
import IntlMessages from "util/IntlMessages";
import { Col, Row, Button, Input } from "antd";
import ProjectWidget from "components/Widgets/ProjectWidget";
import Auxiliary from "util/Auxiliary";
import CircularProgress from "components/CircularProgress/index";
import { WrappedEditTeam } from "./newedit";
const Search = Input.Search;

const Teams = () => {
  const { status, isLoading, isFetching, isFetched, isError, data } = useTeams();
  const cache = useQueryCache();
  const profile = cache.getQueryData(["profile"])
  const subscriptions = cache.getQueryData(["subscriptions"])
  const features = []
  subscriptions && subscriptions.forEach(item => features.push(...item.features))
  const teams = data || cache.getQueryData(['teams'])
  const [state, setState] = React.useState({ addRequest: false, updateRequest: false });
  const [textFilter, setTextFilter] = React.useState("");
  const changeTextFilter = (param) => setTextFilter(param);
  const toggleAdd = () => setState({ addRequest: !state.addRequest, updateRequest: state.updateRequest });

  const RenderCards = (props) => {
    let { teams } = props
    const renderTeams = []
    for (const key in teams) {
      if (teams.hasOwnProperty(key)) {
        const team = teams[key];
        renderTeams.push(
          <Col key={Math.random()} xl={8} lg={12} md={12} sm={12} xs={24}>
            <ProjectWidget btnTitle='Go to Team' authUser={profile.id} role={team.role} project={team} name={team.name} link={`/main/teams/${team.id}`} />
          </Col>
        )
      }
    }
    return renderTeams
  }

  const handleChange = event => {
    changeTextFilter(event.target.value);
  };

  const resetFilter = () => {
    changeTextFilter("");
  }

  if (isLoading || isFetching) return (
    <h2>
      {isLoading && <CircularProgress />}
      <IntlMessages id="chat.teams" /> <span>{(status === 'loading') && '...'}</span>
    </h2>);
  else if (isError) {
    return (<p>Error getting Teams...</p>);
  }
  else if (isFetched) {
    let filteredData = teams.filter(t => !t.archive || t.archive === false)
    if (textFilter !== "") {
      const lowercasedFilter = textFilter.toLowerCase();
      filteredData = teams.filter(item => item.name && item.name.toLowerCase().includes(lowercasedFilter));
    }
    return (
      <Auxiliary>
        <h2>
          <span style={{ display: 'flex' }}>
            <h2 className="gx-mr-2 gx-mt-2"><IntlMessages id="chat.teams" /></h2>
            <span style={{ marginLeft: "auto" }} justify="flex-end">
              <Search
                placeholder="Looking for anyone .. "
                onSearch={value => console.log(value)}
                onChange={handleChange}
                style={{ width: 200 }}
                className="gx-mr-1 gx-ml-3 gx-mt-1"
                value={textFilter}
              />
              {textFilter !== "" && <Button disabled={textFilter.length === 0} onClick={resetFilter} className="gx-mb-0 gx-ml-1" shape="circle" icon="close" size='small' />}
            </span>
          </span>
        </h2>
        <div className="gx-ml-auto">
        </div>
        {state.addRequest && <WrappedEditTeam teams={teams} updateRequest={false} onTeamClose={toggleAdd} />}
        <Row className="gx-mt-3">
          <RenderCards teams={filteredData} />
        </Row>
        {(features.indexOf('addTeams') !== -1) && <FloatButton styles={{ cursor: 'pointer', 'position': 'absolute', 'right': '2%', 'bottom': '2%', 'zIndex': '999', 'backgroundColor': '#000000', 'color': '#fff' }}
          tooltip="Add Team"
          onClick={() => toggleAdd()} >
          <FontAwesomeIcon size="lg" icon={faPlusCircle} />
        </FloatButton>}
      </Auxiliary>
    )
  }
}

export default Teams;
