import { useMutation, useQueryCache } from "react-query";
import axios from '../../axiosWrapper'
import { query } from '../../util/config'

export default function useDeleteContact() {
  const cache = useQueryCache();
  return useMutation(contactId=>axios.delete(`contacts/${contactId}`),{
    onSuccess:(removed) => {
      cache.setQueryData(["contacts"],(prev) =>{
        return prev.filter(contact => contact.id !== removed.id);
      })
    }
  },query.initialQuery)
}