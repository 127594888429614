import React from "react";
import { Route, Switch } from "react-router-dom";
import Email from "./Email";
import Connect from "./Connect";
import Zoom from "./Connect/Zoom/Zoom";
import Google from "./Connect/Google/Google";

const Settings = ({ match }) => (
  <Switch>
    <Route path={`${match.url}/connect/zoom`} component={Zoom} />
    <Route path={`${match.url}/connect/google`} component={Google} />
    <Route path={`${match.url}/connect`} component={Connect} />
    <Route path={`${match.url}/email`} component={Email} />
  </Switch>
);

export default Settings;
