import React from "react";
import { Avatar } from "antd";
import { Link } from "react-router-dom";
import defaultAvatar from "../../assets/images/placeholder.jpeg"

const UserProfile = (props) => {
  const { avatar, profile } = props
  return (
    <div className="gx-flex-row gx-align-items-center gx-pt-2 gx-avatar-row">
      <Link to="/user/myprofile"><Avatar src={avatar || defaultAvatar}
        className="gx-size-40 gx-pointer gx-mr-3 user-profile-icon" alt="" /></Link>
      <span className="gx-avatar-name">{`${profile.firstName} ${profile.lastName}`}</span>
    </div>
  )
};

export default UserProfile;
