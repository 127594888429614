import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import { fetchUserContactsSuccess, addUserContactSuccess, updateContactSuccess, deleteUserContactSuccess } from '../actions/Contact'
import { auth } from '../../firebase/firebase';
import { ADD_USER_CONTACTS, UPDATE_USER_CONTACTS, GET_USER_CONTACTS, DELETE_USER_CONTACTS } from '../../constants/ActionTypes';
import { fetchError } from "../actions/Common";
import contactService from "../../api/contact";

const authUser = localStorage.getItem('user_id')

const getUserContacts = async (uid) => {
  if (uid) {
    const contactList = {};//await contactService.getContacts();
    if ('error' in contactList) {
      console.log("error while fetching contactList");
      return {};
    }
    return contactList;
  }
}
const addUserContact = async (contact, uid, cb) => {

  if (uid && contact && contact.email) {
    const addedContact = await contactService.addContact(contact);
    if ('error' in addedContact) {
      console.log("error while adding contact");
      return {};
    }
    cb();
    return addedContact;
  }
}
const deleteUserContact = async (contact, uid) => {
  console.log("🚀 ~ file: Contacts.js ~ line 43 ~ deleteUserContact ~ deleteUserContact", deleteUserContact)
  if (uid && contact && contact.id) {
    const deletedContact = await contactService.deleteContact(contact.id);
    if ('error' in deletedContact) {
      console.log("error while deleting contact");
      return {};
    }
    return deletedContact;
  }
  return {};
}
const updateUserContact = async (contact, uid, cb) => {
  //This function get triggered multiple times in few unknown scenarios - Pratik
  if (uid && contact && contact.id) {
    const updatedContact = await contactService.updateContact(contact);
    if ('error' in updatedContact) {
      console.log("error while updating contact");
      return {};
    }
    cb();
    return updatedContact;
  }
  return {};
}

function* deleteUserContactOnSuccess(payload) {
  const { contact } = payload
  try {
    const uid = authUser || auth.uid || auth.currentUser.uid
    const deletedUserContact = yield call(deleteUserContact, contact, uid);
    yield put(deleteUserContactSuccess(deletedUserContact));
  } catch (error) {
    console.log(error)
    yield put(fetchError(error));
  }
}
function* addUserContactOnSuccess(payload) {
  const { contact, cb } = payload
  try {
    const uid = authUser || auth.uid || auth.currentUser.uid
    const addedUserContact = yield call(addUserContact, contact, uid, cb);
    yield put(addUserContactSuccess(addedUserContact));
  } catch (error) {
    console.log(error)
    yield put(fetchError(error));
  }
}
function* fetchUserContactsOnSuccess() {
  try {
    const uid = authUser || auth.uid || auth.currentUser.uid
    const fetchedUserContacts = yield call(getUserContacts, uid);
    yield put(fetchUserContactsSuccess(fetchedUserContacts));
  } catch (error) {
    console.log(error)
    yield put(fetchError(error));
  }
}
function* updateUserContactOnSuccess(payload) {
  const { contact, cb } = payload
  try {
    const uid = authUser || auth.uid || auth.currentUser.uid
    const updatedUserContacts = yield call(updateUserContact, contact, uid, cb);
    yield put(updateContactSuccess(updatedUserContacts));
  } catch (error) {
    console.log(error)
    yield put(fetchError(error));
  }
}
function* fetchUserContacts() {
  yield takeLatest(GET_USER_CONTACTS, fetchUserContactsOnSuccess)
}
export function* addContact() {
  yield takeLatest(ADD_USER_CONTACTS, addUserContactOnSuccess);
}
export function* deleteContact() {
  yield takeLatest(DELETE_USER_CONTACTS, deleteUserContactOnSuccess);
}
export function* updateContact() {
  yield takeLatest(UPDATE_USER_CONTACTS, updateUserContactOnSuccess);
}
export default function* rootSaga() {
  yield all([fork(addContact), fork(fetchUserContacts), fork(deleteContact), fork(updateContact)]);
}