import React from "react";
import Widget from "components/Widget/index";

const IconCard = ({ color, icon, title }) => {
  return (
    <Widget styleName={`gx-card-full gx-p-2 gx-text-center gx-text-primary ${color}`}>
      <i className={`icon icon-${icon} gx-fs-iconcard gx-aio-gray-text`} />
      <div className="gx-aio-gray-text">{title}</div>
    </Widget>
  );
};

export default IconCard;
