import { useMutation, useQueryCache } from "react-query";
import axios from '../../axiosWrapper'
import { query } from '../../util/config'

export default function useAddAdminToTeam(id) {
  const cache = useQueryCache();
  const team = cache.getQueryData(["teams", id]);
  if (team) team.admins = team && team.admins ? team.admins : [];
  if (team) team.members = team && team.members ? team.members : [];
  return useMutation(async (adm) => {
    let adminIds = team.admins.map(admin => admin.id)
    let memberIds = team.members.map(member => member.id)
    if (!adminIds.includes(adm.id)) {
      team.admins = [...team.admins, adm]
      const uniqueIds = team.admins.map(item => item.id).filter((value, index, self) => self.indexOf(value) === index)
      if(!memberIds.includes(adm.id)){ 
        team.members = [...team.members,adm]
        const uniqueMemIds = team.members.map(item => item.id).filter((value, index, self) => self.indexOf(value) === index)
        return await axios.put(`teams/${id}`, { admins: uniqueIds, members:uniqueMemIds })
      }
      else{
        return await axios.put(`teams/${id}`, { admins: uniqueIds })
      }
      
    }
    return -1;
  }, {
    onSuccess: (updated) => {
      if (updated && updated.id) {
        cache.setQueryData(["teams", updated.id], (prev) => {
          prev.admins = team.admins
          return prev
        })
      }
    }
  }, query.initialQuery)
}
