import { useMutation, useQueryCache } from "react-query";
import axios from '../../axiosWrapper'
import { query, baseURL } from '../../util/config'

export default function useUpdateProfile() {
  const cache = useQueryCache();
  const authUserId = localStorage.getItem('user_id')
  return useMutation((profile) => axios.put(`users/${authUserId}`, profile), {
    onSuccess: (updated) => {
      if (updated && updated.avatar) updated.avatar = `${updated.avatar.url}` || undefined
      if (updated && !updated.displayName) updated.displayName = `${updated.firstName} ${updated.lastName}`
      cache.setQueryData(["profile"], (prev) => updated || prev)
    }
  }, query.initialQuery)
}
