import React from "react";
import Widget from "components/Widget/index";
import { format } from 'date-fns';
import { Dropdown, Avatar } from "antd";
import meetingIcon from "../../assets/images/meeting-placeholder.png"
import googleMeet from "../../assets/images/appLogos/googlemeet.png"
import webex from "../../assets/images/appLogos/webex.png"
import msTeams from "../../assets/images/appLogos/msTeams.png"
import zoom from "../../assets/images/appLogos/zoom.png"
import aiomeet from "../../assets/images/appLogos/aiomeet.png"

class EventCard extends React.Component {
  render() {
    const { meeting, menu } = this.props;
    const { members, guests, agenda, host, speaker, startTime, endTime, timezone, topic, provider, duration, registrants } = meeting
    const getName = (user) => `${user.firstName} ${user.lastName}`
    const startingAt = startTime ? format(new Date(startTime), 'MMM do @ p') : ''
    return (
      <Widget styleName="gx-card-full gx-dot-arrow-hover">
        <div className="gx-user-wid-row">
          <div className="gx-user-wid event-card">
            <img alt="..." src={speaker ? speaker.avatar || meetingIcon : meetingIcon} className="gx-object-cover" />
            {speaker && <span className="title">{(speaker && getName(speaker))}</span>}
          </div>
          <div className="gx-user-wid-body gx-py-3 gx-pr-3 gx-ml-3">
            <div className="ant-row-flex">
              <h2 className="h5 gx-mr-1 gx-mb-1">
                {provider && provider === 'zoom' && <Avatar size={18} className="gx-mr-1" src={zoom} />}
                {provider && provider === 'googleMeet' && <Avatar size={18} className="gx-mr-1" src={googleMeet} />}
                {provider && provider === 'msTeams' && <Avatar size={18} className="gx-mr-1" src={msTeams} />}
                {provider && provider === 'webex' && <Avatar size={18} className="gx-mr-1" src={webex} />}
                {provider && provider === 'aiomeet' && <Avatar size={18} className="gx-mr-1" src={aiomeet} />}
                {`${topic}`}
              </h2>
            </div>
            <p className="gx-mb-1 gx-text-darkgrey gx-fs-sm">Host: {(host && getName(host)) || '----'}</p>
            <p className="gx-mb-1 gx-text-darkgrey gx-fs-sm">{startingAt || '--/--/--'}</p>
            <div className="gx-dot-arrow">
              {menu && <Dropdown overlay={() => menu(meeting)} id={meeting.id} trigger={['click']} className="ant-menu-icon">
                <div className="gx-dot">
                  <i className="icon icon-ellipse-v gx-text-primary" />
                </div>
              </Dropdown>}
            </div>
          </div>
        </div>
      </Widget>
    )
  }
};
export default EventCard;