import React, { useState } from "react";
import { Link, useParams, useHistory } from "react-router-dom"
import SimpleMDE from "react-simplemde-editor";
import "easymde/dist/easymde.min.css"
import { format } from 'date-fns';
//Data imports
import { useQueryCache } from "react-query";
import useCreateNote from 'hooks/notes/useCreateNote'
import useUpdateNote from 'hooks/notes/useUpdateNote'

//UI imports
import { Form, Col, Row, Menu, Modal, notification, Input, Button } from "antd";
import Auxiliary from "util/Auxiliary";

const FormItem = Form.Item;
const saveBtnLabel = {
  save: 'Create',
  saving: 'Creating'
}

let mdInstence = ''
const Notes = (props) => {
  let { teamId, section, id } = useParams();
  const history = useHistory();
  let backPath = '/main/notes'
  let pageTitle = 'New Note'
  if (section) pageTitle = 'Update Note'
  if (!section) section = 'notes'
  const cache = useQueryCache();
  const { form } = props;
  const { getFieldDecorator, validateFields } = form;
  const [mutateCreate, { status: addStatus, error: addError }] = useCreateNote();
  const [mutateUpdate, { status: updateStatus, error: updateError }] = useUpdateNote();

  const [note, setNote] = useState({ title: '', body: '' })
  const notes = cache.getQueryData(['notes'])
  if (section === 'update' && notes && id && !note.title) {
    saveBtnLabel.save = 'Update'
    saveBtnLabel.saving = 'Updating'
    const foundNote = notes.find(note => note.id === id)
    const { title, body } = foundNote
    setNote({ title, body })
  }
  let contact = {}
  if (section === 'contactNote' && id && !note.title) {
    const now = format(new Date(), 'MM/dd/yyyy p')
    const contacts = cache.getQueryData(['contacts'])
    contact = contacts && contacts.find(c => c.id === id)
    setNote({ title: `Notes for ${contact.firstName} ${contact.lastName} - ${now}`, body: '### Notes\n\n' })
  }
  let meeting = {}
  if (section === 'addMeetingNote' && id && !note.title) {
    const now = format(new Date(), 'MM/dd/yyyy p')
    const meetings = cache.getQueryData(['teams', teamId, 'meetings'])
    meeting = meetings && meetings.find(m => m.id === id)
    if (meeting) {
      let { speaker, host, topic, startTime } = meeting
      startTime = startTime ? format(new Date(startTime), 'MM/dd/yyyy p') : ''
      let body = `### ${topic} - ${startTime} \n\n`
      if (speaker) body = body + `#### Speaker: ${speaker.firstName} ${speaker.lastName} \n`
      if (host) body = body + `#### Host: ${host.firstName} ${host.lastName} \n\n`
      setNote({ title: `Notes for ${meeting.topic} - ${now}`, body })
    }
  }
  const onSave = async () => {
    validateFields(async (err) => {
      if (!err) {
        if (section === 'update') {
          await mutateUpdate({ id, ...note })
        } else {
          if (section === 'contactNote' && id) {
            await mutateCreate({ ...note, tagContact: id })
            history.push('/main/contacts')
          } else if (section === 'addMeetingNote') {
            await mutateCreate({ ...note, tagTeams: [teamId], tagMeetings: [id] })
            history.push(`/main/teams/${teamId}`)
          }

          else {
            await mutateCreate(note)
            history.push('/main/notes')
          }
        }
      }
    })
  }

  // const autosave = {
  //   enabled: true,
  //   uniqueId: 111,
  //   delay: 1000
  // }
  // const autoAutoSave = value => {
  //   console.log(value)
  // }

  const getInstance = (instance) => {
    mdInstence = instance
    if (section === 'update') instance.togglePreview()
    // instance.toggleFullScreen()
  }

  const handleChange = value => {
    setNote({ ...note, body: value });
  }
  const handleTitleChange = e => {
    e.preventDefault()
    if (e.target.name === 'title') {
      setNote({ ...note, title: e.target.value });
    }
  }

  return (
    <Auxiliary>
      <h3><Link to={backPath}>Notes / </Link> {pageTitle}</h3>
      <Form layout="vertical" className="gx-form-inline-label-up">
        <FormItem className="gx-form-item-two-fourth">
          {getFieldDecorator('title', {
            rules: [{ required: true, message: 'Please input Title!', max: 100 },
            ], initialValue: note.title
          })(
            <Input onChange={handleTitleChange} type="text" name="title" placeholder="Title" autoComplete="off" />
          )}
        </FormItem>
        <SimpleMDE
          getMdeInstance={getInstance}
          name="body"
          id={111}
          value={note.body}
          onChange={handleChange}
          options={{
            autofocus: true,
            spellChecker: true,
            maxHeight: '400px'
          }}
        />

      </Form>
      <Row className="gx-mt-0">
        <Col xl={12} lg={12} md={12} sm={12} xs={24}>
          <Button>
            <Link to="./">
              Cancel
            </Link>
          </Button>
          <Button className="gx-aio-gray-btn"
            loading={(addStatus === 'loading') || (updateStatus === 'loading')}
            disabled={(addStatus === 'loading') || (updateStatus === 'loading')}
            onClick={() =>
              onSave()} >{((addStatus === 'loading') || (updateStatus === 'loading')) ? `${saveBtnLabel.saving}` : `${saveBtnLabel.save}`}</Button>
        </Col>
      </Row>
    </Auxiliary>
  )
}

export default Form.create()(Notes);