import { useMutation, useQueryCache } from "react-query";
import axios from '../../axiosWrapper'
import { query } from '../../util/config'

export default function useRemoveAdminFromTeam(team) {
  const cache = useQueryCache();
  if (team) team.members = team && team.members ? team.members : [];
  return useMutation(async (memId) => {
    let memIds = team.members.map(member => member.id)
    if (memIds.includes(memId)) {
      team.members = [...team.members.filter(a => a.id !== memId)]
      const uniqueIds = team.members.map(item => item.id).filter((value, index, self) => self.indexOf(value) === index)
      return await axios.put(`teams/${team.id}`, { members: uniqueIds })
    }
    return -1;
  }, {
    onSuccess: (updated) => {
      if (updated && updated.id) {
        cache.setQueryData(["teams", updated.id], (prev) => {
          prev.members = team.members
          return prev
        })
      }
    }
  }, query.initialQuery)
}
