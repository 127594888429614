import { useMutation, useQueryCache } from "react-query";
import axios from '../../axiosWrapper'
import { query } from '../../util/config'

export default function useUpdateNote() {
  const cache = useQueryCache();
  return useMutation(({ id, title, body, sharedUsers }) => axios.put(`notes/${id}`, { id, title, body, sharedUsers }), {
    onSuccess: (updatedNote) => {
      if (updatedNote && updatedNote.id) {
        cache.setQueryData(['notes', updatedNote.id], updatedNote)
        cache.setQueryData(["notes"], (prev) => prev.map(note => note.id === updatedNote.id ? updatedNote : note))
      }
      return updatedNote
    }
  }, query.initialQuery)
}