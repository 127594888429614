import {
  DELETE_MEET_SUCCESS,
  FETCH_MEETS_SUCCESS,
  ON_ADD_MEET_SUCCESS,
  UPDATE_MEET_SUCCESS,
  ON_GET_MEET_SUCCESS,
  ON_ADD_MEET_MEMBER_SUCCESS,
  FETCH_MEET_REPORT_SUCCESS
} from "../../constants/ActionTypes";

const INIT_STATE = {
  meetList: {},
  report: {},
  selectedTeam: []
};


export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_MEETS_SUCCESS: {
      return {
        ...state,
        meetList: action.payload,
      }
    }

    case UPDATE_MEET_SUCCESS:
      return {
        ...state,
        meetList: state.meetList.map((meet) => meet.id === action.payload.id ? action.payload : meet),
      };

    case DELETE_MEET_SUCCESS:
      return {
        ...state,
        meetList: state.meetList.filter((meet) => meet.id !== action.payload.id),
      };

    case ON_ADD_MEET_SUCCESS:
      const newData = {...state.meetList, ...action.payload}
      return {
        ...state,
        meetList: newData,
      };
    case ON_GET_MEET_SUCCESS:
      const meet = action.payload.meet
      return{
        ...state,
        meetList: {...state.meetList, ...meet}
      }
    case ON_ADD_MEET_MEMBER_SUCCESS:
      const {id, member} = action.payload
      const meetList = state.meetList
      meetList[id]['members'] = {
        ...meetList[id]['members'],
        ...member
      }

      return {
        ...state,
        meetList
      }
    case FETCH_MEET_REPORT_SUCCESS:
      const report = {...state.report, ...action.payload}
      return {
        ...state,
        report
      }
    default:
      return state;
  }
}
