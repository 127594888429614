import React from "react";
import { useQueryCache } from "react-query";
import { Icon, message, Upload, Avatar, Tooltip, notification } from "antd";
import teamDefaultAvatar from "../../assets/images/teamicon.png"
import { baseURL } from '../../util/config'
import ImgCrop from 'antd-img-crop';
const authorization = localStorage.getItem('authorization')
const customHeaders = {
    authorization
}

const AvatarUpload = (props) => {
    const cache = useQueryCache();
    const { id, avatarData, src, type = "team" } = props;
    const [loadingStatus, setState] = React.useState(false);
    const setLoad = (flag) => setState(flag);

    const beforeUpload = (file) => {
        const isPNG = file.type.toLowerCase() === 'image/png';
        const isJPG = file.type.toLowerCase() === 'image/jpg' || file.type.toLowerCase() === 'image/jpeg';
        if (!isJPG && !isPNG) {
            message.error('You can only upload JPG file!');
        }
        const isLt2M = (file.size / 1024 / 1024) < 5;
        if (!isLt2M) {
            message.error('Image must smaller than 5MB!');
        }
        return isJPG || isPNG && isLt2M;
    }

    const handleChange = async (info) => {
        if (info.file.error) {
            setLoad(false);
            notification['error']({
                message: 'Error!',
                description: `Image size is too big!`,
            });
        }
        if (info.file.status === 'uploading') {
            setLoad(true);
            return;
        }
        if (info.file.status === 'done') {
            const { url } = info.file.response[0]
            setLoad(false);
            if (type == 'user') {
                const profile = cache.getQueryData(["profile"]);
                profile.avatar = url
                cache.setQueryData(['profile'], profile)
            } else if (type == 'team') {
                const team = cache.getQueryData(["teams", id]);
                team.avatar = url
                cache.setQueryData(['teams', id], team)
            }
        }
    };
    const loadingIcon = (
        <div>
            <Icon className="gx-size-90" type='loading' />
        </div>
    );
    return (
        <ImgCrop aspect={4 / 4} shape='round' modalTitle='Edit Avatar' modalOk='Upload' rotate={true} >
            <Upload
                name="files"
                listType="text"
                className="avatar-uploader"
                showUploadList={false}
                beforeUpload={beforeUpload}
                action={`${baseURL}/upload`}
                data={avatarData}
                headers={customHeaders}
                onChange={handleChange}
                previewFile={false}>
                {loadingStatus ? loadingIcon : <Tooltip placement="rightBottom" title="Update Avatar" arrowPointAtCenter><Avatar className="gx-size-90" alt={"image" || "..."} src={src || teamDefaultAvatar} /></Tooltip>}
            </Upload>
        </ImgCrop>
    );
}

export default AvatarUpload;