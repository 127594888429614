import auth from 'api/auth'
import { useMutation } from "react-query";
import { query } from '../../util/config'


export default function useVerifyAiomeetToken() {
  return useMutation(meetingToken => auth.verifyAimeetToken(meetingToken), {
    onSuccess: (response) => {
      return response
    }
  }, query.initialQuery)
}