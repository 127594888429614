import React, { useState } from "react";
import { Select, Tooltip, notification, message, Button } from "antd";
import Widget from "components/Widget/index";
import defaultAvatar from "../../assets/images/placeholder.png"
import defaultTeamAvatar from "../../assets/images/teamicon.png"
import { Link } from "react-router-dom";
import useOrganizations from '../../hooks/organizations/useOrganizations'
import useAddMemberToTeam from '../../hooks/teams/useAddMemberToTeam';
import Auxiliary from "util/Auxiliary"

const Option = Select.Option;
var addState = false
const AddCard = (props) => {
  const [addShow, setAdd] = useState(false);
  const toggleAddShow = () => setAdd(!addShow);
  const toggleAdd = (val) => {
    addState = val;
    toggleAddShow()
  }

  const { elem, team, menu } = props;
  const [mutateAddMember, { error: AddError, data: AddData, status: AddStatus }] = useAddMemberToTeam(team);
  if (AddData && addState) {
    if (AddData && AddData === -1) {
      message.info("Selected member is already present in team!")
    }
    else if (!AddError) {
      notification['success']({
        message: 'Success!',
        description: `Successfully added member!`,
      });

    }
    else {
      notification['warning']({
        message: 'Warning!',
        description: `Failed to add new member! Retry later`,
      });
    }
    toggleAdd(false);
  }
  const { data: orgs, status: orgStatus } = useOrganizations();
  const orgMembers = orgStatus === "success" && orgs && orgs.length ? orgs[0].members : []
  const teamMembers = team.members;
  if (!(orgMembers && teamMembers)) return null;
  const existingMembers = teamMembers.map(member => member.id)
  const result = orgMembers.filter(o => existingMembers.includes(o.id) === false && o.id !== team.owner.id)

  async function handleChange(value) {
    const adm = result.find(mem => mem.id === value)
    await mutateAddMember(adm)
  }

  return (
    <Widget styleName="gx-card-full gx-dot-arrow-hover">
      {!addState ? <div className="gx-user-wid-row">
        <div className="gx-user-wid gx-mr-3">
          <img alt="..." src={defaultAvatar} className="gx-object-cover" />
        </div>
        <div className="gx-user-wid-body gx-py-3 gx-pr-3">
          <div className="ant-row-flex">
            <h2 className="h4 gx-mr-1 gx-mb-1">
              <Link to="#" onClick={() => toggleAdd(true)}>
                <span><br />{`Add ${elem}`}</span>
                {/* <Tooltip className="gx-mb-0" key={Math.random(3)} title={`${"Add Member"}`}>
                    <Button disabled={result.length === 0} onClick={} className={result.length === 0 ? '' : 'gx-aio-gray-btn'} shape="circle" icon="plus" size='default' />
                  </Tooltip> */}
              </Link>
            </h2>
          </div>
          <p className="gx-mb-1 gx-text-grey gx-fs-sm"><br /></p>
          {/* <div className="gx-dot-arrow">
            <div className="gx-dot">
                <i className="icon icon-ellipse-v gx-text-primary" />
              </div>
            </div> */}
        </div>
      </div>
        : <div className="gx-user-wid-row">
          <div className="gx-user-wid gx-mr-3">
            <img alt="..." src={defaultAvatar} className="gx-object-cover" />
          </div>
          <div className="gx-user-wid-body gx-py-3 gx-pr-3">
            <div className="ant-row-flex">
                <Select className="gx-mr-2"
                  showSearch
                  style={{ width: 200 }}
                  placeholder="Add member"
                  optionFilterProp="children"
                  onChange={handleChange}
                  filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                  {result.map(mem => <Option key={mem.id} value={mem.id}>{mem.firstName + " " + mem.lastName}</Option>)}
                </Select>
            </div>
            <p className="gx-mb-1 gx-text-grey gx-fs-sm"><br /></p>
            <div className="gx-dot-arrow">
              <div className="gx-dot">
                {addState && <Tooltip className="gx-mb-0" key={Math.random(3)} title={`${"Cancel"}`}>
                  <Button disabled={result.length === 0} onClick={() => toggleAdd(false)} shape="circle" icon="close" size='small' />
                </Tooltip>}
              </div>
            </div>
          </div>
        </div>}
      {elem === "Meeting" && <div className="gx-user-wid-row">
        <div className="gx-user-wid">
          <img alt="..." src={defaultTeamAvatar} className="gx-object-cover" />
        </div>
        <div className="gx-user-wid-body gx-py-3 gx-pr-3 gx-ml-3">
          <div className="ant-row-flex">
            <h2 className="h4 gx-my-2 gx-mr-1">
              <Link to="#" onClick={() => { }}>
                <span><br /><br />{`Add ${elem}`}<br /><br /><br /></span>
              </Link>
            </h2>
          </div>
          {
          //
          /* {(authUser === project.owner.id) && <div className="gx-dot-arrow">
            {menu && <Dropdown overlay={() => menu(project)}
              id={project.id}
              trigger={['click']} >
              <div className="gx-dot">
                <i className="icon icon-ellipse-v gx-text-primary" />
              </div>
            </Dropdown>}
          </div>} */}
        </div>
      </div>}
    </Widget>
  );
}

export default AddCard;