import { useMutation, useQueryCache } from "react-query";
import axios from '../../axiosWrapper'
import { query } from '../../util/config'

export default function useDeleteNote() {
  const cache = useQueryCache();
  return useMutation(noteId => axios.delete(`notes/${noteId}`), {
    onSuccess: (removed) => {
      cache.setQueryData(["notes"], (prev) => {
        return prev.filter(note => note.id !== removed.id);
      })
    }
  }, query.initialQuery)
}