import React, { useEffect } from "react";
import { Row, Col, Tabs, Menu, Modal, notification, message, Divider } from "antd";
import { Link } from "react-router-dom";
import CalendarEventCard from "components/Widgets/CalendarEventCard"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen, faTrash, faChartPie, faMousePointer, faArchive, faCalendarPlus, faLink, faEdit, faFileAlt } from '@fortawesome/free-solid-svg-icons'
import { WrappedRegisterGuest } from "../Teams/registerGuest";
import useTeams from '../../../hooks/teams/useTeams'
import useDeleteMeeting from '../../../hooks/meetings/useDeleteMeeting'
import useArchiveMeeting from '../../../hooks/meetings/useArchiveMeeting'
import useZoomRegistration from '../../../hooks/meetings/useZoomRegistration'
import useGenerateReport from '../../../hooks/meetings/useGenerateReport'
import useApps from '../../../hooks/apps/useApps'
import useMeetings from '../../../hooks/meetings/useMeetings'
import Auxiliary from "util/Auxiliary";
import { Button as FloatButton, lightColors } from 'react-floating-action-button'
import { useHistory } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard'
import useSubscriptions from 'hooks/user/useSubscriptions'
import moment from 'moment'

const confirm = Modal.confirm;
const TabPane = Tabs.TabPane;

const TeamDetailsSlide = () => {

  const getEvents = (meetings) => {
    const events = []
    for (let key in meetings) {
      const meeting = meetings[key]
      if (meeting['Start At']) {
        const startTime = meeting['Start At']
        const startUTC = new Date(startTime).getTime()
        const endUTC = startUTC + (meeting['Duration'] * 60000)
        const start = new Date(startTime)
        const end = new Date(endUTC)
        const newMeet = {
          title: `${meeting['provider']} - ${meeting['Topic']}`,
          start,
          end,
        }
        events.push(newMeet)
      }
    }
    return events
  }

  let events = []
  const { status: tStatus, isLoading: tLoading, isError: tError, data: teams } = useTeams();
  const { status: mStatus, isLoading: mLoading, isError: mError, data: meetings } = useMeetings();
  const { data: subscriptions } = useSubscriptions();
  // const { status: gStatus, isLoading: gLoading, isError: gError, data: gMeetings } = useGoogleMeetings();

  const features = []
  let credits = 0
  subscriptions && subscriptions.forEach(item => {
    features.push(...item.features)
    credits = credits + parseInt(item.aiomeetCredits)
  })

  const ownedTeams = teams ? teams.filter(t => t.role === 'owner' || t.role === 'admin') : [];
  if (meetings && meetings.length) {
    events = [...events, ...getEvents(meetings)]
  }
  const authUser = localStorage.getItem('user_id')
  const { data: apps } = useApps();
  const [mutateRegisterMe, { status: regStatus, error: regError, data: regData }] = useZoomRegistration();
  const [mutateDeleteMeeting, { status: delStatus, error: delError, data: delData }] = useDeleteMeeting();
  const [mutateArchiveMeeting, { status: archStatus, error: archError, data: archData }] = useArchiveMeeting();
  const [mutateReportMeeting, { status: reportStatus, error: reportError, data: reportData }] = useGenerateReport();
  const [state, setState] = React.useState({ addRequest: false });
  const toggleAdd = (val) => setState({ addRequest: val });
  const history = useHistory();
  const showAddButton = ((apps && apps.length > 0) || credits) ? true : false

  /**
   * WE NEED ADD BETTER ERROR HANDLING
   */
  useEffect(() => {
    if (!regError && regData && regStatus === "success") {
      notification['success']({
        message: 'Guest registered!',
        description: `You have been successfully registered for the event.`,
      });
    }
    else if (regStatus === "error") {
      notification['error']({
        message: 'Guest registration Failed!',
        description: `Unable to register you, please try after some time or contact admin.`,
      });
    }
    if (!delError && delData && delStatus === "success") {
      notification['success']({
        message: 'Meeting Deleted!',
        description: `You have successfully deleted the event.`,
      });
    }
    else if (delStatus === "error") {
      notification['error']({
        message: 'Meeting deletion Failed!',
        description: `Unable to delete meeting, please try after some time or contact admin.`,
      });
    }
    if (!archError && archData && archStatus === "success") {
      notification['success']({
        message: 'Meeting Archived!',
        description: `You have successfully archived the event.`,
      });
    }
    else if (archStatus === "error") {
      notification['error']({
        message: 'Meeting archive Failed!',
        description: `Unable to archive meeting, please try after some time or contact admin.`,
      });
    }
    if (!reportError && reportData && reportStatus === "success") {
      notification['success']({
        message: 'Meeting Report!',
        description: `You have successfully generated the report.`,
      });
    }
    else if (reportStatus === "error") {
      notification['error']({
        message: 'Meeting report Failed!',
        description: `Unable to generate meetng report, please try after some time or contact admin.`,
      });
    }
  }, [delStatus, regStatus, archStatus, reportStatus]);

  const RenderEventCards = (props) => {
    const { meetings, isAdmin, isOwner, tab } = props
    const users = [];

    const showGoToMeetingConfirm = async (url) => {
      (
        await confirm({
          title: 'Are you sure you want to join the meeting?',
          okText: 'Yes',
          okType: 'danger',
          cancelText: 'No',
          async onOk() {
            if (url && url !== "") {
              const win = window.open(url, '_blank');
              win.focus();
            }
          },
          onCancel() {
            console.log('Cancel');
          },
        })
      )
    }

    const showRegisterMeConfirm = async (meeting) => {
      (
        message.info("Please goto Teams and register yourself from particular team tab")
        // await confirm({
        //   title: 'Are you sure you want to register yourself for this event/meeting?',
        //   okText: 'Yes',
        //   okType: 'danger',
        //   cancelText: 'No',
        //   async onOk() {
        //     if (meeting) {
        //       const teamPayload = { id: "team.id", name: "team.name" }
        //       const payload = { registrant: "me", team: teamPayload }
        //       await mutateRegisterMe({ payload, meetingId: meeting.id, teamId })

        //     }
        //   },
        //   onCancel() {
        //     console.log('Cancel');
        //   },
        // })
      )
    }

    const showDeleteConfirm = async (meetingId) => {
      (
        await confirm({
          title: 'Are you sure you want to delete this event/meeting?',
          okText: 'Yes',
          okType: 'danger',
          cancelText: 'No',
          async onOk() {
            if (meetingId) {
              await mutateDeleteMeeting(meetingId)
            }
          },
          onCancel() {
            console.log('Cancel');
          },
        })
      )
    }

    const showArchiveConfirm = async (meetingId) => {
      (
        await confirm({
          title: 'Are you sure you want to archive this event/meeting?',
          okText: 'Yes',
          okType: 'danger',
          cancelText: 'No',
          async onOk() {
            if (meetingId) {
              await mutateArchiveMeeting(meetingId)
            }
          },
          onCancel() {
            console.log('Cancel');
          },
        })
      )
    }

    const showReportConfirm = async (meetingId) => {
      (
        await confirm({
          title: 'Are you sure you want to generate a Report?',
          okText: 'Yes',
          okType: 'danger',
          cancelText: 'No',
          async onOk() {
            if (meetingId) {
              await mutateReportMeeting(meetingId)
            }
          },
          onCancel() {
            console.log('Cancel');
          },
        })
      )
    }

    const meetingMenu = (meeting) => {
      return (
        <Menu onClick={(e) => menuClick(e.key, meeting)}>
          {/* {((isOwner || isAdmin) && meeting.canRegister) && <Menu.Item className="gx-text-blue" key="breakoutRooms">
            <FontAwesomeIcon icon={faWindowMaximize} />&nbsp;
             <span><Link to={`./${teamId}/breakoutRooms/${meeting.id}`}>Breakout Rooms</Link></span>
          </Menu.Item>} */}
          {meeting && tab === 'upcoming' && <Menu.Item className="gx-text-blue" key="update">
            <FontAwesomeIcon icon={faEdit} />&nbsp;
            <span>Update Meeting</span>
          </Menu.Item>}
          {meeting.canRegister && meeting.provider && meeting.provider === "zoom" && tab === 'upcoming' &&
            <Menu.Item key="registerGuest" className="gx-text-blue">
              <FontAwesomeIcon icon={faPen} />&nbsp;
              <span>Register Guest</span>
            </Menu.Item>}
          {meeting.myJoinLink && meeting.provider === 'aiomeet' && tab === 'upcoming' &&
            <Menu.Item key="copy" className="gx-text-blue">
              <CopyToClipboard text={meeting.shareLink}>
                <span><FontAwesomeIcon icon={faLink} />&nbsp;Share Invite Link</span>
              </CopyToClipboard>
            </Menu.Item>
          }
          {(meeting.canRegister && meeting.provider && meeting.provider === "zoom" && !isOwner) && tab === 'upcoming' &&
            <Menu.Item key="registerMe" className="gx-text-blue">
              <FontAwesomeIcon icon={faPen} />&nbsp;
              <span>Register Me</span>
            </Menu.Item>}
          {(isOwner) && !meeting.analytics && <Menu.Item key="report" className="gx-text-blue">
            <FontAwesomeIcon icon={faFileAlt} />&nbsp;
            <span>Create Report</span>
          </Menu.Item>}
          {(isOwner) && meeting.analytics && <Menu.Item key="analytics" className="gx-text-green">
            <Link to={`/main/calendar/${meeting.id}/view`} className="gx-text-green">
              <FontAwesomeIcon icon={faChartPie} />&nbsp;
              <span>Analytics</span>
            </Link>
          </Menu.Item>}
          {(isOwner) && <Menu.Item key="archive" className="gx-text-red">
            <FontAwesomeIcon icon={faArchive} />&nbsp;
            <span>Archive</span>
          </Menu.Item>}
          {(isOwner) && <Menu.Item key="delete" className="gx-text-red">
            <FontAwesomeIcon icon={faTrash} />&nbsp;
            <span>Delete</span>
          </Menu.Item>}
          {meeting.myJoinLink && tab === 'upcoming' && <Menu.Item className="gx-text-red" key="join">
            <FontAwesomeIcon icon={faMousePointer} />&nbsp;
            <span>Join Meeting</span>
          </Menu.Item>}
        </Menu>
      )
    }

    const menuClick = async (key, meeting) => {
      if (key === 'registerGuest') {
        toggleAdd(meeting.id);
      }
      else if (key === 'registerMe') {
        showRegisterMeConfirm(meeting);
      }
      else if (key === 'join') {
        if (meeting.provider === 'aiomeet' && new Date(meeting.startTime).getTime() - new Date().getTime() > 300000) {
          message.info("Meeting has not started yet!")
          return
        }
        showGoToMeetingConfirm(meeting.myJoinLink)
      }
      else if (key === 'breakoutRooms') {
        //route to main/teams/:id/breakoutRooms
      }
      else if (key === 'delete') {
        showDeleteConfirm(meeting.id);
      }
      else if (key === 'archive') {
        showArchiveConfirm(meeting.id);
      }
      else if (key === 'report') {
        console.log(`generate report for meeting ${meeting.id}`)
        // Only zoom meeting
        showReportConfirm(meeting.id);
        // Make API call to https://api.aiomeet.com/meetings/{meetingId}/report
        // Get all teams after it's successful or set meeting.analytics = result.meetingRegistrant
      }
      else if (key === 'copy') {
        message.success("Invite link copied to clipboard. Paste to share")
      }
      else if (key === 'update') {
        history.push("./calendar/" + meeting.id + "/updateMeeting")
      }
    }

    if (meetings) {
      var anyTimeMeet = meetings.filter(m => !m.startTime)
      if (anyTimeMeet && anyTimeMeet.length > 0) {
        users.push(<Divider key={Math.random()} orientation="left">Anytime Meetings</Divider>)
        for (const mkey in anyTimeMeet) {
          if (anyTimeMeet.hasOwnProperty(mkey)) {
            const meetingElem = anyTimeMeet[mkey]
            const meetingTeams = meetingElem.teams || [];
            const ownedTeamIds = ownedTeams.map(o => o.id)
            const found = meetingTeams.filter(r => ownedTeamIds.includes(r.id))
            users.push(
              <Col key={Math.random()} xl={8} lg={12} md={12} sm={12} xs={24}>
                {state && state.addRequest && state.addRequest === meetingElem.id &&
                  <WrappedRegisterGuest fromCalendar={true} meeting={meetingElem} userId={authUser} teams={found} meetingId={meetingElem} updateRequest={false} onContactClose={toggleAdd} />
                }
                <CalendarEventCard meeting={meetingElem} tab menu={meetingMenu} />
              </Col>
            )
          }
        }
      }
      let sortedByWeek = meetings.filter(m => m.startTime).reduce((res, meet) => {
        let startOfWeek = moment(meet.startTime, 'YYYY-MM-DDTHH:mm:ssZ').startOf('day').format('dddd, DD MMM YYYY');
        res[startOfWeek] = res[startOfWeek] || [];
        res[startOfWeek].push(meet);
        return res;
      }, {});
      let orderedDates = {};
      Object.keys(sortedByWeek).sort(function (a, b) {
        return moment(b, 'dddd, DD MMM YYYY').toDate() - moment(a, 'dddd, DD MMM YYYY').toDate();
      }).forEach(function (key) {
        orderedDates[key] = sortedByWeek[key];
      })
      for (const mkey in orderedDates) {
        if (orderedDates.hasOwnProperty(mkey)) {
          const today = moment().format("dddd, DD MMM YYYY");
          users.push(<Divider key={Math.random()} orientation="left">{mkey === today ? "Today, " + mkey : mkey}</Divider>)
          for (const skey in orderedDates[mkey]) {
            if (orderedDates[mkey].hasOwnProperty(skey)) {
              const meetingElem = orderedDates[mkey][skey]
              const meetingTeams = meetingElem.teams || [];
              const ownedTeamIds = ownedTeams.map(o => o.id)
              const found = meetingTeams.filter(r => ownedTeamIds.includes(r.id))
              users.push(
                <Col key={Math.random()} xl={8} lg={12} md={12} sm={12} xs={24}>
                  {state && state.addRequest && state.addRequest === meetingElem.id &&
                    <WrappedRegisterGuest fromCalendar={true} meeting={meetingElem} userId={authUser} teams={found} meetingId={meetingElem} updateRequest={false} onContactClose={toggleAdd} />
                  }
                  <CalendarEventCard meeting={meetingElem} tab menu={meetingMenu} />
                </Col>
              )
            }
          }
        }
      }
    }

    return users
  }

  let upcomingMeetings = []
  let pastMeetings = []
  const today = new Date().getTime() - (75 * 60 * 1000)
  if (meetings && meetings.length) {
    upcomingMeetings = meetings.filter(m => {
      const end = new Date(m.endTime).getTime() || new Date(m.startTime).getTime() + m.duration * 60000
      if (end > today) {
        if (m.registrants) {
          m.members = m.registrants.filter(r => r['Invited By'] && !r['Invited By'].name).length
          m.guests = m.registrants.filter(r => r['Invited By'] && r['Invited By'].name).length
        }
        return m
      }
      if (!m.startTime) {
        return m
      }
    })
    pastMeetings = meetings.filter(m => {
      const end = new Date(m.endTime).getTime() || new Date(m.startTime).getTime() + m.duration * 60000
      if (end <= today) {
        const registrants = m.analytics || m.registrants
        if (registrants) {
          m.members = registrants.filter(r => r['Invited By'] && !r['Invited By'].name).length
          m.guests = registrants.filter(r => r['Invited By'] && r['Invited By'].name).length
        }
        return m
      }
    })
  }
  return (
    <Auxiliary>
      <Tabs defaultActiveKey="upcoming-meetings">
        <TabPane tab={`Upcoming (${upcomingMeetings.length})`} key="upcoming-meetings">
          <Row className="gx-mt-3">
            {(meetings && !meetings.length) && <h2 className="gx-ml-3">There are no upcoming meetings to show!</h2>}
            <RenderEventCards meetings={upcomingMeetings}
              addCard={true}
              isAdmin={true}
              isOwner={true}
              tab="upcoming" />
          </Row>
        </TabPane>
        <TabPane tab={`Past (${pastMeetings.length})`} key="past-meetings">
          <Row className="gx-mt-3">
            {(meetings && !pastMeetings.length) && <h2 className="gx-ml-3">There are no upcoming meetings to show!</h2>}
            <RenderEventCards meetings={pastMeetings}
              addCard={true}
              isAdmin={true}
              isOwner={true}
              tab="past" />
          </Row>
        </TabPane>
      </Tabs>
      {showAddButton && <FloatButton styles={{ cursor: 'pointer', 'position': 'absolute', 'right': '2%', 'bottom': '2%', 'zIndex': '999', 'backgroundColor': '#000000', 'color': '#fff' }}
        tooltip="Create Meeting"
        onClick={() => { history.push("./calendar/AddMeeting") }} >
        <FontAwesomeIcon size="lg" icon={faCalendarPlus} />
      </FloatButton>}
    </Auxiliary>
  );
}

export default TeamDetailsSlide;
