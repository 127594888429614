import { useMutation } from "react-query";
import axios from 'axios'
import config,{ query } from '../../util/config'

export default function useForgotPassword() {
  return useMutation(async (email)=>{
    const { data } = await axios.post(`${config.baseURL}/auth/forgot-password`, { email })
  },{
    // onSuccess:(data) => {
    //         //take action
    // }
  },query.initialQuery)
}