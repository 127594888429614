import React, { useState } from "react";
import jwt from "jsonwebtoken"
import Auxiliary from "util/Auxiliary";
import { Button, Input } from "antd";
import * as QueryString from "query-string"
import aioLogo from 'assets/images/instazoom-logo-tr-blue-30.png'
import useVerifyAiomeetToken from '../hooks/auth/useVerifyAiomeetToken'

const Join = (props) => {
  const { meetingToken } = QueryString.parse(props.location.search);
  const [state, setState] = useState({
    name: '',
    avatar: '',
    room: undefined,
    displayName: '',
    roomLoaded: false
  });
  if (meetingToken && !state.name) {
    const decode = jwt.decode(meetingToken)
    setState({ ...state, name: decode.name, avatar: decode.avatar })
  }
  const onChange = (e) => {
    e.preventDefault()
    setState({ ...state, [e.target.name]: e.target.value })
  }
  // let isError = false

  const [mutateVerifyToken, { status, error: isError }] = useVerifyAiomeetToken();
  const onClick = async () => {
    const data = await mutateVerifyToken(meetingToken)
    console.log("🚀 ~ file: Join.js ~ line 25 ~ onClick ~ data", data)
    if (data && data.room && !state.roomLoaded) {
      setState({ ...state, room: data.room, roomLoaded: true })
    }
  }
  const elm = document.querySelector("whereby-embed");
  const events = [];
  function logEvent(event) {
    events.push({ type: event.type, detail: event.detail })
    console.log(`got event ${JSON.stringify({ type: event.type, detail: event.detail })}\n`);
  }
  if (elm) {
    elm.addEventListener("ready", logEvent)
    elm.addEventListener("participantupdate", logEvent)
    elm.addEventListener("leave", logEvent)
  }

  return (
    <Auxiliary>
      {!state.room && <div className="gx-app-login-wrap">
        <div className="gx-app-login-container">
          <div className="gx-app-login-main-content">
            <div className="gx-app-logo-content gx-aio-gray-back">
              {/* <h1><IntlMessages id="app.userAuth.register" /></h1> */}
              <div>
                <a href="/"><img alt="AIO Meet" src={aioLogo} /></a>
              </div>
              <div className="gx-app-logo gx-mt-4">
                <img className="gx-rounded-lg" alt="example" width="200" src={state.avatar || require("assets/images/instazoom-logo-tr-white.png")} />
              </div>
              <div className="gx-app-logo-wid gx-mt-4">
                <p className="h3 gx-text-white">Please join meeting hosted by<br />
                  <span className="gx-font-weight-medium">{state.name}</span>.</p>
              </div>
            </div>
            <div className="gx-app-login-content">
              {!isError && <div>
                <h1 className="gx-text-center gx-mt-5 gx-mb-5">Welcome to AIO Meet</h1>
                <Input
                  name="displayName"
                  onChange={onChange}
                  value={state.displayName}
                  className="gx-mt-3"
                  type="text"
                  placeholder="Enter your name" />
                <Button
                  loading={status === 'loading'}
                  onClick={onClick}
                  disabled={!state.displayName}
                  className="gx-mt-3 gx-aio-gray-btn gx-full-width"
                  style={{ width: '100%' }}>Join</Button>
              </div>}
              {isError && <div className="gx-app-login-content gx-text-white">
                <h1 className="gx-mt-5">Sorry for the inconvenience.</h1>
                <h2>Please ask meeting host to share valid meeting link.</h2>
              </div>}
            </div>
          </div>
        </div>
      </div>}
      {state.room && <whereby-embed
        style={{
          marginLeft: 'auto',
          marginRight: 'auto'
        }}
        audio="on"
        video="on"
        chat="on"
        screenshare="on"
        recording="off"
        background="on"
        displayName={state.displayName}
        subdomain="aiomeetvideo"
        room={"/" + state.room}>
      </whereby-embed>}
    </Auxiliary>
  );
};

export default Join;
