import { useMutation, useQueryCache } from "react-query";
import axios from '../../axiosWrapper'
import { query } from '../../util/config'

export default function useUpdateTeamAvatar(id) {
  const cache = useQueryCache();
  return useMutation(async (data) => {
    //return await axios.post(`teams/${id}/breakoutrequest`, data )
    console.log("Call the breakout request ApI here with data", data)
    return true;
  }, {
    onSuccess: (requestStatus) => {
      console.log("🚀 ~ file: useUpdateTeamAvatar.js ~ line 11 ~ useUpdateTeamAvatar ~ requestStatus", requestStatus)
    }
  }, query.initialQuery)
}
