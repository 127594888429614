import axios from 'axios';
import config from '../util/config';

const getAuth = async (identifier, password) => {
  const { baseURL, headers } = config
  const payload = { identifier, password };
  const url = `${baseURL}/auth/local`

  try {
    const { data } = await axios.post(url, payload, { headers })
    return data
  } catch (error) {
    console.log("🚀 ~ file: auth.js ~ line 14 ~ getAuth ~ error", error)
    return error
  }
}
const verifyInviteToken = async (inviteToken) => {
  const { baseURL, headers } = config
  const tokeUrl = `${baseURL}/teams/verifyToken`
  try {
    return axios.post(tokeUrl, { inviteToken }, { headers })
  } catch (error) {
    return new Error(error)
  }
}
const verifyAimeetToken = async (meetingToken) => {
  const { baseURL, headers } = config
  const tokeUrl = `${baseURL}/meetings/verifyAiomeetToken`
  try {
    const { data } = await axios.post(tokeUrl, { meetingToken }, { headers })
    return data
  } catch (error) {
    return new Error(error)
  }
}
const registerTeamMember = async (payload) => {
  const { email, password, firstName, lastName, inviteToken } = payload
  const registerPayload = {
    email,
    username: email,
    password,
    firstName,
    lastName,
    inviteToken
  }
  const { baseURL, headers } = config
  const tokeUrl = `${baseURL}/teams/registerMember`

  try {
    const data = await axios.post(tokeUrl, registerPayload, { headers })
    if (!data.message) {
      return data
    }
    return new Error(data)
  } catch (error) {
    return error
  }
}

export default { getAuth, registerTeamMember, verifyInviteToken, verifyAimeetToken }