//UI Imports
import React, { useState } from "react";
import { Input, Modal, Form, notification } from "antd";
import IntlMessages from "../../../util/IntlMessages";

//Data Imports
import useSendInviteRequest from '../../../hooks/teams/useSendInviteRequest'

const FormItem = Form.Item;

const RequestForm = (props) => {
  const [disabled, setDisable] = useState(false);
  const setDisabled = (val) => setDisable(val)
  const { toggleRequest, teamId, form } = props;
  const authUser = localStorage.getItem('user_id')
  const { getFieldDecorator, validateFields } = form;
  const [mutateRequest, { status: addStatus, error: addError }] = useSendInviteRequest();

  const validator = (rule, value, callback) => {
    if (value === undefined) {
      setDisabled(true);
      return callback();
    }
    if (rule.field === "email") {
      const emailVal = value.trim().toLowerCase();
      if (emailVal.length > 0) {
        let lastAtPos = emailVal.lastIndexOf('@');
        let lastDotPos = emailVal.lastIndexOf('.');

        if (!(lastAtPos < lastDotPos && lastAtPos > 0 && emailVal.indexOf('@@') === -1 && lastDotPos > 2 && (emailVal.length - lastDotPos) > 2)) {
          setDisabled(true);
          return callback();
        }
      }
      validateFields(['emailConfirm'])
    }
    else if (rule.field === "emailConfirm") {
      const emailVal = value.trim().toLowerCase();
      if (emailVal.length > 0) {
        let lastAtPos = emailVal.lastIndexOf('@');
        let lastDotPos = emailVal.lastIndexOf('.');

        if (!(lastAtPos < lastDotPos && lastAtPos > 0 && emailVal.indexOf('@@') === -1 && lastDotPos > 2 && (emailVal.length - lastDotPos) > 2)) {
          setDisabled(true);
          return callback();
        }
        var x = document.getElementsByName("email")[0].value;
        if (x && x !== emailVal) {
          setDisabled(true);
          return callback(true);
        }
      }
    }
    setDisabled(false);
    callback();
  }

  return (
    <Modal
      title="Send Invite Request"
      visible={true}
      okButtonProps={{ disabled: (addStatus === 'loading') || disabled }}
      confirmLoading={(addStatus === 'loading')}
      closable={false}
      okText="Send Request"
      onOk={() => {
        validateFields(async (err, values) => {
          if (!err) {
            if (values.email) values.email = values.email.toLowerCase()
            let payload = { email: values.email, user: authUser, team: teamId, type: 'team' }
            await mutateRequest(payload);
            if (!addError) {
              notification['success']({
                message: 'Invite Request sent!',
                description: `Contact has been successfully Invited.`,
              });
              toggleRequest(false);
            }
          }
          else {
            console.log(err)
          }
        })
      }}
      onCancel={() => toggleRequest(false)}>
      <div className="gx-ml-auto">
        <Form
          labelCol={window.innerWidth > 500 ? { span: 10 } : { span: 24 }}
          wrapperCol={window.innerWidth > 500 ? { span: 14 } : { span: 24 }}
          layout={window.innerWidth > 500 ? "horizontal" : "vertical"}
          className="gx-px-0">
          <FormItem className="gx-form-item-two-fourth">
            {getFieldDecorator('email', {
              rules: [{ required: true, message: 'Please input valid Email!', type: 'email', max: 50 },
              { validator: validator, message: 'Email already exist!' }
              ],
            })(
              <Input type="email" name={"email"} placeholder="Invitee Email" autoComplete="off" />
            )}
          </FormItem>
          <FormItem className="gx-form-item-two-fourth">
            {getFieldDecorator('emailConfirm', {
              rules: [{ required: true, message: 'Please input valid Email!', type: 'email', max: 50 },
              { validator: validator, message: 'Email did not match!' }
              ],
            })(
              <Input type="email" name={"emailConfirm"} placeholder="Confirm Invitee Email" autoComplete="off" />
            )}
          </FormItem>
        </Form>
      </div>
    </Modal>
  );
}

export const InviteRequest = Form.create()(RequestForm);