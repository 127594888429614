import React from "react";
import { Tag } from "antd";

import Widget from "components/Widget/index";
import { Link } from "react-router-dom";
import defaultAvatar from "../../assets/images/teamicon.png"
const ProjectWidget = ({ project, menu, link, authUser, role }) => {
  const { avatar, members, admins, pendingMembers } = project
  const isAdmin = role === 'admin' || role === 'owner'
  return (
    <Link to={role !== "pending" ? link : '/'}>
      <Widget styleName="gx-card-full gx-dot-arrow-hover">
        {project && <div className="card-contact gx-user-wid-row">
          <div className="gx-user-wid team-card">
            <img alt="..." src={avatar || defaultAvatar} className="gx-object-cover" />
          </div>
          <div className="gx-user-wid-body gx-py-3 gx-pr-3 gx-ml-3">
            <div className="ant-row-flex gx-mr-2 gx-mb-2">
              {/* {role !== "pending" && <Link to={link}> */}
              <h2 className="h4 gx-my-2 gx-mr-1">{project.name}</h2>
              {/* </Link>} */}
              {role === "pending" && <h2 className="h4 gx-my-2 gx-mr-1">{project.name}</h2>}
            </div>
            {role !== "pending" && <div>
              {members && <Tag>{members.length} Members</Tag>}
              {/* {(admins && isAdmin) && <Tag>{admins.length} Admins</Tag>} */}
              {(pendingMembers && isAdmin) && <Tag>{pendingMembers.length} Pending</Tag>}
            </div>}
          </div>
          <div className="gx-pt-2 gx-position-absolute gx-top-0 gx-right-0" >
            <Tag color='volcano'>{role}</Tag>
          </div>
        </div>}
      </Widget>
    </Link>
  )
};

export default ProjectWidget;
