import React from "react";

export const contactList = (profile) => {
  return [
    {
      id: 1,
      title: 'Email',
      icon: 'email',
      desc: [<span className="gx-link" key={1}>{profile.email}</span>]
    },
    {
      id: 2,
      title: 'Web page',
      icon: 'link',
      desc: [<a className="gx-link" target="_blank" href={profile.url} key={2}>{profile.url}</a>]
    }, {
      id: 3,
      title: 'Mobile',
      icon: 'phone',
      desc: [`${profile.phone || ''}`]
    },
  ]
}
