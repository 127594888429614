import React from "react";
import { Link } from "react-router-dom";
import PullToRefresh from 'react-simple-pull-to-refresh'
import Auxiliary from "util/Auxiliary";
import { format } from 'date-fns';
import { Table, Row, Col, Tabs, Tag, Badge } from "antd";
import UserDashCard from "components/Widgets/UserDashCard";
import WorkStatusCard from "components/Widgets/WorkStatusCard";
import CircularProgress from "components/CircularProgress";

//Data imports
import useProfile from '../../../hooks/user/useProfile'
import useDashboard from '../../../hooks/dashboard/useDashboard'
import useFetchDashboard from '../../../hooks/dashboard/useFetchDashboard'

// import userRole from '../../../services/userRole'
const TabPane = Tabs.TabPane;

const RenderMembersCard = ({ members, type }) => {
  const membersCard = []
  members && members.map(member => {
    membersCard.push(<Col key={member.firstName + member.lastName} xl={6} lg={12} md={12} sm={12} xs={12} className="gx-col-full">
      <UserDashCard type={type} member={member} />
    </Col>)
  })
  return membersCard
}

const Dashboard = () => {
  const TabPane = Tabs.TabPane;
  const { status, isLoading, isFetching, isFetched, isError, data: dashboards } = useDashboard();
  const [fetchDashboard, { status: dashStatus, error: dashError }] = useFetchDashboard();
  let dashboard = {}
  let registrants = []
  let meeting = {}
  let guests = []
  let guestsPresent = 0
  let members = []
  let membersPresent = 0
  let columns = []
  let memColumns = []
  const teams = {}
  let hideTeams = []
  let startTime = new Date().toISOString()
  if (dashboards && dashboards.length > 0) {
    dashboard = dashboards[0]
    registrants = dashboard.registrant || []
    meeting = dashboard.meeting || {}
    startTime = format(new Date(meeting.startTime || startTime), 'MM/dd/yyyy p')
    hideTeams = dashboard.filter ? dashboard.filter.hideTeams : []
  }
  if (meeting && registrants) {
    registrants = registrants.filter(r => hideTeams && hideTeams.indexOf(r['Team']) === -1)
    registrants.map(r => {
      const teamName = r['Team']
      const _team = meeting.teams.find(t => t.name === teamName)
      if (teamName) {
        if (!teams[teamName]) {
          teams[teamName] = {
            members: [],
            guests: [],
            avatar: _team.avatar && _team.avatar.url
          }
        }
        if (r['Invited By']) {
          teams[teamName].guests.push(r)
        } else {
          teams[teamName].members.push(r)
        }
      }
    })
    members = registrants.filter(r => !r['Invited By'])
    membersPresent = members.filter(m => m.att).length
    guests = registrants.filter(r => r['Invited By'])
    guestsPresent = guests.filter(g => g.att).length
    if (registrants.length && !columns.length) {
      const registrant = registrants[0];
      columns.push({
        title: 'Name',
        dataIndex: 'Name',
        render: (r, d) => {
          return <span><Badge className="gx-mr-2" key={Math.random(3)} status={d.att ? 'success' : 'error'} dot><i className="icon icon-user-o" style={{ color: 'grey' }} /></Badge> {d['First Name']} {d['Last Name']}</span>
        }
      })
      columns.push({
        title: 'Invited By',
        dataIndex: 'Invited By',
        key: 'Invited By'
      })
      Object.keys(registrant).map(title => {
        if (title === 'Invited By' ||
          title === 'Invited By' ||
          title === 'First Name' ||
          title === 'Last Name' ||
          title === 'att' ||
          title === 'Team' ||
          title === 'key') {

        } else if (title === 'Time') {
          columns.push({
            title: title,
            dataIndex: title,
            key: title,
            render: (r, d) => {
              return format(new Date(r), 'MMM dd, p')
            }
          })
        } else {
          columns.push({
            title,
            dataIndex: title
          })
        }
      })
    }
    if (registrants.length && !memColumns.length) {
      const registrant = registrants[0];
      memColumns.push({
        title: 'Name',
        dataIndex: 'Name',
        render: (r, d) => {
          return <span><Badge className="gx-mr-2" key={Math.random(3)} status={d.att ? 'success' : 'error'} dot><i className="icon icon-user-o" style={{ color: 'grey' }} /></Badge> {d['First Name']} {d['Last Name']}</span>
        }
      })
      Object.keys(registrant).map(title => {
        if (title === 'Invited By' ||
          title === 'Invited By' ||
          title === 'First Name' ||
          title === 'Last Name' ||
          title === 'att' ||
          title === 'Team' ||
          title === 'key' ||
          title === 'Select Breakout Room') {

        } else if (title === 'Name') {

        } else if (title === 'Time') {
          memColumns.push({
            title: title,
            dataIndex: title,
            key: title,
            render: (r, d) => {
              return format(new Date(r), 'MMM dd, p')
            }
          })
        } else {
          memColumns.push({
            title,
            dataIndex: title
          })
        }
      })
    }
  }

  const handleRefresh = () => {
    return fetchDashboard()
  }

  const userProfile = useProfile();
  const profile = userProfile.data || {};
  return (
    <Auxiliary>
      {isLoading && <CircularProgress />}
      {dashboard && <div>
        {meeting && <div>
          <Tag color='black'>Topic: {meeting.topic} ({meeting?.speaker?.firstName} {meeting?.speaker?.lastName}) - {startTime}</Tag>
        </div>}
        <PullToRefresh onRefresh={handleRefresh}>
          <Tabs defaultActiveKey="guests">
            <TabPane tab={`Guests (${guestsPresent}/${guests.length})`} key="guests" className="dashboard-guest-tab">
              <Table rowKey='key' className="gx-table-responsive" columns={columns} dataSource={guests} onChange={() => { }} />
            </TabPane>
            <TabPane tab={`Members (${membersPresent}/${members.length})`} key="members" className="dashboard-members-tab">
              <Table rowKey='key' className="gx-table-responsive" columns={memColumns} dataSource={members} onChange={() => { }} />
            </TabPane>
            {teams && <TabPane tab={`Teams (${Object.keys(teams).length})`} key="teams" className="dashboard-tesm-tab">
              <Row>
                {Object.keys(teams).map(teamName => {
                  return (
                    <Col key={teamName} xl={6} lg={6} md={8} sm={12} xs={12}>
                      <WorkStatusCard
                        key={teamName}
                        teamName={teamName}
                        members={teams[teamName].members}
                        guests={teams[teamName].guests}
                        avatar={teams[teamName].avatar}
                      />
                    </Col>
                  )
                })}
              </Row>
            </TabPane>}
          </Tabs>
        </PullToRefresh>
      </div>}
      <div className="gx-position-absolute gx-top-0 gx-mt-4 gx-right-0 gx-mr-5">
        <Link to={`/main/dashboard/settings`} className="dashbaord-settings">
          <span className="gx-link gx-profile-setting">
            <i className="icon icon-setting gx-fs-lg gx-mr-2 gx-mr-sm-3 gx-d-inline-flex gx-vertical-align-middle" />
            <span className="gx-d-inline-flex gx-vertical-align-middle gx-ml-1 gx-ml-sm-0">Settings</span>
          </span>
        </Link>
      </div>
    </Auxiliary>
  )
}

export default Dashboard