import { useMutation, useQueryCache } from "react-query";
import axios from '../../axiosWrapper'
import { query } from '../../util/config'

export default function useGenerateReport() {
  const cache = useQueryCache();
  const cacheMeets = cache.getQueryData(["meetings"]);
  const cacheTeams = cache.getQueryData(["teams"]);

  return useMutation(async (id) => {
    const meetingId = id
    const report = await axios.get(`meetings/${meetingId}/report`)
    // const report = await axios.get(`reports/63850a00fb5d2b24de866a75`)
    return { meetingId, ...report }
  }, {
    onSuccess: (report) => {
      const { meetingId } = report
      // report.meetingRegistrant = [...report.analytics]
      // delete report.analytics
      /**
       * Why do we have this code?
       * as we do not get contact details bacl
       * */
      const foundMeet = cacheMeets.find(m => m.id === meetingId);
      if (report && report.meetingRegistrant && foundMeet) {
        foundMeet.analytics = report.meetingRegistrant.filter(r => (r && r.team)).map(r => {
          const foundTeam = cacheTeams.find(t => t.id === r.team);
          if (r && r.team) {
            return {
              'id': r.email,
              'Name': r.firstName + ' ' + r.lastName,
              'Invited By': r.invitedBy,
              'Team': foundTeam ? foundTeam.name : r.team,
              'Breakout Room': r.roomNumber,
              'Status': r.status,
              'att': r.attendance
            }
          }
        })
        cache.setQueryData(["meetings"], (prev) => {
          const meetings = prev.map(m => {
            if (m.id === foundMeet.id) return foundMeet
            return m
          })
          return (meetings)
        })
      }
    }
  }, query.initialQuery)
}
