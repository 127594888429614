import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Select, Row, Col, Button, Avatar, Input, notification, message, Switch } from "antd";
import Auxiliary from "util/Auxiliary";
import IntlMessages from "util/IntlMessages";
import defaultAvatar from "../../assets/images/avatar.png"
import useProfile from '../../hooks/user/useProfile'
import useUpdateProfile from '../../hooks/user/useUpdateProfile'
import useForgotPassword from '../../hooks/user/useForgotPassword';
import AvatarUpload from 'components/Widgets/AvatarUpload';
import { startCase } from "lodash";
import config from '../../util/config';

const Option = Select.Option;

const UpdateProfile = (porps) => {
  const [state, setState] = useState({ firstName: undefined, lastName: undefined, email: undefined, phone: undefined, url: undefined, gender: 'unknown' });
  const updateState = (displayName, firstName, lastName, email, phone, url, gender, autoRegister) => setState({ displayName, firstName, lastName, email, phone, url, gender, autoRegister });
  const [disabled, setDisable] = useState(true);
  const setDisabled = (val) => setDisable(val)
  const onChangeValue = (name, value) => {
    setState(prev => ({
      ...prev,
      [name]: value
    }))
    setDisabled(false)
  }
  const onChangeUrl = (e) => {
    e.persist();
    setState(prev => ({
      ...prev,
      url: e.target.value
    }))
    setDisabled(false)
  }
  const onChangeHandler = (e) => {
    e.persist();
    if (e.target.name === 'phone') {
      setState(prev => ({
        ...prev,
        [e.target.name]: e.target.value
      }))
      setDisabled(false)
    } else {
      const nameVal = e.target.value.length === 1 && e.target.value === " " ? "" : e.target.value;
      if (nameVal === "" || (nameVal.match(config.regex.userNames) ? true : false)) {
        setState(prev => ({
          ...prev,
          [e.target.name]: nameVal
        }))
        setDisabled(false)
      }
    }

  }
  const [mutateForgotPassword, { status: forgotStatus, data: forgotData, error: forgotError }] = useForgotPassword();
  const onResetClick = async () => {
    if (profile) {
      await mutateForgotPassword(profile.email);
      if (!forgotError) {
        notification['success']({
          message: 'Password request link sent!',
          description: 'Please check your mail box and use the link to reset your password!',
        });
      }
      else {
        notification['failed']({
          message: 'Password reset request failed!',
          description: 'Please try again by logout and login again!',
        });
      }
    }
    else {
      notification['failed']({
        message: 'Password reset request failed!',
        description: 'Please try again by logout and login again!',
      });
    }
  }

  const userProfile = useProfile();
  const profile = userProfile.data || {};
  const { avatar } = profile || {}
  if (profile && profile.firstName && state.firstName === undefined) updateState(profile.displayName, profile.firstName, profile.lastName, profile.email, profile.phone, profile.url, profile.gender, profile.autoRegister)
  const [mutateUpdateProfile, { status, data, error }] = useUpdateProfile();

  const onSave = async () => {
    if (state.firstName === "" || state.lastName === "" || state.displayName === "") {
      message.warning("Please fill all data to proceed!");
      return;
    }
    await mutateUpdateProfile({ ...state, firstName: startCase(state.firstName.toLowerCase()), lastName: startCase(state.lastName.toLowerCase()), displayName: startCase(state.displayName.toLowerCase()), email: state.email.toLowerCase(), url: state.url, gender: state.gender, autoRegister: state.autoRegister })
  }
  const onChange = () => { }
  const avatarData = {
    field: 'avatar',
    ref: 'user',
    refId: profile.id,
    source: 'users-permissions'
  }
  return (
    <Auxiliary>
      <h2><IntlMessages id="user.myProfile" /></h2>
      <div className="gx-ml-auto">
        <Row className="gx-mb-3">
          <Col xl={8} lg={12} md={12} sm={12} xs={24}>
            <AvatarUpload avatarData={avatarData} alt="" src={avatar || defaultAvatar} type="user" />
          </Col>
        </Row>
        <Row>
          <Col xl={8} lg={12} md={12} sm={12} xs={24}>
            <div className="gx-form-group">
              <Input
                name="displayName"
                type="text"
                required
                placeholder="Display Name"
                onChange={onChangeHandler}
                value={state.displayName}
                margin="none" />
            </div>
          </Col>
          <Col xl={8} lg={12} md={12} sm={12} xs={24}>
            <div className="gx-form-group">
              <Select
                name="gender"
                placeholder="Select Gender"
                mode="single"
                style={{ width: '100%' }}
                value={state.gender}
                onChange={(value) => onChangeValue('gender', value)}>
                <Option value="male" key="male">Male</Option>
                <Option value="female" key="female">Female</Option>
                <Option value="unknown" key="unknown">Unknown</Option>
              </Select>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xl={8} lg={12} md={12} sm={12} xs={24}>
            <div className="gx-form-group">
              <Input
                name="firstName"
                type="text"
                required
                placeholder="First Name"
                onChange={onChangeHandler}
                value={state.firstName}
                margin="none" />
            </div>
          </Col >
          <Col xl={8} lg={12} md={12} sm={12} xs={24}>
            <div className="gx-form-group">
              <Input
                name="lastName"
                type="text"
                required
                placeholder="Last Name"
                onChange={onChangeHandler}
                value={state.lastName}
                margin="none" />
            </div>
          </Col>
        </Row>
        <Row>
          <Col xl={8} lg={12} md={12} sm={12} xs={24}>
            <div className="gx-form-group">
              <Input
                name="email"
                type="email"
                required
                placeholder="Email"
                disabled
                value={state.email}
                margin="normal" />
            </div>
          </Col >
          <Col xl={8} lg={12} md={12} sm={12} xs={24}>
            <div className="gx-form-group">
              <Input
                name="phone"
                type="tel"
                placeholder="Mobile: (222)-333-4444"
                onChange={onChangeHandler}
                value={state.phone}
                margin="normal"
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col xl={8} lg={12} md={12} sm={12} xs={24}>
            <div className="gx-form-group">
              <Input
                name="url"
                type="text"
                placeholder="Personal Website"
                onChange={onChangeUrl}
                value={state.url}
                margin="normal" />
            </div>
          </Col >
        </Row>
        <Row>
          <Col xl={8} lg={12} md={12} sm={12} xs={24}>
            <div className="gx-form-group">
              <Switch
                checkedChildren="Auto Register ON"
                unCheckedChildren="Auto Register OFF"
                name="autoRegister"
                checked={state.autoRegister}
                onChange={(value) => onChangeValue('autoRegister', value)} />
            </div>
          </Col >
        </Row>
        <Row>
          <Col xl={12} lg={12} md={12} sm={12} xs={24}>
            <Button>
              <Link to="./myprofile">
                Cancel
                  </Link>
            </Button>
            <Button className="gx-aio-gray-btn" loading={(status === 'loading')} disabled={(status === 'loading') || disabled} onClick={() => onSave()} >{(status === 'loading') ? ' Saving...' : 'Save'}</Button>
          </Col>
        </Row>
      </div>
      <div className="gx-ml-auto gx-mt-5">
        <h2>Reset Password</h2>
        <Row className="gx-ml-1">
          <Button type="danger" htmlType="submit" loading={forgotStatus === "loading"} onClick={onResetClick} >
            Reset
          </Button>
        </Row>
      </div>
    </Auxiliary>
  )
}

export default UpdateProfile 
