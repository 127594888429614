import React, { useEffect, useState } from "react"
import { Link, useParams } from "react-router-dom"
import { useQueryCache } from "react-query"
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { Row, Col, Button, Avatar, Input, Modal, notification, Tooltip, message } from "antd"
import Auxiliary from "util/Auxiliary"
import defaultAvatar from "../../../assets/images/placeholder.png"
import useGetTeam from '../../../hooks/teams/useGetTeam'
import useUpdateTeam from '../../../hooks/teams/useUpdateTeam'
import useDeleteTeam from '../../../hooks/teams/useDeleteTeam'
import useArchiveTeam from '../../../hooks/teams/useArchiveTeam'
import useCreateTeamLink from '../../../hooks/teams/useCreateTeamLink'
import useRemoveInviteLink from '../../../hooks/teams/useRemoveInviteLink'
import AvatarUpload from 'components/Widgets/AvatarUpload'
import AddAdmin from './AddAdmin';
import useOrganizations from '../../../hooks/organizations/useOrganizations'
import useRemoveAdminFromTeam from '../../../hooks/teams/useRemoveAdminFromTeam';
import CircularProgress from "components/CircularProgress/index";
import Error401 from "../../errorPages/401"
import config from '../../../util/config';

const AvatarList = (props) => {
  const confirm = Modal.confirm;
  const links = []
  let removeAction = false;
  const [ishovered, setHovered] = useState("");
  const hovered = (id) => {
    setHovered(id);
  }
  const unhovered = () => {
    setHovered("");
  }
  const { data, className, teamId, type = "", isOwner } = props
  const [mutateRemoveAdmin, { error, data: removeData, status }] = useRemoveAdminFromTeam(teamId);
  if (removeData && ishovered !== "" && removeAction) {
    if (removeData && removeData === -1) {
      message.info("Selected member already removed from admins!")
    }
    else if (!error) {
      notification['success']({
        message: 'Success!',
        description: `Successfully removed admin!`,
      });
    }
    else {
      notification['warning']({
        message: 'Warning!',
        description: `Failed to remove admin! Retry later`,
      });
    }
    removeAction = false;
  }


  const removeAdmin = (id) => {
    removeAction = true;
    mutateRemoveAdmin(id)
  }

  const showRemoveConfirm = (id) => {
    (
      confirm({
        title: 'Are you sure remove the admin?',
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        async onOk() {
          if (id) {
            removeAdmin(id)
          }
        },
        onCancel() {
          console.log('Cancel');
        },
      })
    )
  }

  data && data.length > 0 && data.map(item => {
    const displayName = item.displayName || `${item.firstName} ${item.lastName}`
    links.push(
      <Tooltip key={item.id} trigger="hover" title={`${displayName} ${type && `(${type})`}`}>
        {!(ishovered === item.id && isOwner) ?
          <Avatar
            onMouseEnter={() => hovered(item.id)}
            onMouseLeave={() => unhovered("")}
            onMouseOut={() => unhovered("")}
            key={displayName}
            className={"gx-fs-xl gx-size-36 gx-mr-2 " + className}
            alt={displayName || "..."}
            src={item.avatar || defaultAvatar} /> :
          <Button onMouseOut={() => unhovered("")} onMouseLeave={() => unhovered("")} onClick={() => showRemoveConfirm(item.id)} className="gx-aio-gray-btn gx-mb-0 gx-mr-2" shape="circle" icon="close" size='default' />
        }
      </Tooltip>
    )
  })
  if (removeAction) { return <CircularProgress /> }
  else { return links }
}

const GenerateInviteLink = (props) => {
  const cache = useQueryCache();
  const [mutateTeamInviteLink, { status: linkStatus, data: linkData, error: linkError }] = useCreateTeamLink();
  const { team } = props
  useEffect(() => {
    if (linkStatus === 'success' && !linkError && linkData) {
      team.inviteLink = linkData.inviteLink
      cache.setQueryData(["teams", team.id], team)
      notification['success']({
        message: 'Link generated!',
        description: 'Now you can copy and share this invite link to join your team.',
      });
    }
    else if (linkError) {
      notification['error']({
        message: 'Link generation failed!',
        description: 'Please try after sometime or contact administrator!',
      });
    }
  }, [linkData, linkError])
  const inviteHandle = async () => {
    //GET token and attach it to base url
    await mutateTeamInviteLink(team.id)
  }
  return (
    <CopyToClipboard text={''}
      onCopy={async () => await inviteHandle()}>
      <span className="gx-ml-3 gx-link">
        <i className="icon-link" /> {team.inviteLink ? 'Re-' : ''}Generate Invite Link</span>
    </CopyToClipboard>
  )
}

const DeleteInviteLink = (props) => {
  const { team } = props
  const cache = useQueryCache();
  const [mutateRemoveInviteLink, { status: removeStatus, data: removeData, error: removeError }] = useRemoveInviteLink();
  useEffect(() => {
    if (removeStatus !== 'loading' && removeStatus !== "idle" && removeError) {
      notification['error']({
        message: 'Failed Link removal!',
        description: 'Failed to delete the link.',
      });
    }
    else if (removeStatus === 'success') {
      notification['success']({
        message: 'Link Deleted!',
        description: 'Now no one can register using old link.',
      });
    }
  }, [removeStatus])
  const inviteDeleteHandle = async () => {
    await mutateRemoveInviteLink(team.id)
  }
  return (
    <span className="gx-ml-3 gx-link ant-text-danger" onClick={inviteDeleteHandle}>
      <i className="icon-link" />Delete Invite Link</span>
  )
}

const showNotification = () => {
  notification['success']({
    message: 'Deleted!',
    description: `Team has been successfully deleted`,
  });
}



const UpdateTeam = (props) => {
  const confirm = Modal.confirm;
  const authUser = localStorage.getItem('user_id')
  const { teamId } = useParams();
  const { data: orgs, status: orgStatus } = useOrganizations();
  const orgMembers = orgStatus === "success" && orgs && orgs.length ? orgs[0].members : []

  const [state, setState] = useState({ id: "", name: undefined, deleteConfirm: "", namePrefix: '' });
  const updateState = (id, name, deleteConfirm, namePrefix) => setState({ id, name, deleteConfirm, namePrefix });
  const onChangeHandler = (e) => {
    e.persist();
    if (e.target.name === 'namePrefix') {
      setState(prev => ({
        ...prev,
        [e.target.name]: e.target.value
      }))
    } else {
      const nameVal = e.target.value.length === 1 && e.target.value === " " ? "" : e.target.value;
      if (nameVal === "" || (nameVal.match(config.regex.teamNames) ? true : false)) {
        setState(prev => ({
          ...prev,
          [e.target.name]: nameVal
        }))
      }
    }
  }
  const res = useGetTeam(teamId);
  const team = res.data || {};
  let isTeamError = false
  let isOwner = false
  let isAdmin = false
  if (team && team.name) {
    isOwner = authUser === team.owner.id
    isAdmin = team.admins.find(x => x.id === authUser) !== undefined;
    isTeamError = !(isOwner || isAdmin)
  }
  const { avatar } = team || {}
  if (team && team.name && state.name === undefined) updateState(teamId, team.name, "", team.namePrefix)
  const teamMembers = team.members
  const [mutateUpdateTeam, { status }] = useUpdateTeam();
  const [mutateDelete, { status: delStatus }] = useDeleteTeam();
  const [mutateArchive, { status: archStatus }] = useArchiveTeam();
  const onSave = async () => {
    if (team.name !== state.name || team.namePrefix !== state.namePrefix) {
      await mutateUpdateTeam({ ...state, name: state.name })
      notification['success']({
        message: 'Updated!',
        description: `Team details have been successfully updated.`,
      });
    }
  }

  const avatarData = {
    field: 'avatar',
    ref: 'teams',
    refId: teamId
  }

  const showDeleteConfirm = (team) => {
    (
      confirm({
        title: 'Are you sure delete this team?',
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        async onOk() {
          if (team.id) {
            await mutateDelete(team.id)
            showNotification();
            props.history.push('/main/teams')
          }
        },
        onCancel() {
          console.log('Cancel');
        },
      })
    )
  }

  const showArchiveConfirm = async (team) => {
    (
      await confirm({
        title: 'Are you sure archive this team?',
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        async onOk() {
          if (team.id) {
            await mutateArchive(team.id)
            notification['success']({
              message: 'Archived!',
              description: `Team has been successfully archived`,
            });
            props.history.push('/main/teams')
          }
        },
        onCancel() {
          console.log('Cancel');
        },
      })
    )
  }

  const showNameConfirm = async (team) => {
    notification['warning']({
      message: 'Warning!',
      description: `Please type exact name of team ${team ? "\"" + team.name + "\" " : ""}to delete, before proceeding to click Delete.`,
    });
  }
  const onDelete = async () => {
    if (state.deleteConfirm === state.name) {
      showDeleteConfirm(team)
    }
    else {
      showNameConfirm(team)
    }
  }

  return (
    <Auxiliary>
      {isTeamError && <Error401 />}
      {!isTeamError && (isOwner || isAdmin) && <div>
        <div className="gx-ml-auto row mx-mt-n5">
          <Row className="gx-mb-3">
            <Col xl={8} lg={12} md={12} sm={12} xs={24}>
              <AvatarUpload avatarData={avatarData} alt=""
                src={avatar || defaultAvatar} id={teamId} type="team" />
            </Col>
          </Row>
          <Row>
            <Col xl={8} lg={12} md={12} sm={12} xs={24}>
              <div className="gx-form-group">
                <Input
                  name="name"
                  type="text"
                  disabled={!isOwner}
                  required
                  placeholder="Team Name"
                  onChange={onChangeHandler}
                  value={state.name}
                  margin="none" />
              </div>
            </Col>
          </Row>
          <Row>
            <Col xl={8} lg={12} md={12} sm={12} xs={24}>
              <div className="gx-form-group">
                <Input
                  name="namePrefix"
                  type="text"
                  className="team-update-save-btn"
                  // disabled={!isOwner}
                  required
                  placeholder="Member Name Prefix 'Z - '"
                  onChange={onChangeHandler}
                  value={state.namePrefix}
                  margin="none" />
              </div>
            </Col>
          </Row>
          {(isOwner || isAdmin) && <Row>
            <Col xl={12} lg={12} md={12} sm={12} xs={24}>
              <Button>
                <Link to={`../${teamId}`}>
                  Cancel
                  </Link>
              </Button>
              <Button className="gx-aio-gray-btn" loading={(status === 'loading')} disabled={(status === 'loading')} onClick={() => onSave()} >{(status === 'loading') ? ' Saving...' : 'Save'}</Button>
            </Col>
          </Row>}
          <Row className=" gx-ml-auto  gx-mt-2">
            <div className="gx-profile-banner-avatar-info">
              <h2 className="gx-mb-2 gx-mb-sm-3 gx-fs-xxl gx-font-weight-light">Admins</h2>
              <span>
                <AvatarList data={team.admins} teamId={teamId} type="admin" isOwner={isOwner} />
                {(isOwner && orgStatus === "success") && <AddAdmin teamAdmins={team.admins} teamId={teamId} teamMembers={teamMembers} orgMembers={orgMembers} />}
              </span>
            </div>
          </Row>
          <Row className=" gx-ml-auto gx-mt-3">
            <h2 className="gx-mb-2 gx-mb-sm-3 gx-fs-xxl gx-font-weight-light">Invite Link</h2>
          </Row>
          <Row>
            <Col xl={8} lg={12} md={12} sm={12} xs={24}>
              <GenerateInviteLink team={team} className="team-generate-link-btn" />
            </Col>
            <Col xl={8} lg={12} md={12} sm={12} xs={24}>
              {team.inviteLink && <DeleteInviteLink team={team} className="team-delete-link-btn" />}
            </Col>
          </Row>
        </div>
        {isOwner && <div className="gx-ml-auto gx-mt-5">
          <h2>Permanently Delete Team</h2>
          <Row>
            <Col xl={8} lg={12} md={12} sm={12} xs={24}>
              <p>Please type team name <b>{team.name}</b> in below box to confirm Delete</p>
              <Input
                name="deleteConfirm"
                type="text"
                required
                placeholder="Confirm Team name"
                onChange={onChangeHandler}
                value={state.deleteConfirm}
                margin="none" />
            </Col>
          </Row>
          <Row className="gx-mt-2">
            <Col xl={12} lg={12} md={12} sm={12} xs={24}>
              <Button type='danger'
                loading={(delStatus === 'loading')}
                disabled={(delStatus === 'loading')}
                className="team-delete-btn"
                onClick={() => onDelete()} >
                {(delStatus === 'loading') ? ' Deleting...' : 'Delete Team'}
              </Button>
            </Col>
          </Row>
          <Row className="gx-mt-2">
            <Col xl={12} lg={12} md={12} sm={12} xs={24}>
              <Button type='danger'
                loading={(archStatus === 'loading')}
                disabled={(archStatus === 'loading')}
                onClick={() => showArchiveConfirm(team)} >
                {(archStatus === 'loading') ? ' Archiving...' : 'Archive Team'}
              </Button>
            </Col>
          </Row>
        </div>}
        <div className="gx-position-absolute gx-top-0 gx-mt-4 gx-right-0 gx-mr-5">
          <Link to={`../${teamId}`}>
            <span className="gx-link gx-profile-setting">
              <i className="icon icon-arrow-left gx-fs-lg gx-mr-sm-1 gx-d-inline-flex gx-vertical-align-middle" />
              <span className="gx-d-inline-flex gx-vertical-align-middle gx-ml-0 gx-ml-sm-0">&nbsp;Update Team</span>
            </span>
          </Link>
        </div>
      </div>}
    </Auxiliary >
  )
}

export default UpdateTeam
