import React, { useEffect, useState } from "react";
import { Button, Card, message, Steps, Row, Col, Form, Select, Switch, DatePicker, Input, Modal, Radio } from "antd";
import moment from 'moment'
import Widget from "components/Widget/index";
import { Link, useHistory, useParams } from "react-router-dom";
import useMeetings from '../../../hooks/meetings/useMeetings'
import useCreateMeeting from '../../../hooks/meetings/useCreateMeeting'
import useUpdateMeeting from '../../../hooks/meetings/useUpdateMeeting'
import useTeams from '../../../hooks/teams/useTeams'
import useApps from '../../../hooks/apps/useApps'
import useSubscriptions from '../../../hooks/user/useSubscriptions'
import useProfile from '../../../hooks/user/useProfile'
import useContacts from '../../../hooks/contacts/useContacts'
import useOrganizations from '../../../hooks/organizations/useOrganizations'
import useTemplates from '../../../hooks/meetings/useTemplates'
import useSaveTemplate from '../../../hooks/meetings/useSaveTemplate'
import { createZoomPayload } from '../../../util/createZoomPayload';
import { createMeetingPayload } from '../../../util/createMeetingPayload';
import { createAIOMeetPayload } from '../../../util/createAIOMeetPayload';
import _ from 'lodash';

import "./index.css";

const Step = Steps.Step;
const FormItem = Form.Item;
const Option = Select.Option;
const confirm = Modal.confirm;
const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;

const AddMeeting = (props) => {
  let { meetingId } = useParams();
  const [mutateCreateMeeting, { status: createStatus, error: createError }] = useCreateMeeting();
  const [mutateUpdateMeeting, { status: updateStatus, error: updateError }] = useUpdateMeeting(meetingId);
  const [mutateSaveTemplate, { status: saveStatus }] = useSaveTemplate();

  const history = useHistory();
  const { data: orgs, status: orgStatus } = useOrganizations();
  const { data: profile } = useProfile();
  const { data: subscription } = useSubscriptions();
  const { data: teamData } = useTeams();
  const { data: contacts } = useContacts();
  const { data: apps } = useApps();
  const { data: meetings } = useMeetings();
  const { data: templates } = useTemplates();
  const { getFieldDecorator, validateFields, setFieldsValue, getFieldsValue } = props.form;

  const [startDateTime, setStartTime] = useState(props.startDateTime)
  const [endDateTime, setEndTime] = useState(props.endDateTime)
  const [loading, setLoading] = useState(false)
  const [canRegister, setCanRegister] = useState(false)
  const [canGuestRegister, setCanGuestRegister] = useState(false)
  const [current, setCurrent] = useState(meetingId ? 1 : 0);
  const [type, setType] = useState(2);
  const [vendor, setVendor] = useState("");
  const [payload, setPayload] = useState({});
  const [formState, setFormState] = useState(vendor === "zoom" ? {
    host_video: false,
    participant_video: true,
    mute_upon_entry: true,
    canRegister: false,
    canGuestRegister: false,
    waiting_room: true,
    namePrefix: '',
    teams: []
  } : vendor !== 'aiomeet' ? {
    canRegister: false,
    teams: []
  } :
    {}
  );

  const memData = []
  teamData && teamData.map(t => t.members && t.members.map(x => memData.push(x)))
  const members = _.uniqBy(memData, x => x.id);
  const orgMembers = orgStatus === "success" && orgs && orgs.length ? orgs[0].members : []
  const teams = teamData || [];
  const aiomeetCredits = subscription && subscription.length > 0 ? subscription[0].aiomeetCredits : 0.0;
  const meet = meetings && meetings.find(m => m.id === meetingId)

  const roundBy = 1000 * 60 * 5;
  const estimate = 0;

  if (meet && vendor === "") {
    if (meet.startTime && meet.duration) {
      setStartTime(meet.startTime)
      setEndTime(moment(meet.startTime).add(meet.duration, 'minute').format("YYYY-MM-DDTHH:mm:ssZ"))
    }
    setFormState(vendor === "zoom" ? {
      teams: meet.teams.map(t => t.id),
      topic: meet.topic,
      namePrefix: meet.namePrefix,
      joinUrl: meet.myJoinLink,
      speaker: meet.speaker ? meet.speaker.id : null,
      members: meet.registrants ? meet.registrants.filter(m => m.type === "member").map(m => m.id) : null,
      contacts: meet.registrants ? meet.registrants.filter(m => m.type === "contact").map(m => m.id) : null,
    } : vendor !== 'aiomeet' ?
      {
        teams: meet.teams.map(t => t.id),
        topic: meet.topic,
        joinUrl: meet.myJoinLink,
        host: meet.host ? meet.host.id : null,
        speaker: meet.speaker ? meet.speaker.id : null,
        members: meet.registrants ? meet.registrants.filter(m => m.type === "member").map(m => m.id) : null,
        contacts: meet.registrants ? meet.registrants.filter(m => m.type === "contact").map(m => m.id) : null,
      } :
      {
        topic: meet.topic,
        members: meet.registrants ? meet.registrants.filter(m => m.type === "member").map(m => m.id) : null,
        contacts: meet.registrants ? meet.registrants.filter(m => m.type === "contact").map(m => m.id) : null,
      }
    )
    setVendor(meet.provider)
  }

  useEffect(() => {
    if (current === 1 && saveStatus === "success") {
      message.success("Meeting Tamplate created successfully!")
    }
    else if (current === 1 && createStatus === "success") {
      message.success("Meeting created successfully!")
      history.push('/main/calendar')
    }
    else if (createError) {
      message.error("Meeting creation Failed! Try again later" + createStatus)
      setLoading(false)
    }
    if (current === 1 && (updateStatus === "success")) {
      message.success("Meeting updated successfully!")
      history.push('/main/calendar')
    }
    else if (updateError) {
      message.error("Meeting updation Failed! Try again later" + createStatus)
      setLoading(false)
    }
  }, [saveStatus, createStatus, updateStatus])

  useEffect(() => {
    if (current === 1 && !meetingId && vendor) {
      setFieldsValue(formState);
    }
    else if (meetingId && meet && vendor) {
      setFieldsValue(formState);
    }
  }, [current, formState]);

  useEffect(() => {
    if (vendor && vendor !== "" && !meetingId) {
      setCurrent(1)
      setFormState(vendor === "zoom" ? {
        host_video: false,
        participant_video: true,
        mute_upon_entry: true,
        canRegister: false,
        waiting_room: true,
        teams: []
      } : vendor !== "aiomeet" ?
        {
          canRegister: false,
          teams: []
        } : {}
      )
    }
    else {
      if (!meetingId) { setCurrent(0) }
    }
  }, [vendor]);

  useEffect(() => {
    async function createMeet() {
      if (payload && payload.topic) {
        if (vendor === "zoom") {
          if (payload.start_date) {
            const duration = payload.end_date.diff(payload.start_date, 'minutes')
            const payloadData = { ...payload, duration, provider: vendor }
            payloadData.start_date = payload.start_date.format("YYYY-MM-DDTHH:mm:ssZ")
            const zoomPayload = createZoomPayload(payloadData)
            await mutateCreateMeeting(zoomPayload);
          }
          else {
            const start_date = moment().format("YYYY-MM-DDTHH:mm:ssZ")
            const payloadData = { ...payload, provider: vendor, start_date, organizationId: profile ? profile.defaultOrganization : "" }
            const zoomPayload = createZoomPayload(payloadData)
            await mutateCreateMeeting(zoomPayload);
          }
        }
        else if (vendor === "aiomeet") {
          const duration = payload.end_date.diff(payload.start_date, 'minutes')
          const payloadData = { ...payload, duration, provider: vendor, organizationId: profile ? profile.defaultOrganization : "" }
          payloadData.start_date = payload.start_date.format("YYYY-MM-DDTHH:mm:ssZ")
          payloadData.end_date = payload.end_date.format("YYYY-MM-DDTHH:mm:ssZ")
          const aiomeetPayload = createAIOMeetPayload(payloadData)
          await mutateCreateMeeting(aiomeetPayload);
        }
        else if (vendor.length > 1) {
          const duration = payload.end_date.diff(payload.start_date, 'minutes')
          const payloadData = { ...payload, duration, provider: vendor, organizationId: profile ? profile.defaultOrganization : "" }
          payloadData.start_date = payload.start_date.format("YYYY-MM-DDTHH:mm:ssZ")
          payloadData.end_date = payload.end_date.format("YYYY-MM-DDTHH:mm:ssZ")
          const meetingPayload = createMeetingPayload(payloadData)
          await mutateCreateMeeting(meetingPayload);
        }
        else {
          message.error("Invalid Provider! Cannot create meeting")
        }
      }
    }
    async function updateMeet() {
      if (payload && payload.topic) {
        if (vendor === "zoom") {
          if (payload.start_date) {
            const duration = payload.end_date.diff(payload.start_date, 'minutes')
            const payloadData = { ...payload, duration, provider: vendor }
            payloadData.start_date = payload.start_date.format("YYYY-MM-DDTHH:mm:ssZ")
            const zoomPayload = createZoomPayload(payloadData)
            await mutateUpdateMeeting(zoomPayload);
          }
          else {
            const start_date = moment().format("YYYY-MM-DDTHH:mm:ssZ")
            const payloadData = { ...payload, provider: vendor, start_date, organizationId: profile ? profile.defaultOrganization : "" }
            const zoomPayload = createZoomPayload(payloadData)
            await mutateUpdateMeeting(zoomPayload);
          }
        }
        else if (vendor === "aiomeet") {
          const duration = payload.end_date.diff(payload.start_date, 'minutes')
          const payloadData = { ...payload, duration, provider: vendor, organizationId: profile ? profile.defaultOrganization : "" }
          payloadData.start_date = payload.start_date.format("YYYY-MM-DDTHH:mm:ssZ")
          payloadData.end_date = payload.end_date.format("YYYY-MM-DDTHH:mm:ssZ")
          const aiomeetPayload = createAIOMeetPayload(payloadData)
          await mutateUpdateMeeting(aiomeetPayload);
        }
        else if (vendor.length > 1) {
          const duration = payload.end_date.diff(payload.start_date, 'minutes')
          const payloadData = { ...payload, duration }
          if (payloadData.start_date) payloadData.start_date = payload.start_date.format("YYYY-MM-DDTHH:mm:ssZ")
          if (payloadData.end_date) payloadData.end_date = payload.end_date.format("YYYY-MM-DDTHH:mm:ssZ")
          const meetingPayload = createMeetingPayload(payloadData)
          await mutateUpdateMeeting(meetingPayload);
        }
        else {
          message.error("Invalid Provider! Cannot create meeting")
        }
      }
    }
    meetingId ? updateMeet() : createMeet();
  }, [payload])

  const handleOk = (e) => {
    validateFields(async (err, values) => {
      if (!err) {
        let pLoad = { ...values, registrants: formatMemsAndContacts(values.members, values.contacts) }
        // console.log("🚀 ~ file: newedit.js ~ line 254 ~ validateFields ~ pLoad", pLoad)
        setLoading(true)
        showConfirmCreate(pLoad)
      }
      else {
        message.error("Please fill all required details to proceed!")
      }
    }
    );
  }

  const formatMemsAndContacts = (mems, cons) => {
    let m, c;
    if (mems) {
      m = mems.map(m => {
        const mem = members.find(i => i.id === m)
        if (mem) {
          return {
            id: mem.id,
            name: mem.firstName + " " + mem.lastName,
            type: "member",
            avatar: mem.avatar,
            status: "approved",
            email: mem.email,
            "Invited By": { id: profile.id, email: profile.email, name: profile.firstName + " " + profile.lastName }
          }
        }
      })
    }
    if (cons) {
      c = cons.map(m => {
        const con = contacts.find(i => i.id === m)
        if (con) {
          return {
            id: con.id,
            name: con.firstName + " " + con.lastName,
            type: "contact",
            avatar: con.avatar,
            status: "approved",
            email: con.email,
            "Invited By": { id: profile.id, email: profile.email, name: profile.firstName + " " + profile.lastName }
          }
        }
      })
    }
    console.log(m, c)
    if (m && c) return [...m, ...c]
    else if (m && !c) return [...m]
    else if (!m && c) return [...c]
    else return []
  }

  const handleSaveTemplate = (e) => {
    validateFields(async (err, values) => {
      if (!err) {
        delete values.start_date
        delete values.end_date
        delete values.password
        await mutateSaveTemplate({ ...values, name: values.topic + " Template" })
      }
    })
  }

  const onChangeHandler = (e) => {
    if (e) {
      const selectedTemplate = templates.find(x => x.name === e)
      if (selectedTemplate) {
        setFormState(vendor === "zoom" ? {
          host_video: selectedTemplate.host_video,
          participant_video: selectedTemplate.participant_video,
          mute_upon_entry: selectedTemplate.mute_upon_entry,
          canRegister: selectedTemplate.canRegister,
          canGuestRegister: selectedTemplate.canGuestRegister,
          waiting_room: selectedTemplate.waiting_room,
          teams: selectedTemplate.teams
        } : vendor !== 'aiomeet' ?
          {
            canRegister: selectedTemplate.canRegister,
            teams: selectedTemplate.teams
          }
          : {}
        )
      }
    }
  }
  const showConfirmCreate = (data) => {
    (
      confirm({
        title: 'Do you want to create the meeting?',
        okText: 'Yes',
        okType: 'primary',
        cancelText: 'No',
        async onOk() {
          if (data) {
            setPayload(data);
          }
        },
        onCancel() {
          setLoading(false)
          console.log('Cancel..user wants to make modification', data);
        },
      })
    )
  }

  const handleSelectAll = (value) => {
    if (value && value.length && value.includes("all")) {
      if (value.length === teams.length + 1) {
        return [];
      }
      return [...teams.map(t => t.id)];
    }
    return value;
  }

  const handleSelectAllMembers = (value) => {
    if (value && value.length && value.includes("all")) {
      if (value.length === members.length + 1) {
        return [];
      }
      return [...members.map(t => t.id)];
    }
    return value;
  }

  const handleSelectAllContacts = (value) => {
    if (value && value.length && value.includes("all")) {
      if (value.length === contacts.length + 1) {
        return [];
      }
      return [...contacts.map(t => t.id)];
    }
    return value;
  }

  const onMeetingTypeChange = (e) => {
    setType(e.target.value)
  }

  const prev = () => {
    setVendor("");
    setCurrent(current - 1);
  }

  function validURL(str) {
    var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
    return !!pattern.test(str);
  }

  function validGoogleMeetURL(str) {
    var pattern = new RegExp('https:\/\/(meet\.google\.com\/)[-a-zA-Z0-9@:%._\+~#=]');
    return !!pattern.test(str);
  }

  const validator = (rule, value, callback) => {
    if (value === undefined) {
      return callback();
    }
    if (rule.field === "joinUrl") {
      const nameVal = value.trim().toLowerCase();
      if (nameVal === "") {
        rule.message = "Please enter valid url";
        return callback(true);
      }
      if (vendor !== "googleMeet" && !validURL(nameVal)) {
        return callback(true)
      }
      if (vendor === "googleMeet" && !validGoogleMeetURL(nameVal)) {
        return callback(true)
      }
      callback();
    }

  }

  function interval(start, end) {
    const result = [];
    for (let i = start; i < end; i++) {
      if (i % 5 !== 0)
        result.push(i);
    }
    return result;
  }

  function disabledDateTime() {
    return {
      disabledMinutes: () => interval(0, 60),
    };
  }

  function disabledDate(current) {
    // Can not select days before today and today
    return current && current < moment().endOf('day').subtract(1, 'd');
  }
  return (
    <div>
      <Card className="gx-card" title={<div><Link to={meetingId ? "../../calendar" : "../calendar"}>Calendar / </Link>Switch Step</div>}>
        {vendor !== "" && <Row type="flex" align="middle" justify="center">
          <Col>
            <div style={{ display: 'inline-flex', justifyContent: 'center', alignItems: 'center' }}>
              <img className="" src={require(`assets/images/appLogos/${vendor}.png`)} width="50" alt='flying' />
            </div>
          </Col>
        </Row>}
        <Steps current={current}>
          <Step key={0} title={"Select the Provider"} />
          <Step key={1} title={"Schedule a Meeting"} />
        </Steps>
        <div className="steps-content gx-pt-3">
          < Row className="gx-px-5 gx-pt-3">
            {current === 0 && aiomeetCredits && (aiomeetCredits > 0.0) &&
              <Col key={"aiomeet"} xl={6} lg={6} md={6} sm={12} xs={12} onClick={() => setVendor("aiomeet")}>
                <Widget styleName="gx-card-full gx-dot-arrow-hover gx-p-5 gx-link">
                  <img src={require(`assets/images/appLogos/aiomeet.png`)} width="100" alt="aiomeet" />
                </Widget>
              </Col>
            }
            {current === 0 && apps && apps.length > 0 &&
              apps.map(app => (
                <Col key={app.id} xl={6} lg={6} md={6} sm={12} xs={12} onClick={() => setVendor(app.provider)}>
                  <Widget styleName="gx-card-full gx-dot-arrow-hover gx-p-5 gx-link">
                    <img src={require(`assets/images/appLogos/${app.provider}.png`)} width="100" alt={app.provider} />
                  </Widget>
                </Col>
              ))
            }
          </Row>
          {current === 1 &&
            <Row className="gx-ml-0 gx-mt-0">
              <Col xl={6} lg={6} md={6} sm={0} xs={0}></Col>
              <Col xl={12} lg={12} md={12} sm={24} xs={24} className="calendar-step2">
                <Form
                  labelCol={window.innerWidth > 500 ? { span: 10 } : { span: 24 }}
                  wrapperCol={window.innerWidth > 500 ? { span: 14 } : { span: 24 }}
                  layout={window.innerWidth > 500 ? "horizontal" : "vertical"}
                  className="gx-px-0">
                  {templates && templates.length > 0 && <Row>
                    <Col span={24}>
                      <FormItem label="Template">
                        <Select
                          showSearch
                          style={{ minWidth: '200px' }}
                          placeholder="Select Existing Template"
                          onChange={onChangeHandler}
                          optionFilterProp="children"
                          filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                          {templates.map(mem => <Option key={mem.name} value={mem.name}>{mem.name}</Option>)}
                        </Select>
                      </FormItem>
                    </Col>
                  </Row>}
                  {vendor === "zoom" && vendor !== 'aiomeet' && !meetingId &&
                    <Row>
                      <Col span={24}>
                        <FormItem label="Meeting Type">
                          {getFieldDecorator('type', {
                            rules: [{ required: false, message: 'Please select start date!' }], initialValue: type
                          })(
                            <RadioGroup onChange={onMeetingTypeChange}>
                              <RadioButton style={{ minWidth: 100 }} value={2}>One Time</RadioButton>
                              <RadioButton style={{ minWidth: 100 }} value={3}>Any Time</RadioButton>
                            </RadioGroup>
                          )}
                        </FormItem>
                      </Col>
                    </Row>
                  }
                  {type === 2 &&
                    <Row>
                      <Col span={24}>
                        <FormItem label="Start at">
                          {getFieldDecorator('start_date', {
                            rules: [{ required: true, message: 'Please select start date!' }], initialValue: moment(Math.ceil(moment(startDateTime) / roundBy) * roundBy)
                          })(
                            <DatePicker
                              showTime={{
                                format: 'YYYY-MM-DD HH:mm',
                                hideDisabledOptions: true,
                                minuteStep: 5
                              }}
                              className="gx-w-100"
                              format={"YYYY-MM-DD HH:mm"}
                              disabledDate={disabledDate}
                              disabledTime={disabledDateTime}
                              onChange={(value) => setStartTime(value ? moment(Math.ceil(moment(value) / roundBy) * roundBy) : value)}
                              style={{ width: 300 }}
                              onOk={() => { }}
                            />
                          )}
                        </FormItem>
                      </Col>
                      <Col span={24}>
                        <FormItem label="End date">
                          {getFieldDecorator('end_date', {
                            rules: [{ required: true, message: 'Please select End date!' }], initialValue: moment(Math.ceil(moment(startDateTime ? startDateTime : endDateTime).add(30, 'm') / roundBy) * roundBy)
                          })(
                            <DatePicker
                              showTime={{
                                format: 'YYYY-MM-DD HH:mm',
                                hideDisabledOptions: true,
                                minuteStep: 5
                              }}
                              disabledDate={disabledDate}
                              disabledTime={disabledDateTime}
                              className="gx-w-100"
                              format="YYYY-MM-DD HH:mm"
                              onChange={(value) => setEndTime(value ? moment(Math.ceil(moment(value) / roundBy) * roundBy) : value)}
                              style={{ width: 300 }}
                              onOk={() => { }}
                            />
                          )}
                        </FormItem>
                      </Col>
                    </Row>
                  }
                  <Row>
                    <Col span={24}>
                      <FormItem label="AIO Meet Topic">
                        {getFieldDecorator('topic', {
                          rules: [{ required: true, message: 'Please enter AIO Meet topic!', max: 100 }],
                        })(
                          <Input style={{ minWidth: '200px' }} placeholder="AIO Meet Topic" />
                        )}
                      </FormItem>
                    </Col>
                  </Row>
                  {/* {canGuestRegister && */}
                  <Row>
                    <Col span={24}>
                      <FormItem label="Name Prefix (Optional)">
                        {getFieldDecorator('namePrefix', {
                          rules: [{ required: false, message: 'Please set prefix to member name!', max: 10 }],
                        })(
                          <Input style={{ minWidth: '200px' }} placeholder="e. g. 'A -' prefix will save member as 'A - John Doe'" />
                        )}
                      </FormItem>
                    </Col>
                  </Row>
                  {/* } */}
                  {vendor !== 'aiomeet' &&
                    <Row>
                      <Col span={24}>
                        <FormItem label="Select Host">
                          {getFieldDecorator('host', {
                            rules: [{ required: false, message: 'Meeting Host!' }],
                          })(
                            <Select
                              showSearch
                              style={{ minWidth: '200px' }}
                              optionFilterProp="children"
                              filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                              placeholder="Please select host">
                              {orgMembers && orgMembers.map(item => <Option value={item.id} key={item.id}>{item.firstName + " " + item.lastName}</Option>)}
                            </Select>
                          )}
                        </FormItem>
                      </Col>
                    </Row>
                  }
                  {vendor !== 'aiomeet' &&
                    <Row>
                      <Col span={24}>
                        <FormItem label="Select Speaker">
                          {getFieldDecorator('speaker', {
                            rules: [{ required: false, message: 'Meeting Speaker!' }],
                          })(
                            <Select
                              showSearch
                              style={{ minWidth: '200px' }}
                              optionFilterProp="children"
                              filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                              placeholder="Please select speaker">
                              {orgMembers && orgMembers.map(item => <Option value={item.id} key={item.id}>{item.firstName + " " + item.lastName}</Option>)}
                            </Select>
                          )}
                        </FormItem>
                      </Col>
                    </Row>
                  }
                  {vendor !== 'aiomeet' &&
                    <Row>
                      <Col span={24}>
                        <FormItem label="Select Teams (Optional)">
                          {getFieldDecorator('teams', {
                            getValueFromEvent: handleSelectAll,
                            rules: [{ required: canRegister, message: 'Please select atleast 1 team for guest registration!' }],
                          })(
                            <Select
                              showSearch
                              mode="multiple"
                              style={{ minWidth: '200px' }}
                              optionFilterProp="children"
                              filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                              placeholder="Select teams eligible for registering to this Meeting">
                              <Option value="all" key="all">Select All</Option>
                              {teams && teams.map(item => <Option value={item.id} key={item.id}>{item.name}</Option>)}
                            </Select>
                          )}
                        </FormItem>
                      </Col>
                    </Row>
                  }
                  {contacts && contacts.length > 0 &&
                    <Row>
                      <Col span={24}>
                        <FormItem label="Select Contacts (Optional)">
                          {getFieldDecorator('contacts', {
                            getValueFromEvent: handleSelectAllContacts,
                            rules: [{ required: false, message: 'Please select contacts to register!' }],
                          })(
                            <Select
                              showSearch
                              mode="multiple"
                              style={{ minWidth: '200px' }}
                              optionFilterProp="children"
                              filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                              placeholder="Select contacts for registering them to this Meeting">
                              <Option value="all" key="all">Select All</Option>
                              {contacts && contacts.map(item => <Option value={item.id} key={item.id}>{item.name || item.firstName + " " + item.lastName}</Option>)}
                            </Select>
                          )}
                        </FormItem>
                      </Col>
                    </Row>
                  }
                  {members && members.length > 0 &&
                    <Row>
                      <Col span={24}>
                        <FormItem label="Select Members (Optional)">
                          {getFieldDecorator('members', {
                            getValueFromEvent: handleSelectAllMembers,
                            rules: [{ required: false, message: 'Please select members to register!' }],
                          })(
                            <Select
                              showSearch
                              mode="multiple"
                              style={{ minWidth: '200px' }}
                              optionFilterProp="children"
                              filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                              placeholder="Select members for registering them to this Meeting">
                              <Option value="all" key="all">Select All</Option>
                              {members && members.map(item => <Option value={item.id} key={item.id}>{item.name || item.firstName + " " + item.lastName}</Option>)}
                            </Select>
                          )}
                        </FormItem>
                      </Col>
                    </Row>
                  }

                  {vendor !== 'zoom' && vendor !== 'aiomeet' &&
                    <Row>
                      <Col span={24}>
                        <FormItem label="Meeting join Link">
                          {getFieldDecorator('joinUrl', {
                            rules: [{ required: true, message: 'Please enter meeting join link!', max: 100 },
                            { validator: validator, message: "Please enter valid url" }],
                          })(
                            <Input type="url" style={{ minWidth: '200px' }} placeholder={vendor + " join Link"} />
                          )}
                        </FormItem>
                      </Col>
                    </Row>}
                  {vendor === 'zoom' && vendor !== 'aiomeet' && !meetingId &&
                    <Row>
                      <Col span={24}>
                        <FormItem label="Enter AIO Meet Password">
                          {getFieldDecorator('password', {
                            rules: [{ required: false, message: 'Please enter valid AIO Meet password!' }], initialValue: Math.ceil(Math.random() * Math.pow(10, 10).toString())
                          })(
                            <Input placeholder="AIO Meet Password" />
                          )}
                        </FormItem>
                      </Col>
                      <Col span={24}>
                        <FormItem label="Waitingroom">
                          {getFieldDecorator('waiting_room', {
                            rules: [{ required: true, message: 'Please select!' }], valuePropName: 'checked'
                          })(
                            <Switch />
                          )}
                        </FormItem>
                      </Col>
                    </Row>}
                  {vendor === 'zoom' && vendor !== 'aiomeet' && !meetingId &&
                    <Row>
                      <Col span={24}>
                        <FormItem label="Host Video">
                          {getFieldDecorator('host_video', {
                            rules: [{ required: true, message: 'Please select!' }], valuePropName: 'checked'
                          })(
                            <Switch />
                          )}
                        </FormItem>
                      </Col>
                      <Col span={24}>
                        <FormItem label="Participants Video">
                          {getFieldDecorator('participant_video', {
                            rules: [{ required: true, message: 'Please select!' }], valuePropName: 'checked'
                          })(
                            <Switch />
                          )}
                        </FormItem>
                      </Col>
                    </Row>}
                  {vendor === 'zoom' && vendor !== 'aiomeet' && !meetingId &&
                    <Row>
                      <Col span={24}>
                        <FormItem label="Mute Upon Entry">
                          {getFieldDecorator('mute_upon_entry', {
                            rules: [{ required: true, message: 'Please select!' }], valuePropName: 'checked'
                          })(
                            <Switch />
                          )}
                        </FormItem>
                      </Col>
                    </Row>}
                  {vendor !== 'aiomeet' && !meetingId &&
                    <Row>
                      <Col span={24}>
                        <FormItem label="Allow Registration">
                          {getFieldDecorator('canRegister', {
                            rules: [{ required: true, message: 'Please select!' }], valuePropName: 'checked'
                          })(
                            <Switch onChange={(value) => { setCanRegister(value); if (value) { const values = props.form.getFieldsValue(); setFormState({ ...formState, teams: values.teams, canRegister: value, canGuestRegister: false }) } else { let t = formState; delete t.canGuestRegister; setFormState(t) } }} />
                          )}
                        </FormItem>
                      </Col>
                    </Row>
                  }
                  {canRegister && !meetingId &&
                    <Row>
                      <Col span={24}>
                        <FormItem label="Allow Guest Registration">
                          {getFieldDecorator('canGuestRegister', {
                            rules: [{ required: true, message: 'Please select!' }], valuePropName: 'checked'
                          })(
                            <Switch onChange={(value) => { setCanGuestRegister(value) }} />
                          )}
                        </FormItem>
                      </Col>
                    </Row>
                  }
                </Form>
              </Col>
              <Col xl={6} lg={6} md={6} sm={0} xs={0}></Col>
            </Row>
          }</div>
        <div className="steps-action">
          {current === 2 - 1
            &&
            <Button type="submit"
              loading={loading}
              className="gx-aio-gray-btn gx-mr-0"
              style={{ float: 'right' }}
              onClick={handleOk}>{meetingId ? "Update" : "Schedule"}</Button>
          }
          {current === 2 - 1
            &&
            <Link to={meetingId ? "../../calendar" : "../calendar"}>
              <Button type="secondary"
                loading={loading}
                className="gx-mr-3"
                style={{ float: 'right' }}>{meetingId ? "Cancel" : "Back"}</Button>
            </Link>
          }
          {current > 0 && !meetingId
            &&
            <Button className="gx-ml-0" onClick={() => prev()}>
              Previous
            </Button>
          }
          {current === 1 && vendor !== 'aiomeet' && !meetingId &&
            <p><Button style={{ float: 'right' }} key="save" onClick={handleSaveTemplate}>Save as template</Button></p>
          }
        </div>
      </Card>
    </div >
  );
}

export const WrappedCreateMeeting = Form.create()(AddMeeting);