import { useQuery, useQueryCache } from "react-query";
import { query } from '../../util/config'
import auth from '../../api/auth'

export default function useVerifyToken(inviteToken) {
  query.initialQuery.retry = 0
  return useQuery(
    [inviteToken],
    () => auth.verifyInviteToken(inviteToken),
    query.initialQuery
  )
}