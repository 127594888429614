//UI Imports
import React, { useState } from "react";
import { Input, Modal, Form, Row, notification } from "antd";
import IntlMessages from "../../../util/IntlMessages";
import Errors from '../Errors';

//Data Imports
import { useQueryCache } from "react-query";
import useCreateContact from '../../../hooks/contacts/useCreateContact'
import useUpdateContact from '../../../hooks/contacts/useUpdateContact'
import { isEqual, startCase } from 'lodash';
import config from '../../../util/config';
const FormItem = Form.Item;

const AddEditForm = (props) => {
  const [disabled, setDisable] = useState(true);
  const setDisabled = (val) => setDisable(val)
  const { updateRequest, onContactClose, contact, contacts, form } = props;
  const { getFieldDecorator, validateFields } = form;
  const [mutateCreate, { status: addStatus, error: addError }] = useCreateContact();
  const [mutateUpdate, { status: updateStatus, error: updateError }] = useUpdateContact();

  const cache = useQueryCache();
  const store = cache.getQueryData(["contacts"]);
  let data = undefined;
  if (updateRequest) {
    data = store.filter(c => c.id === contact.id)[0];
  }

  const validator = (rule, value, callback) => {
    if (value === undefined) {
      setDisabled(true);
      return callback();
    }
    if (rule.field === "firstName" || rule.field === "lastName") {
      const nameVal = value.trim().toLowerCase();
      if (nameVal === "" || (nameVal.match(config.regex.userNames) ? false : true)) {
        rule.message = "Please enter valid name";
        setDisabled(true);
        return callback(true);
      }
    }
    else if (rule.field === "email") {
      const emailVal = value.trim().toLowerCase();
      if (((contacts.find(c => c.email.toLowerCase() === emailVal && (contact ? c.id !== contact.id : true))) !== undefined)) {
        setDisabled(true);
        return callback(true);
      }
      else if (emailVal.length > 0) {
        let lastAtPos = emailVal.lastIndexOf('@');
        let lastDotPos = emailVal.lastIndexOf('.');

        if (!(lastAtPos < lastDotPos && lastAtPos > 0 && emailVal.indexOf('@@') === -1 && lastDotPos > 2 && (emailVal.length - lastDotPos) > 2)) {
          setDisabled(true);
          return callback();
        }
      }
      validateFields(['emailConfirm'])
    }
    else if (rule.field === "emailConfirm") {
      const emailVal = value.trim().toLowerCase();
      if (((contacts.find(c => c.email.toLowerCase() === emailVal && (contact ? c.id !== contact.id : true))) !== undefined)) {
        setDisabled(true);
        return callback(true);
      }
      else if (emailVal.length > 0) {
        let lastAtPos = emailVal.lastIndexOf('@');
        let lastDotPos = emailVal.lastIndexOf('.');

        if (!(lastAtPos < lastDotPos && lastAtPos > 0 && emailVal.indexOf('@@') === -1 && lastDotPos > 2 && (emailVal.length - lastDotPos) > 2)) {
          setDisabled(true);
          return callback();
        }
        var x = document.getElementsByName("email")[0].value;
        if (x && x !== emailVal) {
          setDisabled(true);
          return callback(true);
        }
      }
    }
    else if (rule.field === "phone") {
      const phoneVal = value.trim().toLowerCase();
      if (phoneVal.length !== 10 && phoneVal.length > 0) {
        rule.message = "";
        setDisabled(true);
        return callback(true);
      }
      else if (phoneVal.length > 0 && phoneVal.length !== phoneVal.replace(/[^\d]/, '').length) {
        rule.message = "Please enter valid number";
        setDisabled(true);
        return callback(true);
      }
    }
    setDisabled(false);
    callback();
  }

  return (
    <Modal
      title={updateRequest === false ?
        <IntlMessages id="contact.addContact" /> :
        <IntlMessages id="contact.updateContact" />}
      visible={true}
      okButtonProps={{ disabled: (addStatus === 'loading' || updateStatus === 'loading') || disabled }}
      confirmLoading={(addStatus === 'loading' || updateStatus === 'loading')}
      closable={false}
      okText={updateRequest === false ?
        <IntlMessages id="contact.button.addContact" /> :
        <IntlMessages id="contact.button.updateContact" />}
      onOk={() => {
        validateFields(async (err, values) => {
          if (!err) {
            if (values.email) values.email = values.email.toLowerCase()
            if (updateRequest && !isEqual(values, contact)) {
              let payload = { ...values, id: contact.id, firstName: startCase(values.firstName.toLowerCase()), lastName: startCase(values.lastName.toLowerCase()) }
              await mutateUpdate(payload)
              if (!updateError) {
                notification['success']({
                  message: 'Updated!',
                  description: `Contact details have been successfully updated.`,
                });
                onContactClose();
              }
            }
            else if (updateRequest) {
              setDisabled(true);
            }
            else {
              let payload = { ...values, firstName: startCase(values.firstName.toLowerCase()), lastName: startCase(values.lastName.toLowerCase()) }
              await mutateCreate({ ...payload });
              console.log("🚀 ~ file: newedit.js ~ line 131 ~ validateFields ~ payload", payload)
              if (!addError) {
                notification['success']({
                  message: 'Contact Added!',
                  description: `Contact has been successfully Added.`,
                });
                onContactClose();
              }
            }
          }
          else {
            console.log(err)
          }
        })
      }}
      onCancel={onContactClose}>
      <div className="gx-ml-auto">
        <Row className="gx-mt-3">
          {addError && <Errors errors={data} />}
          {updateError && <Errors errors={data} />}
        </Row>
        <Form layout="inline" className="gx-form-inline-label-up">
          <FormItem className="gx-form-item-two-fourth">
            {getFieldDecorator('firstName', {
              rules: [{ required: true, message: 'Please input First Name!', max: 25 },
              { validator: validator }
              ], initialValue: (data ? data.firstName : "")
            })(
              <Input type="text" name={"firstName"} placeholder="First Name" autoComplete="off" />
            )}
          </FormItem>
          <FormItem className="gx-form-item-two-fourth">
            {getFieldDecorator('lastName', {
              rules: [{ required: true, message: 'Please input Last Name!', max: 25 },
              { validator: validator }
              ], initialValue: (data ? data.lastName : "")
            })(
              <Input type="text" name={"lastName"} placeholder="Last Name" autoComplete="off" />
            )}
          </FormItem>
          <FormItem className="gx-form-item-two-fourth">
            {getFieldDecorator('email', {
              rules: [{ required: true, message: 'Please input valid Email!', type: 'email', max: 50 },
              { validator: validator, message: 'Email already exist!' }
              ], initialValue: (data ? data.email : "")
            })(
              <Input type="email" name={"email"} placeholder="Email" autoComplete="off" />
            )}
          </FormItem>
          <FormItem className="gx-form-item-two-fourth">
            {getFieldDecorator('emailConfirm', {
              rules: [{ required: true, message: 'Please input valid Email!', type: 'email', max: 50 },
              { validator: validator, message: 'Email did not match!' }
              ], initialValue: (data ? data.email : "")
            })(
              <Input type="email" name={"emailConfirm"} placeholder="Confirm Email" autoComplete="off" />
            )}
          </FormItem>

          <FormItem className="gx-form-item-two-fourth">
            {getFieldDecorator('phone', {
              rules: [{ required: false, message: 'Please input valid Phone Number!', max: 10 },
              { validator: validator, message: 'Phone number already exist!' }
              ], initialValue: (data ? data.phone : "")
            })(
              <Input type="tel" name={"phone"} placeholder="Phone" autoComplete="off" />
            )}
          </FormItem>
        </Form>
      </div>
    </Modal>
  );
}

export const WrappedEditContact = Form.create()(AddEditForm);
