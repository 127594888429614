import firebase from "firebase";

// Initialize Firebase
const config = {
  apiKey: "AIzaSyDGRprFS4alYs49SSgc_VlwEdc4p30MXyE",
  authDomain: "instazoomus.firebaseapp.com",
  databaseURL: "https://instazoomus.firebaseio.com",
  projectId: "instazoomus",
  storageBucket: "instazoomus.appspot.com",
  messagingSenderId: "931678611394",
  appId: "1:931678611394:web:92d8129780aedc6d1ad85b",
  measurementId: "G-6YLYK7L1XJ"
};

firebase.initializeApp(config);
const auth = firebase.auth();

const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
const facebookAuthProvider = new firebase.auth.FacebookAuthProvider();
const githubAuthProvider = new firebase.auth.GithubAuthProvider();
const twitterAuthProvider = new firebase.auth.TwitterAuthProvider();

const database = firebase.database();
const db = firebase.firestore()
const FieldValue = firebase.firestore.FieldValue
export {
  db,
  database,
  FieldValue,
  auth,
  googleAuthProvider,
  githubAuthProvider,
  facebookAuthProvider,
  twitterAuthProvider
};
