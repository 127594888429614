import { useMutation, useQueryCache } from "react-query";
import axios from '../../axiosWrapper'
import { query } from '../../util/config'

export default function useUpdateTeam() {
  const cache = useQueryCache();
  return useMutation(({ id, name, namePrefix }) => axios.put(`teams/${id}`, { name, namePrefix }), {
    onSuccess: (updated) => {
      if (updated && updated.id) {
        cache.setQueryData(["teams", updated.id], (prev) => {
          prev.name = updated.name
          return prev
        })
        cache.setQueryData(["teams"], (prev) => {
          return prev.map(team => team.id === updated.id ? { ...team, name: updated.name } : team);
        })
      }
    }
  }, query.initialQuery)
}
