import React from "react";
import { Avatar, Tooltip } from "antd";
import { Link, useParams } from "react-router-dom";
import defaultAvatar from "../../../assets/images/placeholder.png"
import teamDefaultAvatar from "../../../assets/images/teamicon.png"

const AvatarList = (props) => {
  const { data, className, type = "" } = props
  const links = []
  data && data.forEach(item => {
    const displayName = item.displayName || `${item.firstName} ${item.lastName}`
    links.push(<Tooltip key={Math.random(3)} title={`${displayName} ${type && `(${type})`}`}>< Avatar
      key={displayName}
      className={"gx-fs-xl gx-size-30 gx-mr-2 " + className}
      alt={displayName || "..."}
      src={item.avatar || defaultAvatar} /></Tooltip>)
  })
  return links
}
const TeamHeader = (props) => {
  const { teamId } = useParams();
  const { data: propsData, authUser } = props
  const avatar = propsData.avatar || teamDefaultAvatar
  const isAdmin = propsData.admins.find(x => x.id === authUser) !== undefined;
  return (
    <div className="gx-profile-banner team">
      <div className="gx-profile-container">
        <div className="gx-profile-banner-top">
          <div className="gx-profile-banner-top-left">
            <div className="gx-profile-banner-avatar">
              <Avatar className="gx-size-90" alt={propsData.name || "..."} src={avatar} />
            </div>
            <div className="gx-profile-banner-avatar-info">
              <h2 className="gx-mb-2 gx-mb-sm-3 gx-fs-xxl gx-font-weight-light">{propsData.name || ''}</h2>
              <span>
                <AvatarList data={[propsData.owner]} className="gx-mr-4" />
                <AvatarList data={propsData.admins} type="admin" />
                {/* {status === "success" && (authUser === propsData.owner.id) && <AddAdmin teamId={teamId} teamMembers={teamMembers} orgMembers={orgMembers} />} */}
              </span>

            </div>
          </div>
          {(authUser === propsData.owner.id || isAdmin) && <div className="gx-profile-banner-top-right">
            <Link to={`./${teamId}/updateTeam`} className="team-update-btn">
              <span className="gx-link gx-profile-setting">
                <i className="icon icon-setting gx-fs-lg gx-mr-2 gx-mr-sm-3 gx-d-inline-flex gx-vertical-align-middle" />
                <span className="gx-d-inline-flex gx-vertical-align-middle gx-ml-1 gx-ml-sm-0">Update Team</span>
              </span>
            </Link>
          </div>}
        </div>
      </div>
    </div>
  )
};

export default TeamHeader;
