import React from "react";
import { useQueryCache } from "react-query";

// import userRole from '../../../services/userRole'

const Reports = () => {
  const cache = useQueryCache();
  const profile = cache.getQueryData(["profile"]);
  return (
    <div>
      <h1>Reports</h1>
      <h2>Welcome {profile.firstName} {profile.lastName}</h2>
      <h3>Please contact app@aiomeet.com to complete your your account setup.</h3>
    </div>
  )
}

export default Reports