import React, { useEffect } from "react";
import jwt from "jsonwebtoken"
import { Button, Checkbox, Form, Input, notification } from "antd";
import { useHistory } from "react-router-dom";
import Auxiliary from "util/Auxiliary";
import aioLogo from '../assets/images/instazoom-logo-tr-blue-30.png'

import { useDispatch, useSelector } from "react-redux";
import {
  hideMessage,
  showAuthLoader,
  userSignUp,
} from "appRedux/actions/Auth";

import IntlMessages from "util/IntlMessages";
import { message } from "antd/lib/index";
import CircularProgress from "components/CircularProgress/index";
import * as QueryString from "query-string"
import useVerifyToken from '../hooks/auth/useVerifyToken'

const FormItem = Form.Item;

const TeamInvite = (props) => {
  let name = ''
  let avatar = ''
  let role = ''
  const authUser = localStorage.getItem('verify')
  const dispatch = useDispatch();
  const history = useHistory();
  const { inviteToken } = QueryString.parse(props.location.search);
  const { status, isLoading, isFetching, isFetched, isError, data } = useVerifyToken(inviteToken);
  if (inviteToken) {
    const decode = jwt.decode(inviteToken)
    name = decode.name
    avatar = decode.avatar
    role = decode.role
  }

  const { loader, alertMessage, showMessage } = useSelector(({ auth }) => auth);

  useEffect(() => {
    if (showMessage) {
      setTimeout(() => {
        dispatch(hideMessage());
      }, 100);
    }
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(inviteToken)
    props.form.validateFields((err, values) => {
      if (!err && inviteToken) {
        dispatch(showAuthLoader());
        dispatch(userSignUp(values, inviteToken));
      }
    });
  };

  const { getFieldDecorator } = props.form;

  return (
    <Auxiliary>
      {isLoading && <CircularProgress />}
      {!isLoading && isError && notification['error']({
        message: 'Invalid',
        description: 'Invalid invitation link'
      })}
      <div className="gx-app-login-wrap">
        <div className="gx-app-login-container">
          <div className="gx-app-login-main-content">
            <div className="gx-app-logo-content gx-aio-gray-back">
              {/* <h1><IntlMessages id="app.userAuth.register" /></h1> */}
              <div>
                <a href="/"><img alt="AIO Meet" src={aioLogo} /></a>
              </div>
              <div className="gx-app-logo gx-mt-4">
                <img className="gx-rounded-lg" alt="example" width="200" src={avatar || require("assets/images/instazoom-logo-tr-white.png")} />
              </div>
              <div className="gx-app-logo-wid gx-mt-4">
                <p className="h3 gx-text-white">You have been invited to join <br />
                  <span className="gx-font-weight-medium">{name}</span>'s Team.</p>
              </div>
            </div>
            {isError && <div className="gx-app-login-content gx-text-white">
              <h1 className="gx-mt-5">Sorry for the inconvenience.</h1>
              <h2>Please ask Team admin to share valid invitation link.</h2>
            </div>}
            {(!isError && !authUser) && <div className="gx-app-login-content">
              <Form onSubmit={handleSubmit} className="gx-signup-form gx-form-row0">
                <FormItem>
                  {getFieldDecorator('firstName', {
                    rules: [{ required: true, message: 'Please input your First Name!' }],
                  })(
                    <Input placeholder="First Name" />
                  )}
                </FormItem>
                <FormItem>
                  {getFieldDecorator('lastName', {
                    rules: [{ required: true, message: 'Please input your Last Name!' }],
                  })(
                    <Input placeholder="Last Name" />
                  )}
                </FormItem>

                <FormItem>
                  {getFieldDecorator('email', {
                    rules: [{
                      required: true, type: 'email', message: 'The input is not valid E-mail!',
                    }],
                  })(
                    <Input placeholder="Email" />
                  )}
                </FormItem>
                <FormItem>
                  {getFieldDecorator('password', {
                    rules: [{ required: true, message: 'Please input your Password!' }],
                  })(
                    <Input type="password" placeholder="Password" />
                  )}
                </FormItem>
                <FormItem>
                  {getFieldDecorator('remember', {
                    valuePropName: 'checked',
                    initialValue: true,
                  })(
                    <Checkbox><IntlMessages id="appModule.iAccept" /></Checkbox>
                  )}
                  <span className="gx-link gx-signup-form-forgot"><IntlMessages
                    id="appModule.termAndCondition" /></span>
                </FormItem>
                <FormItem>
                  <Button
                    disabled={loader}
                    loading={loader}
                    className="gx-aio-gray-btn gx-mb-0" htmlType="submit">
                    <IntlMessages id="app.userAuth.register" />
                  </Button>
                  <span className="gx-mr-3">or</span>
                  <a href="/">Login</a>
                </FormItem>
              </Form>
            </div>}
            {authUser && <div className="gx-app-login-content gmpt-5">
              <br /><br /><br /><br />
              <h3 className="gx-pt-5 gx-mt-5">Please verify your email Id and <a href="/signin">Login</a></h3>
            </div>}
            {showMessage &&
              message.error(alertMessage)}
          </div>
        </div>
      </div>
    </Auxiliary>
  );
};


const WrappedSignUpForm = Form.create()(TeamInvite);


export default WrappedSignUpForm;
