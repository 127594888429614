import { useMutation, useQueryCache } from "react-query";
import axios from '../../axiosWrapper'
import { query } from '../../util/config'

export default function useFetchDashboard() {
  const cache = useQueryCache();
  return useMutation((id) => axios.get(`dashboards`), {
    onSuccess: async (dashboards) => {
      cache.setQueryData(["dashboards"], dashboards)
    }
  }, query.initialQuery)
}
