import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { fetchUserTeamsSuccess, addUserTeamSuccess, updateTeamSuccess, addTeamMemberSuccess, getTeamMembersSuccess, deleteTeamSuccess } from '../actions/Teams'
import { db, auth } from '../../firebase/firebase';
import { ADD_USER_TEAMS, GET_USER_TEAMS, ADD_TEAM_MEMBER, GET_TEAM, DELETE_TEAM, UPDATE_USER_TEAMS } from '../../constants/ActionTypes';
import { fetchError } from "../actions/Common";
import teamsService from "../../api/team"
const authUser = localStorage.getItem('user_id')

const getUserTeams = async (uid) => {
  if (uid) {
    const teams = await teamsService.getTeams()
    return teams
  }
}

const getTeamById = async (teamId, uid, cb) => {
  console.log("🚀 ~ file: Teams.js ~ line 17 ~ getTeamById ~ teamId", teamId)
  if (uid && teamId) {
    // try {
    //   const res = await teamsService.getTeamById(teamId)
    //   cb()
    //   return res.data
    // } catch (error) {
    //   console.log("🚀 ~ file: Teams.js ~ line 28 ~ getTeamById ~ error", error)
    //   return error
    // }
  }
}

const updateTeamById = async (team, cb) => {
  console.log("🚀 ~ file: Teams.js ~ line 17 ~ getTeamById ~ teamId", team)
  if (team && team.id) {
    try {
      const res = await teamsService.updateTeam(team)
      cb()
      return res.data
    } catch (error) {
      console.log("🚀 ~ file: Teams.js ~ line 28 ~ getTeamById ~ error", error)
      return error
    }
  }
}

const deleteUserTeam = async (teamId, cb) => {
  console.log("🚀 ~ file: Teams.js ~ line 43 ~ deleteTeam ~ deleteTeam", teamId)
  if (teamId) {
    try {
      const res = await teamsService.deleteTeam(teamId)
      cb()
      return res
    } catch (error) {
      console.log("🚀 ~ file: Teams.js ~ line 28 ~ getTeamById ~ error", error)
      cb()
      return error
    }
  }
  return {};
}


const addUserTeam = async (team, uid, cb) => {
  if (uid && team && team.name) {
    try {
      const res = await teamsService.createTeam(team)
      if ('error' in res) {
        console.log("error while fetching addUserTeam");
        return {};
      }
      cb()
      return res
    } catch (error) {
      console.log("🚀 ~ file: Teams.js ~ line 75 ~ addUserTeam ~ error", error)
      return error
    }
  }
}

const addTeamMember = async (teamId, member, uid, cb) => {
  if (uid && member) {
    // console.log('CALL: addTeamMember ----------------> ', member, teamId)
    const teamsRef = db.collection('teams').doc(uid).collection('teams').doc(teamId)
    teamsRef.set({ members: { ...member } }, { merge: true }).then(async () => {
      cb(member)
    }).catch(function (error) {
      console.log("Error getting document:", error);
      cb()
    });
    return { id: teamId, member }
  }
}

function* addTeamMemberOnSuccess(payload) {
  const { teamId, member, cb } = payload
  try {
    const uid = authUser
    const addedTeamMember = yield call(addTeamMember, teamId, member, uid, cb);
    yield put(addTeamMemberSuccess(addedTeamMember));
  } catch (error) {
    console.log("Error getting document:", error);
    cb()
  }
}

function* getTeamOnSuccess(payload) {
  const { teamId, cb } = payload
  try {
    const uid = authUser
    const team = yield call(getTeamById, teamId, uid, cb);
    yield put(getTeamMembersSuccess(team));
  } catch (error) {
    console.log("Error getting document:", error);
    cb()
  }
}

function* updateTeamOnSuccess(payload) {
  const { team, cb } = payload
  try {
    const uid = authUser
    const team = yield call(updateTeamById, team, cb);
    yield put(updateTeamSuccess(team));
  } catch (error) {
    console.log("Error getting document:", error);
    cb()
  }
}

function* deleteTeamOnSuccess(payload) {
  const { team, cb } = payload
  try {
    const deletedTeam = yield call(deleteUserTeam, team.id, cb);
    yield put(deleteTeamSuccess(deletedTeam));
  } catch (error) {
    console.log(error)
    yield put(fetchError(error));
  }
}

function* addUserTeamOnSuccess(payload) {
  const { team, cb } = payload
  try {
    const uid = authUser || auth.uid
    const addedUserTeam = yield call(addUserTeam, team, uid, cb);
    yield put(addUserTeamSuccess(addedUserTeam));
  } catch (error) {
    console.log(error)
    yield put(fetchError(error));
  }
}

function* fetchUserTeamsOnSuccess() {
  try {
    const uid = authUser || auth.uid || auth.currentUser.uid
    const fetchedUserTeams = yield call(getUserTeams, uid);
    yield put(fetchUserTeamsSuccess(fetchedUserTeams));
  } catch (error) {
    console.log(error)
    yield put(fetchError(error));
  }
}

function* fetchUserTeams() {
  yield takeEvery(GET_USER_TEAMS, fetchUserTeamsOnSuccess)
}

export function* addTeam() {
  yield takeEvery(ADD_USER_TEAMS, addUserTeamOnSuccess);
}

export function* updateTeam() {
  yield takeEvery(UPDATE_USER_TEAMS, updateTeamOnSuccess);
}

export function* addMember() {
  yield takeEvery(ADD_TEAM_MEMBER, addTeamMemberOnSuccess)
}

export function* getTeam() {
  yield takeEvery(GET_TEAM, getTeamOnSuccess)
}

export function* deleteTeam() {
  yield takeEvery(DELETE_TEAM, deleteTeamOnSuccess)
}

export default function* rootSaga() {
  yield all([
    fork(addTeam),
    fork(deleteTeam),
    fork(fetchUserTeams),
    fork(addMember),
    fork(getTeam),
    fork(updateTeam),
  ]);
}
