import React from "react";
import Widget from "components/Widget/index";
import { Dropdown, Icon } from "antd";
import defaultAvatar from "../../assets/images/placeholder.png"

class UserCard extends React.Component {
  render() {
    const { user, resend, cb, menu, id, showAvatar = true } = this.props;
    const { firstName, lastName, email, phone, avatar, displayName } = user
    return (
      <Widget styleName="gx-card-full gx-dot-arrow-hover">
        <div className="gx-user-wid-row">
          {showAvatar && <div className="gx-user-wid">
            <img alt="..." src={avatar || defaultAvatar} className="gx-object-cover" />
          </div>}
          <div className="gx-user-wid-body gx-py-3 gx-pr-3 gx-ml-3">
            <div className="ant-row-flex">
              <h2 className="h4 gx-mr-1">{displayName || `${firstName} ${lastName}`} </h2>
            </div>
            <p className="gx-mb-1 gx-text-grey gx-fs-sm">{email}</p>
            <p className="gx-mb-1 gx-text-grey gx-fs-sm">{phone || '-'}</p>
            {!resend || <p onClick={() => cb(user)} className="gx-mt-3">Re-send Invite</p>}
            <div className="gx-dot-arrow">
              {menu && <Dropdown overlay={() => menu(user)} id={id} trigger={['click']} className="ant-menu-icon">
                <div className="gx-dot">
                  <i className="icon icon-ellipse-v gx-text-primary" />
                </div>
              </Dropdown>}
            </div>
          </div>
        </div>
      </Widget>
    );
  }
};
export default UserCard;