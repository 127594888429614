import { useQuery, useQueryCache } from "react-query";
import axios from '../../axiosWrapper'
import { query } from '../../util/config'

export default async function useGetTeamMeetings(teamId) {
  const cache = useQueryCache();
  const team = cache.getQueryData(['teams', teamId, 'meetings']);
  return useQuery(
    ['teams', teamId, 'meetings'],
    () => team || axios.get(`teams/${teamId}/meetings`),
    query.initialQuery
  )
}