import axios from '../axiosWrapper';

export const getContacts = async () => {
  return axios.get('contacts')
}

export const addContact = async (user) => {
  const { firstName, lastName, email, phone } = user
  return axios.post('contacts', { firstName, lastName, email, phone })
}

export const updateContact = async (user) => {
  const { firstName, lastName, email, phone, id } = user
  return axios.get(`contacts/${id}`, { firstName, lastName, email, phone })
}

export const deleteContact = async (id) => {
  return axios.delete(`contacts/${id}`)
}

export default { getContacts, deleteContact, addContact, updateContact };