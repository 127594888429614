import { useMutation, useQueryCache } from "react-query";
import axios from '../../axiosWrapper'
import { query } from '../../util/config'

export default function useAddAdminToTeam(team) {
  const cache = useQueryCache();
  //const team = cache.getQueryData(["teams", id]);
  if (team) team.members = team && team.members ? team.members : [];
  return useMutation(async (member) => {
    let memIds = team.members.map(mem => mem.id)
    if (!memIds.includes(member.id)) {
      team.members = [...team.members, member]
      const uniqueIds = team.members.map(item => item.id).filter((value, index, self) => self.indexOf(value) === index)
      return await axios.put(`teams/${team.id}`, { members: uniqueIds })
    }
    return -1;
  }, {
    onSuccess: (updated) => {
      if (updated && updated.id) {
        cache.setQueryData(["teams", updated.id], (prev) => {
          prev.members = team.members
          return prev
        })
      }
    }
  }, query.initialQuery)
}
