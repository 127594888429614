import React from "react";
import { Link } from "react-router-dom";
//Data imports
import { useQueryCache } from "react-query";
import useContacts from '../../../hooks/contacts/useContacts'
import useDeleteContact from '../../../hooks/contacts/useDeleteContact'
import useArchiveContact from '../../../hooks/contacts/useArchiveContact'

//UI imports
import CircularProgress from "components/CircularProgress/index";
import IntlMessages from "util/IntlMessages";
import { Col, Row, Menu, Modal, notification, Input, Button } from "antd";
import UserCard from "components/Widgets/UserCard";
import Auxiliary from "util/Auxiliary";
import { WrappedEditContact } from "./newedit";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button as FloatButton, lightColors } from 'react-floating-action-button'
import { faEdit, faTrash, faUserPlus, faPen, faFile, faList, faArchive } from '@fortawesome/free-solid-svg-icons'
const Search = Input.Search;
const ContactList = () => {
  const confirm = Modal.confirm;
  const cache = useQueryCache();
  const subscriptions = cache.getQueryData(["subscriptions"])
  const { status, isLoading, isFetching, isFetched, isError, data } = useContacts();
  const [mutateDelete] = useDeleteContact();
  const [mutateArchive] = useArchiveContact();
  const [state, setState] = React.useState({ addRequest: false, updateRequest: false });
  const [contact, setContact] = React.useState({});
  const currentContact = (contact) => setContact(contact);
  const toggleAdd = () => setState({ addRequest: !state.addRequest, updateRequest: state.updateRequest });
  const toggleUpdate = () => setState({ addRequest: state.addRequest, updateRequest: !state.updateRequest });
  const [filter, setFilter] = React.useState("");
  const changeFilter = (param) => setFilter(param);

  let features = []
  if (subscriptions) subscriptions.map(sub => features.push(...sub.features))

  const RenderCards = (props) => {
    const { contacts } = props
    if (!contacts || contacts.length === 0) return (<h1>No contacts found!</h1>)
    return contacts.map((contact) => {
      return (
        <Col key={Math.random()} xl={6} lg={12} md={12} sm={12} xs={24}>
          <UserCard showAvatar={false} id={contact.email} user={contact} menu={menu} />
        </Col>
      )
    })
  }

  const menu = (contact) => {
    return (
      <Menu onClick={(e) => menuClick(e.key, contact)}>
        {(features.indexOf('notes') !== -1) && <Menu.Item key="addNote" className="contact-card-menu-addNote-btn">
          <Link to={`/main/contacts/contactNote/${contact.id}`}>
            <FontAwesomeIcon icon={faFile} />&nbsp;
           <span>Add Note</span>
          </Link>
        </Menu.Item>}
        {(features.indexOf('notes') !== -1) && <Menu.Item key="viewNotes" className="contact-card-menu-viewNote-btn">
          <Link to={`/main/notes`}>
            <FontAwesomeIcon icon={faFile} />&nbsp;
           <span>View Notes</span>
          </Link>
        </Menu.Item>}
        {(features.indexOf('contact.register') !== -1) && <Menu.Item key="register">
          <Link to={`/main/contacts/${contact.id}/register`} className="contact-card-menu-register-btn">
            <FontAwesomeIcon icon={faPen} />&nbsp;
           <span>Register</span>
          </Link>
        </Menu.Item>}
        {(features.indexOf('activity') !== -1) && <Menu.Item key="activity" className="contact-card-menu-activity-btn">
          <Link to={`/main/contacts/${contact.id}/activity`}>
            <FontAwesomeIcon icon={faList} />&nbsp;<span>Activity</span>
          </Link>
        </Menu.Item>}
        <Menu.Item key="edit" className="contact-card-menu-edit-btn">
          <FontAwesomeIcon icon={faEdit} />&nbsp;
           <span>Edit</span>
        </Menu.Item>
        <Menu.Item key="archive">
          <FontAwesomeIcon icon={faArchive} />&nbsp;
           <span>Archive</span>
        </Menu.Item>
        <Menu.Item key="delete" className="contact-card-menu-delete-btn">
          <FontAwesomeIcon icon={faTrash} />&nbsp;
            <span className="gx-chart-down">Delete</span>
        </Menu.Item>
      </Menu>
    )
  }

  const menuClick = (key, contact) => {
    if (key === 'delete') showDeleteConfirm(contact)
    if (key === 'edit') {
      currentContact(contact);
      toggleUpdate();
    }
    if (key === 'archive') showArchiveConfirm(contact)
  }

  const showDeleteConfirm = async (contact) => {
    (
      await confirm({
        title: 'Are you sure delete this contact?',
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        async onOk() {
          if (contact.id) {
            await mutateDelete(contact.id)
            notification['success']({
              message: 'Deleted!',
              description: `Contact has been successfully deleted.`,
            });
          }
        },
        onCancel() {
          console.log('Cancel');
        },
      })
    )
  }

  const showArchiveConfirm = async (contact) => {
    (
      await confirm({
        title: 'Are you sure archive this contact?',
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        async onOk() {
          if (contact.id) {
            await mutateArchive(contact.id)
            notification['success']({
              message: 'Archived!',
              description: `Contact has been successfully archived.`,
            });
          }
        },
        onCancel() {
          console.log('Cancel');
        },
      })
    )
  }
  const handleChange = event => {
    changeFilter(event.target.value);
  };
  const resetFilter = () => {
    changeFilter("");
  }
  if (isLoading || isFetching) return (
    <h2>
      {isLoading && <CircularProgress />}
      <IntlMessages id="chat.contacts" /> <span>{(status === 'loading') && '...'}</span>
    </h2>);
  else if (isError) {
    return (<p>Error getting Contacts...</p>);
  }
  else if (isFetched) {
    let filteredData = data.filter(c => !c.archive || c.archive === false)
    if (filter !== "") {
      const lowercasedFilter = filter.toLowerCase();
      filteredData = data.filter(item => {
        return Object.keys(item).some(key =>
          key !== "id" && item[key] !== null && (typeof item[key] === 'string' || item[key] instanceof String) && item[key].toLowerCase().includes(lowercasedFilter)
        );
      });
    }
    return (
      <Auxiliary>
        <span style={{ display: 'flex' }}>
          <h2><IntlMessages id="chat.contacts" /></h2>
          <span style={{ marginLeft: "auto" }} justify="flex-end">
            <Search
              placeholder="Looking for anyone .. "
              onSearch={value => console.log(value)}
              onChange={handleChange}
              style={{ width: 200 }}
              className="gx-mr-2"
              value={filter}
            />
            {filter !== "" && <Button disabled={filter.length === 0} onClick={resetFilter} className="gx-mb-0 danger" shape="circle" icon="close" size='small' />}
          </span>
        </span>
        <div className="gx-ml-auto">
        </div>
        { state.addRequest && <WrappedEditContact contacts={data} updateRequest={false} onContactClose={toggleAdd} />}
        { state.updateRequest && <WrappedEditContact contact={contact} contacts={data} updateRequest={true} onContactClose={toggleUpdate} />}
        <Row className="gx-mt-3">
          <RenderCards contacts={filteredData} />
        </Row>
        <FloatButton
          styles={{ cursor: 'pointer', 'position': 'absolute', 'right': '2%', 'bottom': '2%', 'zIndex': '999', 'backgroundColor': '#000000', 'color': '#fff' }}
          className="contact-add-btn"
          tooltip="Add Contact"
          onClick={() => toggleAdd()} >
          <FontAwesomeIcon size="lg" icon={faUserPlus} />
        </FloatButton>
      </Auxiliary>
    )
  }
}

export default ContactList;
