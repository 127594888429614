import React, { useState } from "react";
import { Link, useParams } from "react-router-dom"
import SimpleMDE from "react-simplemde-editor";
import "easymde/dist/easymde.min.css"
import { Tabs, Select, Button, Row, Col } from "antd";
//Data imports
import { useQueryCache } from "react-query";
// import useTeams from 'hooks/teams/useTeams'
import useOrganizations from 'hooks/organizations/useOrganizations'
import useUpdateNote from 'hooks/notes/useUpdateNote'
//UI imports
import Auxiliary from "util/Auxiliary";

const TabPane = Tabs.TabPane;
const Option = Select.Option;
let mdInstence = ''
const ViewNote = (props) => {
  const authUser = localStorage.getItem('user_id')
  let { id } = useParams();
  let backPath = '/main/notes'
  const cache = useQueryCache();
  const { data: orgs, status: orgStatus } = useOrganizations();
  // const { status: teamsStatus, data: teams } = useTeams();
  // const teamMembers = teamsStatus === "success" && teams && teams.length ? teams.map(t => t.members) : []
  const orgMembers = orgStatus === "success" && orgs && orgs.length ? orgs[0].members : []
  const orgOwner = orgStatus === "success" && orgs && orgs.length ? orgs[0].owner : {}
  const [mutateUpdate, { status: updateStatus, error: updateError }] = useUpdateNote();
  const [note, setNote] = useState({ title: '', body: '', sharedUsersIds: [], sharedUsers: [], sharedTeams: [] })
  const notes = cache.getQueryData(['notes'])
  let foundNote = {}
  if (notes && !note.title) {
    foundNote = notes.find(note => note.id === id)
    foundNote.sharedUsersIds = foundNote.sharedUsers.map(u => u.id)
    setNote(foundNote)
  }


  const getInstance = (instance) => {
    mdInstence = instance
    instance.togglePreview()
  }

  const handleSelectAll = (value) => {
    if (value && value.length && value.includes("all")) {
      if (value.length === orgMembers.length + 1) {
        return [];
      }
      return [...orgMembers.map(t => t.id)];
    }
    return value;
  }

  const handleSharedUserChange = (sharedUsersIds) => {
    sharedUsersIds = handleSelectAll(sharedUsersIds);
    setNote({ ...note, sharedUsersIds })
  }
  const onSave = async () => {
    note.sharedUsers = note.sharedUsersIds
    const updateNote = await mutateUpdate({ ...note })
    updateNote.sharedUsersIds = updateNote.sharedUsers.map(u => u.id)
    setNote({ ...updateNote })
  }

  const onCancel = () => {
    setNote(foundNote)
  }

  return (
    <Auxiliary>
      <h3><Link to={backPath}>Notes / </Link> {note.title}</h3>
      <Tabs defaultActiveKey="notes">
        <TabPane tab={`Notes`} key="notes" className="notes-tab-notes">
          <SimpleMDE
            className="simpleMde"
            getMdeInstance={getInstance}
            name="body"
            id={111}
            value={note.body}
            options={{
              autofocus: true,
              spellChecker: true,
              toolbar: false,
              minHeight: '625px'
            }}
          />
        </TabPane>
        {/* <TabPane tab={`Attachments`} key="attachments" className="notes-tab-attachments"></TabPane> */}
        {(note && note.user && authUser === note.user.id && authUser === orgOwner.id) && <TabPane tab={`Share`} key="share" className="notes-tab-share">
          <Row className="gx-mt-2">
            <Col xl={12} lg={12} md={12} sm={12} xs={24}>
              <Select
                mode="multiple"
                onChange={handleSharedUserChange}
                value={note.sharedUsersIds}
                optionFilterProp="children"
                style={{ minWidth: '350px', maxWidth: '450px' }}
                placeholder="Select Team(s)">
                <Option value="all" key="all">Select All</Option>
                {(note && orgMembers) && orgMembers.map(item => <Option value={item.id} key={item.id}>{item.firstName} {item.lastName}</Option>)}
              </Select>
            </Col>
          </Row>
          <Row className="gx-mt-3">
            <Col xl={12} lg={12} md={12} sm={12} xs={24}>
              <Button onClick={onCancel}>
                Cancel
              </Button>
              <Button className="gx-aio-gray-btn update-dashboard-btn"
                loading={(updateStatus === 'loading')}
                disabled={(updateStatus === 'loading')}
                onClick={() =>
                  onSave()} >{(updateStatus === 'loading') ? ' Updating...' : 'Update'}</Button>
            </Col>
          </Row>
        </TabPane>}
      </Tabs>
    </Auxiliary>
  )
}

export default ViewNote