import axios from 'axios';
import config from '../util/config'

async function getTeams() {
  const { headers, baseURL } = config
  const teamsUrl = `${baseURL}/teams`
  try {
    // const { data } = await axios.get(teamsUrl, { headers })
    // console.log("🚀 ~ file: team.js ~ line 9 ~ getTeams ~ data", data)
    // return data
    return []
  } catch (error) {
    console.log("🚀 ~ file: team.js ~ line 11 ~ getTeams ~ error", error)
    return error
  }
}

async function createTeam(team) {
  console.log("🚀 ~ file: team.js ~ line 17 ~ createTeam ~ team", team)
  const { headers, baseURL } = config
  const teamsUrl = `${baseURL}/teams`
  try {
    const response = await axios.post(teamsUrl, team, { headers })
    console.log("🚀 ~ file: team.js ~ line 22 ~ createTeam ~ response", response)
    return response.data
  } catch (error) {
    console.log("🚀 ~ file: team.js ~ line 22 ~ createTeam ~ error", error)
    return error
  }
}

async function updateTeam(team) {
  console.log("🚀 ~ file: team.js ~ line 17 ~ createTeam ~ team", team)
  const { headers, baseURL } = config
  const teamsUrl = `${baseURL}/teams/${team.id}`
  try {
    const response = axios.put(teamsUrl, team, { headers })
    return response
  } catch (error) {
    console.log("🚀 ~ file: team.js ~ line 22 ~ createTeam ~ error", error)
    return error
  }
}

async function getTeamById(teamId) {
  const { headers, baseURL } = config
  const teamsUrl = `${baseURL}/teams/${teamId}`
  try {
    const { data } = await axios.get(teamsUrl, { headers })
    console.log("🚀 ~ file: team.js ~ line 34 ~ getTeamById ~ data", data)
    return data
  } catch (error) {
    console.log("🚀 ~ file: team.js ~ line 33 ~ getTeamById ~ error", error)
    return error
  }
}

const deleteTeam = async (id) => {
  const { baseURL, headers } = config
  const url = `${baseURL}/teams/${id}`;
  try {
    const { data } = await axios.delete(url, { headers })
    console.log("🚀 ~ file: team.js ~ line 59 ~ deleteTeam ~ data", data)
    return data
  } catch (error) {
    console.log("🚀 ~ file: team.js ~ line 44 ~ deleteTeam ~ error", error)
    return error
  }
}

export default { getTeams, createTeam, updateTeam, getTeamById, deleteTeam }
