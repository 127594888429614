
import { useMutation, useQueryCache } from "react-query";
import axios from 'axios'
import { baseURL, query } from '../../util/config'

const INITIAL_STATE = {
  loader: false,
  alertMessage: '',
  showMessage: false,
  initURL: '',
  authUser: localStorage.getItem('user_id'),
}

export default function useAuth() {
  const cache = useQueryCache();
  const authApi = async (payload) => {
    return await axios.post(`${baseURL}/auth/local`, payload).then(res => {
      return res.data
    })
  }
  return useMutation(payload => authApi(payload), {
    onSuccess: (data) => {
      cache.setQueryData(["auth"], (prev) => {
        const newData = { ...INITIAL_STATE, ...prev, ...data }
        localStorage.setItem('authorization', `Bearer ${newData.jwt}`)
        localStorage.setItem('user_id', newData.user.id)
        newData.authUser = newData.user.id
        return {
          authorization: `Bearer ${newData.jwt}`,
          user_id: newData.user.id
        }
      })
    }
  }, query.initialQuery)
}
