import React from "react";
import {Col, Row} from "antd";
import SocialMedia from "components/Widgets/SocialMedia";
import Auxiliary from "util/Auxiliary";


const Email = () => {
  return (
    <Auxiliary>
      <Row>
        <Col xl={6} lg={12} md={12} sm={12} xs={24}>
          <SocialMedia/>
        </Col>
        <Col xl={6} lg={12} md={12} sm={12} xs={24}>
          <SocialMedia/>
        </Col>
        <Col xl={6} lg={12} md={12} sm={12} xs={24}>
          <SocialMedia/>
        </Col>
        <Col xl={6} lg={12} md={12} sm={12} xs={24}>
          <SocialMedia/>
        </Col>
      </Row>
    </Auxiliary>
  );
};

export default Email;
