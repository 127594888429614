import { useQuery, useQueryCache } from "react-query";
import axios from '../../axiosWrapper'
import { query } from '../../util/config'

export default function useContacts(callApi = false) {
  const cache = useQueryCache();
  const contacts = cache.getQueryData(["contacts"]);
  return useQuery(
    ["contacts"],
    () => callApi ? axios.get('contacts') : contacts || axios.get('contacts'),
    query.initialQuery
  )
}
