const Errors = (errors) => {
    console.log("🚀 ~ file: index.js ~ line 2 ~ Errors ~ errors", errors)
    //const { contacts } = errors

    // return contacts.map((contact) => {
    //   return (
    //     <Col key={Math.random()} xl={6} lg={12} md={12} sm={12} xs={24}>
    //       <UserCard id={contact.email} user={contact} menu={menu} deleteUser={deleteUser} />
    //     </Col>
    //   )
    // })
    return null;
}
 
export default Errors;