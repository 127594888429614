import { useQuery, useQueryCache } from "react-query";
import axios from '../../axiosWrapper'
import { query } from '../../util/config'

export default function useGetTeam(teamId) {
  const cache = useQueryCache();
  const previousTeam = cache.getQueryData(["teams", teamId]);
  let team = undefined;
  if (previousTeam && previousTeam.id === teamId) team = previousTeam
  return useQuery(
    ["teams", teamId],
    async () => await (team || axios.get(`teams/${teamId}`)),
    query.initialQuery
  )
}