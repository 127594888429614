'use strict'

import axios from 'axios'
import { baseURL, headers } from './util/config'

const axiosInstance = () => {
  const authorization = localStorage.getItem('authorization')
  return axios.create({
    baseURL,
    headers: { ...headers, authorization }
  })
}

export default {
  get: async (postURL) => {
    try {
      const { data } = await axiosInstance().get(postURL)
      return data
    } catch (error) {
      console.log("🚀 ~ file: axios.js ~ line 19 ~ get: ~ error", error)
      throw new Error(error)
    }
  },
  post: async (postURL, payload) => {
    try {
      const { data } = await axiosInstance().post(postURL, payload)
      return data
    } catch (error) {
      console.log("🚀 ~ file: axios.js ~ line 27 ~ post: ~ error", error)
      throw new Error(error)
    }
  },
  put: async (postURL, payload) => {
    try {
      const { data } = await axiosInstance().put(postURL, payload)
      return data
    } catch ({ response }) {
      const { message } = response.data
      throw new Error(message)
    }
  },
  delete: async (postURL) => {
    try {
      const { data } = await axiosInstance().delete(postURL)
      return data
    } catch (error) {
      console.log("🚀 ~ file: axios.js ~ line 43 ~ delete: ~ error", error)
      throw new Error(error)
    }
  },
}

