import React from "react";
import moment from 'moment'
import { Link, useParams } from "react-router-dom"
import { Tabs, Select, Row, Col, Card } from "antd";
//Data imports
import { useQueryCache } from "react-query";
//UI imports
import Auxiliary from "util/Auxiliary";
import EcommerceStatus from "components/Widgets/IconWithTextCard"
import SimpleLineChart from "components/Widgets/SimpleLineChart";
import UserImages from "components/Widgets/UserImages";
import CircularProgress from "components/CircularProgress/index";
import useTeams from '../../../hooks/teams/useTeams'
import useMeetings from '../../../hooks/meetings/useMeetings'
// import data from "components/Widgets/data";
import defaultAvatar from "../../../assets/images/teamicon.png"
// import { teams, meetings } from "./data"

const TabPane = Tabs.TabPane;
const Option = Select.Option;

const getStatistics = (meeting) => {
  const { analytics, teams } = meeting
  const members = {
    present: 0,
    absent: 0
  }
  const guests = {
    present: 0,
    absent: 0
  }
  let teamsStat = []
  if (analytics && teams) {
    teams.map(team => {
      const membersPresent = analytics.filter(a => a.Team.id === team.id && !a['Invited By'].name && a.att) || [];
      const membersAbsent = analytics.filter(a => a.Team.id === team.id && !a['Invited By'].name && !a.att) || [];
      const guestsPresent = analytics.filter(a => a.Team.id === team.id && a['Invited By'].name && a.att) || [];
      const guestsAbsent = analytics.filter(a => a.Team.id === team.id && a['Invited By'].name && !a.att) || [];
      members.present = members.present + membersPresent.length
      members.absent = members.absent + membersAbsent.length
      guests.present = guests.present + guestsPresent.length
      guests.absent = guests.absent + guestsAbsent.length

      teamsStat.push({
        id: team.id,
        name: team.name,
        avatar: team.avatar || defaultAvatar,
        total: (membersPresent.length) + (guestsPresent.length),
        members: {
          present: membersPresent,
          presentCount: membersPresent.length,
          absent: membersAbsent,
          absentCount: membersAbsent.length,
          totalMembers: membersPresent.length + membersAbsent.length
        },
        guests: {
          present: guestsPresent,
          presentCount: guestsPresent.length,
          absent: guestsAbsent,
          absentCount: guestsAbsent.length,
          totalGuests: guestsPresent.length + guestsAbsent.length
        }
      })
    })
    teamsStat = teamsStat.sort((a, b) => b.total - a.total)
    return { meeting: { id: meeting.id, dateTime: meeting.startTime, topic: meeting.topic }, teamsStat, members, guests }
  } else {
    return teamsStat
  }
}

// const StatCards = (props) => {
//   const { teams } = props
//   const statistics = []
//   teams.map(t => {
//     const subTitle = `Members: ${t.members.totalMembers} | Guests: ${t.guests.totalGuests}`
//     const subTitle2 = `Present: ${t.members.presentCount} | Present: ${t.guests.presentCount}`
//     const subTitle3 = `Absent: ${t.members.absentCount} | Absent: ${t.guests.absentCount}`
//     statistics.push(<Col key={Math.random()} xl={8} lg={12} md={12} sm={12} xs={24}>
//       <EcommerceStatus avatar={t.avatar} color="white" iconColor="black" cardColor="blue" icon="user" title={t.name} subTitle={subTitle} subTitle2={subTitle2} subTitle3={subTitle3} />
//     </Col>)
//   })
//   return statistics
// }

const EventAnalytics = (props) => {
  const authUser = localStorage.getItem('user_id')
  let { meetingId } = useParams();
  const { status, isLoading, isFetching, isFetched, isError, data: teamsData } = useTeams();
  const { status: meetstatus, isLoading: meetisLoading, isFetching: meetisFetching, isFetched: meetisFetched, isError: meetError, data: meetingsData } = useMeetings();
  const cache = useQueryCache();
  const meetings = meetingsData || cache.getQueryData(["meetings"])
  const teams = teamsData || cache.getQueryData(["teams"])
  let meetingsMap = {}
  let statistics = null
  let meetingName = ''
  let meetingSpeaker = ''
  let membersData = []
  let guestsData = []
  let meetStats = []
  if (meetings && meetingId) {
    // console.log("🚀 ~ file: eventAnalytics.js ~ line 96 ~ EventAnalytics ~ meetings", meetings.length)
    meetings.map(m => {
      if (m.analytics) {
        // console.log("🚀 ~ file: eventAnalytics.js ~ line 112 ~ EventAnalytics ~ m", m.id)
        m.teams = teams
        const stats = getStatistics(m)
        const { teamsStat: stat, meeting: currentMeet, members, guests } = stats
        meetStats.push({
          id: currentMeet.id,
          name: currentMeet.topic,
          dateTime: moment(currentMeet.dateTime,).format("DD MMM YYYY"),
          members,
          guests
        })
        meetingsMap[m.id] = stat
      }
    })
    meetStats = meetStats.sort((a, b) => new Date(a.dateTime).getTime() - new Date(b.dateTime).getTime())
    // console.log("🚀 ~ file: eventAnalytics.js ~ line 99 ~ EventAnalytics ~ stats", meetStats.length)
    membersData = meetStats.map(m => {
      return {
        name: `${m.dateTime}`,
        total: m.members.present + m.members.absent,
        present: m.members.present,
        absent: m.members.absent,
      }
    })
    // console.log("🚀 ~ file: eventAnalytics.js ~ line 122 ~ EventAnalytics ~ membersData", membersData)
    guestsData = meetStats.map(m => {
      return {
        name: `${m.dateTime}`,
        total: m.guests.present + m.guests.absent,
        present: m.guests.present,
        absent: m.guests.absent,
      }
    })

    statistics = meetingsMap[meetingId]
    const currentMeet = meetings.find(m => m.id === meetingId)
    meetingName = currentMeet.topic
    if (currentMeet.speaker) meetingSpeaker = `${currentMeet.speaker.firstName} ${currentMeet.speaker.lastName}`
  }
  let backPath = '/main/calendar'


  return (
    <Auxiliary>
      {(isLoading || meetisLoading) && <CircularProgress />}
      <h3><Link to={backPath}>Calendar / </Link> {meetingName}</h3>
      <Tabs defaultActiveKey="analytics">
        <TabPane tab={`Analytics (Speaker: ${meetingSpeaker})`} key="analytics" className="analytics-tab">
          <Row>
            <Col lg={12} md={12} sm={24} xs={24}>
              <Card className="gx-card" title="Members Attendance">
                {membersData && <SimpleLineChart data={membersData} key1="present" key2="absent" key3="total" />}
              </Card>
            </Col>
            <Col lg={12} md={12} sm={24} xs={24}>
              <Card className="gx-card" title="Guests Attendance">
                {guestsData && <SimpleLineChart data={guestsData} key1="present" key2="absent" key3="total" />}
              </Card>
            </Col>
          </Row>
          <Row>
            <Col lg={24} md={24} sm={24} xs={24}>
              {statistics && <UserImages memberImageList={statistics} />}
            </Col>
          </Row>
          {/* <Row>
            {statistics && <StatCards teams={statistics} />}
          </Row> */}
        </TabPane>
      </Tabs>
    </Auxiliary>
  )
}

export default EventAnalytics
