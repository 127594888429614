import { useMutation, useQueryCache } from "react-query";
import axios from '../../axiosWrapper'
import { query } from '../../util/config'

export default function useFetchTeam() {
  const cache = useQueryCache();
  return useMutation((id) => axios.get(`teams/${id}`), {
    onSuccess: async (team) => {
      if (team && team.id) {
        const teamId = team.id
        const meetings = await axios.get(`teams/${teamId}/meetings`)
        cache.setQueryData(["teams", teamId, 'meetings'], meetings)
        cache.setQueryData(["teams", teamId], team)
      }
    }
  }, query.initialQuery)
}
