import React from "react";
import Widget from "components/Widget/index";
import { Button, Tag } from "antd";
import defaultAvatar from "../../assets/images/placeholder.png"

const BreakoutCard = ({ reg, del, onCardClick }) => {
  return (
    <Widget styleName="gx-mb-0 gx-card-full gx-dot-arrow-hover">
      <div onClick={onCardClick} className="gx-user-wid-row">
        <div className="gx-user-wid gx-mr-3">
          <img alt="..." src={defaultAvatar} className="gx-object-cover" />
        </div>
        <div className="gx-user-wid-body gx-py-1 gx-pr-3">
          <div className="ant-row-flex">
            <h2 className="h4 gx-mr-1 gx-mb-1">{`${reg.Name}`}</h2>
          </div>
          <div className="ant-row-flex">
            {reg.type && reg.type === 'reg' && <Tag className="gx-mt-0">{reg.id}</Tag>}
            {reg.type && reg.type === 'inv' && <Tag className="gx-mt-0">{reg.email}</Tag>}
          </div>
          <div className="ant-row-flex">
            {reg.type && reg.type === 'reg' && reg['Invited By'] !== "" && <Tag color="green" className="gx-mt-0">Invited by {reg['Invited By'].name}</Tag>}
            {reg.type && reg.type === 'reg' && reg['Invited By'] === "" && <Tag className="gx-mt-0">---</Tag>}
            {reg.type && reg.type === 'inv' && <Tag color="blue" className="gx-mt-0">Inviter</Tag>}
          </div>
          {/* <div className="gx-dot-arrow">
            <Button style={{ marginLeft: "auto" }} justify="flex-end" onClick={del} className="gx-aio-gray-btn" shape="circle" icon="close" size='small' />
          </div> */}
        </div>
      </div>
    </Widget>
  )
};

export default BreakoutCard;
