import { useMutation, useQueryCache } from "react-query";
import axios from '../../axiosWrapper'
import { query } from '../../util/config'

export default function useUpdateContact() {
  const cache = useQueryCache();
  return useMutation(id => axios.put(`contacts/${id}`, { archive: true }), {
    onSuccess: (updated) => {
      if (updated && updated.id)
        cache.setQueryData(["contacts"], (prev) => prev.map(contact => contact.id === updated.id ? updated : contact))
    }
  }, query.initialQuery)
}