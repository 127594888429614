//UI Imports
import React, { useState } from "react";
import { Input, Modal, Form, Row, notification } from "antd";
import IntlMessages from "../../../util/IntlMessages";
import Errors from '../Errors';

//Data Imports
import { useQueryCache } from "react-query";
import useCreateTeam from '../../../hooks/teams/useCreateTeam'
import useUpdateTeam from '../../../hooks/teams/useUpdateTeam'
import { isEqual } from 'lodash';
import config from '../../../util/config';

const FormItem = Form.Item;

const AddEditForm = (props) => {
  const [disabled, setDisable] = useState(true);
  const setDisabled = (val) => setDisable(val)
  const { updateRequest, onTeamClose, team, teams, form } = props;
  const { getFieldDecorator, validateFields } = form;
  const [mutateCreate, { status: addStatus, error: addError }] = useCreateTeam();
  const [mutateUpdate, { status: updateStatus, error: updateError }] = useUpdateTeam();

  const cache = useQueryCache();
  const store = cache.getQueryData(["teams"]);
  const user = cache.getQueryData(["profile"])
  let data = undefined;
  if (updateRequest) {
    data = store.filter(c => c.id === team.id)[0];
  }

  const validator = (rule, value, callback) => {
    if (value === undefined) {
      setDisabled(true);
      return callback();
    }
    if (rule.field === "name") {
      const nameVal = value.trim().toLowerCase();
      if (nameVal === "" || (nameVal.match(config.regex.teamNames) ? false : true)) {
        rule.message = "Please enter valid name";
        setDisabled(true);
        return callback(true);
      }
      if (((teams.find(t => t.name.toLowerCase() === nameVal.toLowerCase() && (team ? t.id !== team.id : true)) !== undefined))) {
        setDisabled(true);
        return callback(true);
      }
      setDisabled(false);
      callback();
    }

  }

  return (
    <Modal
      title={updateRequest === false ?
        <IntlMessages id="team.addTeam" /> :
        <IntlMessages id="team.updateTeam" />}
      visible={true}
      okButtonProps={{ disabled: (addStatus === 'loading' || updateStatus === 'loading') || disabled }}
      confirmLoading={(addStatus === 'loading' || updateStatus === 'loading')}
      closable={false}
      okText={updateRequest === false ?
        <IntlMessages id="team.button.addTeam" /> :
        <IntlMessages id="team.button.updateTeam" />}
      onOk={async () => {
        await validateFields(async (err, values) => {
          if (!err) {
            if (updateRequest && !isEqual(values, team)) {
              values = { ...values, id: team.id, name: values.name }
              await mutateUpdate(values)
              if (!updateError) {
                onTeamClose();
              }
            }
            else {
              await mutateCreate({ ...values, name: values.name, organizations: user.organizations });
              notification['success']({
                message: 'Success!',
                description: `Team have been successfully craeted.`,
              })
              if (!addError) {
                onTeamClose();
              }
            }
          }
        })
      }}
      onCancel={onTeamClose}>
      <div className="gx-ml-auto">
        <Row className="gx-mt-3">
          {addError && <Errors errors={data} />}
          {updateError && <Errors errors={data} />}
        </Row>
        <Form layout="inline" className="gx-form-inline-label-up">
          <FormItem className="gx-form-item-three-fourth">
            {getFieldDecorator('name', {
              rules: [{ required: true, message: 'Please input Team Name!', max: 25 },
              { validator: validator, message: "Team name already exist! Try other names" }
              ], initialValue: (data ? data.name : "")
            })(
              <Input type="text" name={"name"} placeholder="Team Name" autoComplete="off" />
            )}
          </FormItem>
        </Form>
      </div>
    </Modal>
  );
}

export const WrappedEditTeam = Form.create()(AddEditForm);