import { useQuery, useQueryCache } from "react-query";
import axios from '../../axiosWrapper'
import { query } from '../../util/config'

export default function useMeetings() {
  const cache = useQueryCache();
  const cacheMeets = cache.getQueryData(["meetings"]);
  return useQuery(
    ["meetings"],
    () => cacheMeets || axios.get('meetings'),
    query.initialQuery
  )
}
