import React from "react"
import {Col, Row} from "antd"

const Support = () => (
  <main className="gx-layout-content ant-layout-content" style={{overflow: 'auto', height: '100%'}}>
    <div className="gx-main-content-wrapper">
    <Row>
      <Col xl={24} lg={24} md={24} xs={24}>
      <h2>Support</h2>
      <br></br>
      <p>Pleasecontact out team for all technical queries at support@aoimeet.com</p>
      </Col>
    </Row>
    </div>
    
  </main>
);

export default Support;
