import { useMutation, useQueryCache } from "react-query";
import axios from '../../axiosWrapper'
import { query } from '../../util/config'

export default function useCreateTeamLink() {
  const cache = useQueryCache();
  return useMutation((id) => axios.get(`teams/${id}/inviteToken?validFor=15&role=members`), {
    onSuccess: (updated) => {
      const { inviteLink, id } = updated
      cache.setQueryData(['teams', id], (prev) => {
        prev.inviteLink = inviteLink
        return prev
      })
      return inviteLink
    }
  }, query.initialQuery)
}
