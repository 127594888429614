import { useQuery, useQueryCache } from "react-query";
import axios from '../../axiosWrapper'
import { query } from '../../util/config'

export default function useContactEventLogs(id) {
  const cache = useQueryCache();
  const contactEventLog = cache.getQueryData(["event-logs", id]);
  return useQuery(
    ["contacts", id],
    () => contactEventLog || axios.get(`contacts/${id}/event-logs`),
    query.initialQuery
  )
}