import { useQuery, useQueryCache } from "react-query";
import axios from '../../axiosWrapper'
import { query } from '../../util/config'

export default function useTeams() {
  const cache = useQueryCache();
  const teams = cache.getQueryData(["teams"]);
  return useQuery(
    ["teams"],
    () => teams || axios.get('teams'),
    query.initialQuery
  )
}
