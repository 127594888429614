import React from "react";
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";

const SimpleLineChart = ({ data, key1, key2, key3 }) => (
  <ResponsiveContainer width="100%" height={200} >
    <LineChart data={data}
      margin={{ top: 10, right: 0, left: -15, bottom: 0 }}>
      <XAxis dataKey="name" />
      <YAxis />
      <CartesianGrid strokeDasharray="3 3" />
      <Tooltip />
      <Legend />
      <Line type="monotone" dataKey={key3} stroke="#073391" animateNewValues={true} isAnimationActive={true} />
      <Line type="monotone" dataKey={key1} stroke="#1e9107" />
      <Line type="monotone" dataKey={key2} stroke="#c21c06" />
    </LineChart>
  </ResponsiveContainer >
);

export default SimpleLineChart
