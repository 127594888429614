import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Button, Form, Input, notification } from "antd";
import useResetPassword from '../../hooks/user/useResetPassword';
const FormItem = Form.Item;

export default function ResetPassword(props) {
  let { code } = props;
  const authUser = localStorage.getItem('user_id')
  code = code.replace("?code=", "");
  const [confirmed, setConfirmed] = useState(false);
  const [mutateResetPassword, { status: resetStatus, data: resetData, error: resetError, isError }] = useResetPassword();

  function renderConfirmationForm(props) {
    const { getFieldDecorator } = props.form;
    const handleSubmit = (e) => {
      e.preventDefault();
      props.form.validateFields(async (err, values) => {
        if (!err) {
          const vals = { code: code, ...values }
          await mutateResetPassword(vals);
          if (!isError) {
            console.log("🚀 ~ file: ResetPassword.js ~ line 13 ~ ResetPassword ~ resetError", resetError)
            setConfirmed(true);
            notification['success']({
              message: 'Success!',
              description: 'You password has be changed successfully!',
            });
          }
          else {
            console.log("🚀 ~ file: ResetPassword.js ~ line 13 ~ ResetPassword ~ resetError", resetError)
            notification['failed']({
              message: 'Reset failed !',
              description: 'Please try again by requesting new link!',
            });
          }
        }
      });
    };

    const compareToFirstPassword = (rule, value, callback) => {
      const form = props.form;
      if (value && value !== form.getFieldValue('password')) {
        callback('Two passwords that you enter is inconsistent!');
      } else {
        callback();
      }
    };

    const validateToNextPassword = (rule, value, callback) => {
      const form = props.form;
      if (value && props.confirmDirty) {
        form.validateFields(['passwordConfirmation'], { force: true });
      }
      callback();
    };

    return (
      <Form onSubmit={handleSubmit} className="gx-login-form gx-form-row0">
        <FormItem>
          {getFieldDecorator('password', {
            rules: [{
              required: true, message: 'Please input your password!',
            }, {
              validator: validateToNextPassword,
            }],
          })(
            <Input type="password" placeholder="New Password" />
          )}
        </FormItem>
        <FormItem>
          {getFieldDecorator('passwordConfirmation', {
            rules: [{
              required: true, message: 'Please confirm your password!',
            }, {
              validator: compareToFirstPassword,
            }],
          })(
            <Input placeholder="Retype New Password" type="password" />
          )}
        </FormItem>
        <FormItem>
          <Button className="gx-aio-gray-btn" htmlType="submit" loading={resetStatus === "loading"} >
            Reset
          </Button>
        </FormItem>
      </Form>
    );
  }

  function renderSuccessMessage() {
    return (
      <div className="success">
        <p>Your password has been reset.</p>
        <p>
          <Link to={authUser ? "/dashboard" : "/"}>
            Click here to login with your new credentials.
            </Link>
        </p>
      </div>
    );
  }

  const WrappedResetPasswordForm = Form.create()(renderConfirmationForm);
  return (
    <div className="ResetPassword">
      {!confirmed
        ? <WrappedResetPasswordForm />
        : renderSuccessMessage()}
    </div>
  );
}