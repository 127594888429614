import React from "react";
import { Link, useParams } from "react-router-dom";
import { format } from 'date-fns';
import PullToRefresh from 'react-simple-pull-to-refresh'
//Data imports

//UI imports
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button as FloatButton } from 'react-floating-action-button'
import { faPlus, faTrash,faEdit, faArchive } from '@fortawesome/free-solid-svg-icons'
import { Table, Avatar, Divider, Icon, Modal, notification } from "antd";
import Auxiliary from "util/Auxiliary";
import defaultAvatar from "../../../assets/images/placeholder.jpeg"

//Data imports
import { useQueryCache } from "react-query";
import useNotes from '../../../hooks/notes/useNotes'
import useDeleteNote from '../../../hooks/notes/useDeleteNote'
import useFetchNotes from '../../../hooks/notes/useFetchNotes'
import useArchiveNote from '../../../hooks/notes/useArchiveNote'

const Notes = () => {
  const authUser = localStorage.getItem('user_id')
  const cache = useQueryCache();
  let { teamId, section, meetingId } = useParams();
  const { status, isLoading, isFetching, isFetched, isError, data } = useNotes();
  const [mutateDelete, { status: deleteStatus, error: deleteError }] = useDeleteNote();
  const [mutateArchive, { status: archiveStatus, error: archiveError }] = useArchiveNote();
  const [mutateFetchNotes, { status: fetchStatus, error: fetchError }] = useFetchNotes();
  const confirm = Modal.confirm;
  let columns = []
  let notes = []
  if (data && data.length) {
    notes = data
    if (meetingId) notes = notes.filter(n => n.tagMeetings.find(m => m.id === meetingId))
    const availableColumns = ['user', 'title', 'tagMeetings', 'tagContact', 'published_at']
    const titleMap = {
      title: 'Title',
      body: 'Content',
      user: 'Author',
      favourite: 'Favourite',
      tagContact: 'Contact',
      tagMeetings: 'Meetings',
      attachments: 'Attachments',
      published_at: 'Published At'
    }
    availableColumns.map(title => {
      if (title === 'user') {
        columns.push({
          title: titleMap[title],
          dataIndex: title,
          render: (user) => {
            return <Avatar src={user.avatar || defaultAvatar}
              className="gx-size-40 gx-pointer gx-mr-3"
              alt={`${user.firstName} ${user.lastName}`} />
          }
        })
      } else if (title === 'attachments') {
        columns.push({
          title: titleMap[title],
          dataIndex: title,
          render: (attachments) => attachments.length
        })
      } else if (title === 'tagContact') {
        columns.push({
          title: titleMap[title],
          dataIndex: title,
          render: (contact) => contact ? `${contact.firstName} ${contact.lastName}` : ''
        })
      } else if (title === 'tagMeetings') {
        columns.push({
          title: titleMap[title],
          dataIndex: title,
          render: (meetings) => meetings ? `${meetings.map(m => m.topic)}` : ''
        })
      } else if (title === 'published_at') {
        columns.push({
          title: titleMap[title],
          dataIndex: title,
          render: (datetime) => format(new Date(datetime), 'MM/dd/yyyy p')
        })
      } else if (title === 'title') {
        columns.push({
          title: titleMap[title],
          dataIndex: title,
          render: (title, note) => <Link to={`/main/notes/${note.id}/view`}>{title}</Link>
        })
      } else {
        columns.push({
          title: titleMap[title],
          dataIndex: title
        })
      }
    })
    columns.push({
      title: 'Action',
      render: (text, note) => (
        <span>
          {(authUser === note.user.id) && <Link to={`/main/notes/update/${note.id}`} className="note-edit-btn"><FontAwesomeIcon icon={faEdit} />&nbsp;Edit</Link>}
          {(authUser === note.user.id) && <Divider type="vertical" />}
          {(authUser === note.user.id) && <span onClick={() => showArchiveConfirm(note)} className="gx-link gx-text-orange"><FontAwesomeIcon icon={faArchive} />&nbsp;Archive</span>}
          {(authUser === note.user.id) && <Divider type="vertical" />}
          {(authUser === note.user.id) && <span onClick={() => showDeleteConfirm(note)} className="gx-link note-delete-btn gx-text-red"><FontAwesomeIcon icon={faTrash} />&nbsp;Delete</span>}
        </span>
      )
    })

    const showDeleteConfirm = async (note) => {
      (
        await confirm({
          title: `Are you sure delete ${note.title} note?`,
          okText: 'Delete',
          okType: 'danger',
          cancelText: 'Cancel',
          async onOk() {
            if (note.id) {
              await mutateDelete(note.id)
              notification['success']({
                message: 'Deleted!',
                description: `Note has been successfully deleted.`,
              });
            }
          },
          onCancel() {
          },
        })
      )
    }

    const showArchiveConfirm = async (note) => {
      (
        await confirm({
          title: `Are you sure archive ${note.title} note?`,
          okText: 'Archive',
          okType: 'danger',
          cancelText: 'Cancel',
          async onOk() {
            if (note.id) {
              await mutateArchive(note.id)
              notification['success']({
                message: 'Archived!',
                description: `Note has been successfully archived.`,
              });
            }
          },
          onCancel() {
          },
        })
      )
    }
  }
  const handleRefresh = () => {
    return mutateFetchNotes()
  }
  return (
    <Auxiliary>
      <h3>Notes</h3>
      <PullToRefresh onRefresh={handleRefresh}>
        <Table
          rowKey='id'
          loading={(isLoading || deleteStatus === 'loading' || archiveStatus === 'loading')}
          className="gx-table-responsive"
          columns={columns}
          dataSource={notes}
          onChange={() => { }} />
      </PullToRefresh>
      <Link to={`/main/notes/add`} className="note-add-btn">
        <FloatButton styles={{ cursor: 'pointer', 'position': 'absolute', 'right': '2%', 'bottom': '2%', 'zIndex': '999', 'backgroundColor': '#000000', 'color': '#fff' }}
          tooltip="Add Note" >
          <FontAwesomeIcon size="lg" icon={faPlus} />
        </FloatButton>
      </Link>
    </Auxiliary>
  )
}

export default Notes;
