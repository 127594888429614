import React from "react";
import { Col, Row } from "antd";
import FlyingBird from "components/Widgets/FlyingBird";
import Auxiliary from "util/Auxiliary";
import { zoomConnect, googleMeetConnect } from 'util/config'
import CircularProgress from "components/CircularProgress/index";
import useApps from '../../../hooks/apps/useApps'

//Data imports
import { useQueryCache } from "react-query";

const Connect = () => {
  const { status, isLoading, isFetching, isFetched, isError, data } = useApps();
  const cache = useQueryCache();
  const apps = cache.getQueryData(["apps"])
  let zoomApp = ''
  let googleMeet = ''
  if (apps) zoomApp = apps.find(app => app.provider === 'zoom')
  if (apps) googleMeet = apps.find(app => app.provider === 'googlemeet')
  return (
    <Auxiliary>
      {isLoading && <div className="gx-loader-view ant-modal-mask">
        <CircularProgress className='gx-h1-lg' />
      </div>}
      {status === 'success' && <div>
        <h1>Apps Available</h1>
        <Row>
          <Col xl={8} lg={12} md={12} sm={12} xs={24}>
            <FlyingBird appName="Zoom"
              appSubtitle="ver 1.0.0"
              appLogo="zoom"
              app={zoomApp}
              href={zoomConnect} />
          </Col>
          {/* <Col xl={8} lg={12} md={12} sm={12} xs={24}>
            <FlyingBird appName="Google Meet"
              appSubtitle="ver 1.0.0"
              appLogo="googlemeet"
              app={googleMeet}
              href={googleMeetConnect} />
          </Col>
          <Col xl={8} lg={12} md={12} sm={12} xs={24}>
            <FlyingBird
              appName="Google Calendar"
              appSubtitle="ver 1.0.0"
              appLogo="gcal"
              app={googleMeet}
              href={googleMeetConnect}
            />
          </Col> */}
        </Row>
        <h1>Apps Coming soon</h1>
        <Row>
          <Col xl={8} lg={12} md={12} sm={12} xs={24}>
            <FlyingBird appName="Google Meet"
              appSubtitle="ver 1.0.0"
              appLogo="googleMeet"
              href=""
              coming={true} />
          </Col>
          {/* <Col xl={8} lg={12} md={12} sm={12} xs={24}>
            <FlyingBird
              appName="Google Calendar"
              appSubtitle="ver 1.0.0"
              appLogo="gcal"
              href=""
              coming={true}
            />
          </Col> */}
          <Col xl={8} lg={12} md={12} sm={12} xs={24}>
            <FlyingBird
              appName="Teams"
              appSubtitle="ver 1.0.0"
              appLogo="teams"
              href=""
              coming={true}
            />
          </Col>
          <Col xl={8} lg={12} md={12} sm={12} xs={24}>
            <FlyingBird
              appName="Webex"
              appSubtitle="ver 1.0.0"
              appLogo="webex"
              href=""
              coming={true}
            />
          </Col>
          {/* <Col xl={8} lg={12} md={12} sm={12} xs={24}>
            <FlyingBird
              appName="Mailchimp"
              appSubtitle="ver 1.0.0"
              appLogo="mailchimp"
              href=""
              coming={true}
            />
          </Col>
          <Col xl={8} lg={12} md={12} sm={12} xs={24}>
            <FlyingBird
              appName="Slack"
              appSubtitle="ver 1.0.0"
              appLogo="slack"
              href=""
              coming={true}
            />
          </Col> */}
          {/* <Col xl={8} lg={12} md={12} sm={12} xs={24}>
          <FlyingBird appName="Webex" appSubtitle="ver 3.0.0" appLogo="webex"/>
        </Col> */}
        </Row>
      </div>}
    </Auxiliary>
  );
};

export default Connect;
