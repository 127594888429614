import React from "react";
import { useQueryCache } from "react-query";
import { Col, Row } from "antd";
import Contact from "components/profile/Contact/index";
import Auxiliary from "util/Auxiliary";
import ProfileHeader from "components/profile/ProfileHeader/index";
import { contactList } from './contactList'

import useProfile from '../../hooks/user/useProfile'

const Profile = (props) => {
  const cache = useQueryCache();

  const userProfile = useProfile();
  const profile = userProfile.data || {};

  const subscriptions = cache.getQueryData(["subscriptions"]);
  const features = []
  subscriptions && subscriptions.forEach(item => features.push(...item.features))

  return (
    <Auxiliary>
      <ProfileHeader socialHandler={profile.socialHandler} displayName={profile.displayName || '... ...'} location={profile.city} avatar={profile.avatar} />
      <div className="gx-profile-content">
        <Row>
          <Col xl={8} lg={10} md={10} sm={24} xs={24}>
            <Contact contactList={contactList(profile)} />
          </Col>
        </Row>
      </div>
    </Auxiliary>
  );
}

export default Profile
