import React from "react";
import { Route, Switch } from "react-router-dom";
import AddNote from "./Notes/AddNote";
import ViewNote from "./Notes/ViewNote";
import Notes from "./Notes";
import Contacts from "./Contacts";
import ContactTimeline from "./Contacts/Timeline";
import RegisterSteps from "./Contacts/RegisterSteps";
import Teams from "./Teams";
import Meets from "./Meets";
import TeamMeetingReport from "./Teams/TeamMeetingReport"
import TeamDetails from "./Teams/TeamDetails"
import UpdateTeam from './Teams/UpdateTeam';
import BreakoutRooms from './Teams/BreakoutRooms/index'
import Meetings from "./Meetings";
import Dashboard from "./Dashboard";
import Settings from "./Dashboard/settings";
import Report from "./Report";
import Menu from "./Menu";
import MeetCalendar from "./MeetCalendar"
import { WrappedCreateMeeting } from "./MeetCalendar/newedit"
import EventAnalytics from "./MeetCalendar/eventAnalytics"

const Main = ({ match }) => (
  <Switch>
    <Route path={`${match.url}/menu`} component={Menu} />
    <Route path={`${match.url}/dashboard/settings`} component={Settings} />
    <Route path={`${match.url}/dashboard`} component={Dashboard} />
    <Route path={`${match.url}/teams/:teamId/updateTeam`} component={UpdateTeam} />
    <Route path={`${match.url}/teams/:teamId/BreakoutRooms/:meetingId`} component={BreakoutRooms} />
    <Route path={`${match.url}/teams/:teamId/meetingReport/:meetingId`} component={TeamMeetingReport} />
    <Route path={`${match.url}/teams/:teamId/meeting/:meetingId/:section`} component={Notes} />
    <Route path={`${match.url}/teams/:teamId/:section/:id`} component={AddNote} />
    <Route path={`${match.url}/teams/:teamId`} component={TeamDetails} />
    <Route path={`${match.url}/teams`} component={Teams} />
    <Route path={`${match.url}/meets`} component={Meets} />
    <Route path={`${match.url}/contacts/:id/register`} component={RegisterSteps} />
    <Route path={`${match.url}/contacts/:id/activity`} component={ContactTimeline} />
    <Route path={`${match.url}/contacts/:section/:id`} component={AddNote} />
    <Route path={`${match.url}/contacts`} component={Contacts} />
    <Route path={`${match.url}/notes/:id/view`} component={ViewNote} />
    <Route path={`${match.url}/notes/:section/:id`} component={AddNote} />
    <Route path={`${match.url}/notes/add`} component={AddNote} />
    <Route path={`${match.url}/notes`} component={Notes} />
    <Route path={`${match.url}/meetings`} component={Meetings} />
    <Route path={`${match.url}/reports`} component={Report} />
    <Route path={`${match.url}/calendar/:meetingId/updateMeeting`} component={WrappedCreateMeeting} />
    <Route path={`${match.url}/calendar/:meetingId/view`} component={EventAnalytics} />
    <Route path={`${match.url}/calendar/AddMeeting`} component={WrappedCreateMeeting} />
    <Route path={`${match.url}/calendar`} component={MeetCalendar} />
  </Switch>
);

export default Main;
