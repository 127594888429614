//UI Imports
import React from "react";
import { Modal, Form, Select } from "antd";

const FormItem = Form.Item;
const Option = Select.Option;

const SetBreakoutRoom = (props) => {
  const { onModalClose, onRoomChange, form, breakoutRooms, userName } = props;

  if(breakoutRooms == undefined || breakoutRooms.length == 0 ){
    return null;
  }

  const { getFieldDecorator, validateFields } = form;

  const validator = (rule, value, callback) => {
    if (rule === "roomNumber" && value === undefined || value === "") {
      return callback(true);
    }
    callback();
  }

  return (
    <Modal
      title={`Update breakout room for ${userName}`}
      visible={true}
      closable={false}
      okText="Update"
      onOk={() => {
        validateFields(async (err, values) => {
          if (!err) {
            const { roomNumber } = values;
            onRoomChange(roomNumber)
          }
        })
      }}
      onCancel={() => onModalClose()}>
      <div className="gx-ml-auto">
        <Form layout="inline" className="gx-form-inline-label-up">
          <FormItem className="gx-form-item-two-fourth">
            {getFieldDecorator('roomNumber', {
              rules: [{  required: true, message: 'Please select Room!' },
              { validator: validator }
              ]
            })(
              <Select
                showSearch
                placeholder="Select room"
                optionFilterProp="children"
                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                {breakoutRooms.map(room => <Option key={room} value={room}>{room}</Option>)}
              </Select>
            )}
          </FormItem>
        </Form>
      </div>
    </Modal>
  );
}

export default Form.create()(SetBreakoutRoom);
