import React from "react";
import { Link, useParams } from "react-router-dom"
//Data imports

//UI imports
import CircularProgress from "components/CircularProgress";
import IntlMessages from "util/IntlMessages";
import { Col, Row, Menu, Modal, notification, Input, Button } from "antd";
import Auxiliary from "util/Auxiliary";
import SwitchStep from "components/Steps/SwitchStep"

const RegisterSteps = () => {
  const { id } = useParams();
  let contact = {}
  return (
    <Auxiliary>
      <Link to={`/main/contacts`}>Back to Contacts</Link>
      <SwitchStep title='Register' contact={contact} />
    </Auxiliary>
  )
}

export default RegisterSteps;