import React from "react";
import ResetPasswordWidget from 'components/Widgets/ResetPassword';
import IntlMessages from "util/IntlMessages";

export default function ResetPassword(props) {
  return (
    <div className="gx-app-login-wrap">
      <div className="gx-app-login-container">
        <div className="gx-app-login-main-content">
          <div className="gx-app-logo-content gx-aio-gray-back">
            <div className="gx-app-logo gx-mt-4">
              <img alt="aiomeet" src={require("assets/images/instazoom-logo-tr-white.png")} />
            </div>

          </div>
          <div className="gx-app-login-content">
            <div className="gx-mb-4">
              <h2>Reset Your Password</h2>
            </div>
            <ResetPasswordWidget code={props.location.search} />
          </div>
        </div>
      </div>
    </div>
  );
}