import { useQuery, useQueryCache } from "react-query";
import axios from '../../axiosWrapper'
import { query } from '../../util/config'

export default function useDashboard() {
  const cache = useQueryCache();
  const dashboard = cache.getQueryData(["dashboards"]);
  return useQuery(
    ["dashboards"],
    () => dashboard || axios.get('dashboards'),
    { ...query.initialQuery, refetchOnWindowFocus: true }
  )
}
