import {all} from "redux-saga/effects";
import authSagas from "./Auth";
import notesSagas from "./Notes";
import userSagas from "./User";
import contactSagas from "./Contacts";
import teamsSagas from "./Teams";
import meetsSagas from "./Meets";
export default function* rootSaga(getState) {
  yield all([
    authSagas(),
    notesSagas(),
    userSagas(),
    contactSagas(),
    teamsSagas(),
    meetsSagas()
  ]);
}
