import React from "react";
import { Link } from "react-router-dom";
import Auxiliary from "util/Auxiliary";
import { Row, Col } from "antd";
import useSubscriptions from 'hooks/user/useSubscriptions'

import CircularProgress from "components/CircularProgress";
import IconCard from "components/Widgets/IconCard"

const Dashboard = () => {
  let isLoading = false;
  const { data } = useSubscriptions();
  const features = []
  data && data.forEach(item => features.push(...item.features))
  return (
    <Auxiliary className="menu-page">
      {isLoading && <CircularProgress />}
      <Row className="gx-px-3">
        <Col xl={3} lg={3} md={3} sm={8} xs={8} >
          <Link to='/user/myprofile'><IconCard icon="user" title='Profile' /></Link>
        </Col>
        {(features.indexOf('dashboard') !== -1) && <Col xl={3} lg={3} md={3} sm={8} xs={8}>
          <Link to='/main/dashboard'><IconCard icon="dashboard" title='Dashboard' /></Link>
        </Col>}
        {(features.indexOf('calendar') !== -1) && <Col xl={3} lg={3} md={3} sm={8} xs={8}>
          <Link to='/main/calendar'><IconCard icon="calendar-new" title='Calendar' /></Link>
        </Col>}
        {(features.indexOf('contacts') !== -1) && <Col xl={3} lg={3} md={3} sm={8} xs={8}>
          <Link to='/main/contacts'><IconCard icon="contacts" title='Contacts' /></Link>
        </Col>}
        {(features.indexOf('notes') !== -1) && <Col xl={3} lg={3} md={3} sm={8} xs={8}>
          <Link to='/main/notes'>
            <IconCard icon="ckeditor" title='Notes' />
          </Link>
        </Col>}
        {(features.indexOf('teams') !== -1) && <Col xl={3} lg={3} md={3} sm={8} xs={8}>
          <Link to='/main/teams'><IconCard icon="team" title='Teams' /></Link>
        </Col>}
      </Row>
    </Auxiliary>
  )
}

export default Dashboard