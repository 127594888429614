import { useMutation, useQueryCache } from "react-query";
import axios from '../../axiosWrapper'
import { query } from '../../util/config'

export default function useDeleteTeam() {
  const cache = useQueryCache();
  return useMutation(teamId => axios.delete(`teams/${teamId}`), {
    onSuccess: (removed) => {
      cache.setQueryData(["teams"], (prev) => {
        return prev.filter(team => team.id !== removed.id);
      })
    }
  }, query.initialQuery)
}