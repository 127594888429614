import { useMutation, useQueryCache } from "react-query";
import axios from '../../axiosWrapper'
import { query } from '../../util/config'

export default function useFetchRooms() {
  const cache = useQueryCache();
  return useMutation((id) => axios.get(`breakoutRooms/${id}`), {
    onSuccess: async (rooms) => {
      cache.setQueryData(["rooms"], rooms)
    }
  }, query.initialQuery)
}
