import React, { useEffect } from "react";
import { useQueryCache } from "react-query";
import Auxiliary from "util/Auxiliary";
import { Button, Checkbox, Form, Input, message, notification } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, Link } from "react-router-dom";
import { NotificationContainer } from "react-notifications";

import {
  hideMessage,
  userSignIn,
} from "appRedux/actions/Auth";

import IntlMessages from "util/IntlMessages";
import CircularProgress from "components/CircularProgress/index";

//Data imports
import useAuth from '../hooks/user/useAuth'

const FormItem = Form.Item;

const SignIn = (props) => {

  const dispatch = useDispatch();
  const { loader, alertMessage, showMessage, authUser } = useSelector(({ auth }) => auth);
  const history = useHistory();

  const cache = useQueryCache()

  const [mutateAuth, { status, data, error }] = useAuth();

  useEffect(() => {
    if (showMessage) {
      setTimeout(() => {
        dispatch(hideMessage());
      }, 100);
    }
    if (authUser !== null) {
      history.push('/');
    }
  });
  useEffect(() => {
    if (error) {
      notification['error']({
        message: 'Login Failed!',
        description: "Username or Password is invalid!",
      }, 3000)
    }
  }, [error])

  const handleSubmit = (e) => {
    e.preventDefault();
    props.form.validateFields(async (err, values) => {
      if (!err) {
        const { email: identifier, password } = values
        await mutateAuth({ identifier, password });
        cache.clear()
        const auth = cache.getQueryData(['auth'])
        dispatch(userSignIn(auth));
      }
    });
  };

  const { getFieldDecorator } = props.form;

  return (
    <Auxiliary>
      <div className="gx-app-login-wrap">
        <div className="gx-app-login-container ">
          <div className="gx-app-login-main-content">
            <div className="gx-app-logo-content gx-aio-gray-back">
              <div className="gx-app-logo">
                <img alt="aiomeet" src={require("assets/images/instazoom-logo-tr-white.png")} />
              </div>
              <div className="gx-app-logo-wid gx-text-white">
                <h1 className="gx-text-white"><IntlMessages id="app.userAuth.signIn" /></h1>
                <p><IntlMessages id="app.userAuth.bySigning" /></p>
                {/* <p><IntlMessages id="app.userAuth.getAccount" /></p> */}
              </div>

            </div>
            <div className="gx-app-login-content">
              <Form onSubmit={handleSubmit} className="gx-signin-form gx-form-row0">
                <FormItem>
                  {getFieldDecorator('email', {
                    initialValue: "",
                    rules: [{
                      required: true, type: 'email', message: 'The input is not valid E-mail!',
                    }],
                  })(
                    <Input type="email" placeholder="Email" />
                  )}
                </FormItem>
                <FormItem>
                  {getFieldDecorator('password', {
                    initialValue: "",
                    rules: [{ required: true, message: 'Please input your Password!' }],
                  })(
                    <Input type="password" placeholder="Password" />
                  )}
                </FormItem>
                <FormItem>
                  {getFieldDecorator('remember', {
                    valuePropName: 'checked',
                    initialValue: true,
                  })(
                    <Checkbox><IntlMessages id="appModule.iAccept" /></Checkbox>
                  )}
                  <a href="./privacy" className="gx-signup-form-forgot gx-link gx-aio-text-gray"><IntlMessages
                    id="appModule.termAndCondition" /></a>
                </FormItem>
                <FormItem>
                  <Button disabled={status === 'loading'}
                    loading={(status === 'loading')}
                    className="gx-mb-0 gx-aio-gray-btn" htmlType="submit">
                    <IntlMessages id="app.userAuth.signIn" />
                  </Button>
                  {/* <span><IntlMessages id="app.userAuth.or"/></span> <Link to="/signup"><IntlMessages
                  id="app.userAuth.signUp"/></Link> */}
                  <span><IntlMessages id="app.userAuth.or" /></span> <Link to="/forgotPassword" className="gx-aio-text-gray"><IntlMessages
                    id="app.userAuth.forgotPassword" /></Link>
                </FormItem>

              </Form>
            </div>

            {loader ?
              <div className="gx-loader-view ant-modal-mask">
                <CircularProgress />
              </div> : null}
            {showMessage ?
              message.error(alertMessage.toString()) : null}
          </div>
        </div>
        <NotificationContainer />
      </div>
    </Auxiliary>
  );
};

const WrappedNormalLoginForm = Form.create()(SignIn);

export default WrappedNormalLoginForm;
