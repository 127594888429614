import { useMutation, useQueryCache } from "react-query";
import axios from '../../axiosWrapper'
import { query } from '../../util/config'

export default function useCreateTeam() {
  const cache = useQueryCache();
  return useMutation(team => axios.post('teams', team), {
    onSuccess: (data) => {
      cache.setQueryData(["teams"], (prev) => {
        return ([
          ...prev, {...data,role:"owner"}
        ])
      })
    }
  }, query.initialQuery)
}