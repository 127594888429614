const crypto = require('crypto')

const cipher = (string) => {
    const mykey = crypto.createCipher('aes-256-cbc', 'mypassword');
    let myCipherString = mykey.update(string, 'utf8', 'base64')
    myCipherString += mykey.final('base64');
    return myCipherString
//  
}

const decipher = (string) => {
    const mykey = crypto.createDecipher('aes-256-cbc', 'mypassword');
    let myDecipherString = mykey.update(string, 'utf8', 'base64')
    myDecipherString += mykey.final('base64');
    return myDecipherString
}

const create = (string) => crypto.createHash('md5').update(string).digest("hex")
// const read = (string) => crypto.createDecipher('md5').update(string).final("hex")

module.exports = {cipher, decipher, create}
