import React from "react";
import {Route, Switch} from "react-router-dom";
import UpdateProfile from "./UpdateProfile"
import Profile from "./Profile"

const User = ({match}) => (
  <Switch>
    <Route path={`${match.url}/myprofile`} component={Profile}/>
    <Route path={`${match.url}/updateProfile`} component={UpdateProfile}/>
  </Switch>
);

export default User;
