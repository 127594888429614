//UI Imports
import React, { useState } from "react";
import { Input, Modal, Form, Row, notification, Select } from "antd";
import IntlMessages from "../../../util/IntlMessages";
import Errors from '../Errors';

//Data Imports
import { useQueryCache } from "react-query";
import useBreakoutRequest from '../../../hooks/teams/useBreakoutRequest'
import { isEqual } from 'lodash';
import config from '../../../util/config';

const FormItem = Form.Item;
const Option = Select.Option;

const RequestForm = (props) => {
  const [disabled, setDisable] = useState(false);
  const setDisabled = (val) => setDisable(val)
  const { toggleRequest, team, teams, meeting, form } = props;
  const { getFieldDecorator, validateFields } = form;
  const [mutateRequest, { status: addStatus, error: addError }] = useBreakoutRequest(meeting.id || 1);//temp data - 1

  const cache = useQueryCache();
  const data = "temp"
  const validator = (rule, value, callback) => {
    if (value === undefined) {
      setDisabled(true);
      return callback(false);
    }
  }

  return (
    <Modal
      title="Breakout Request"
      visible={true}
      confirmLoading={(addStatus === 'loading')}
      closable={false}
      okText="Send Request"
      onOk={async () => {
        const values = form.getFieldsValue()
        if (values) {
          await mutateRequest(values);
          notification['success']({
            message: 'Success!',
            description: `Team have been successfully craeted.`,
          })
          if (!addError) {
            toggleRequest(false);
          }
        }
        else {
          console.log("Error")
        }
      }}
      onCancel={() => toggleRequest(false)}>
      <div className="gx-ml-auto">
        <Row className="gx-mt-3">
          {addError && <Errors errors={data} />}
        </Row>
        <Form
          labelCol={window.innerWidth > 500 ? { span: 10 } : { span: 24 }}
          wrapperCol={window.innerWidth > 500 ? { span: 14 } : { span: 24 }}
          layout={window.innerWidth > 500 ? "horizontal" : "vertical"}
          className="gx-px-0">
          <FormItem label="Select Breakout Room" className="gx-form-item-two-fourth">
            {getFieldDecorator('roomNumber', {
              rules: [{ message: 'Please select Room!', },
              { validator: validator }
              ], initialValue: meeting && meeting.breakoutRooms && meeting.breakoutRooms.length > 0 ? meeting.breakoutRooms[0] : "Room 1"
            })(
              <Select
                showSearch
                placeholder="Select room"
                optionFilterProp="children"
                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                <Option key='select' value={null}>No Breakout Room</Option>
                {meeting && meeting.breakoutRooms && meeting.breakoutRooms.length > 0 ?
                  meeting.breakoutRooms.map(room => <Option key={room} value={room}>{room}</Option>) :
                  ["Room 1", "Room 2", "Room 3", "Room 4"].map(room => <Option key={room} value={room}>{room}</Option>)}
              </Select>
            )}
          </FormItem>
          <FormItem label="Add Note" className="gx-form-item-three-fourth">
            {getFieldDecorator('note', {
              rules: [{ required: false, message: 'Please input Team Name!', max: 300 },
              { validator: validator, message: "Team name already exist! Try other names" }
              ], initialValue: (data ? data.name : "")
            })(
              <textarea rows="5" cols="30" placeholder="Write a note[Optional]"></textarea>
            )}
          </FormItem>
        </Form>

      </div>
    </Modal>
  );
}

export const BreakoutRequest = Form.create()(RequestForm);