import React from "react";
import Aux from "util/Auxiliary.js"
import { Badge } from 'antd'

const getBadge = (type, text) => {
  return <span><Badge className="gx-ml-1" key={Math.random(3)} status={type || 'success'} dot></Badge>{text}</span>
}

const UserImages = ({ memberImageList }) => {
  return (
    <Aux>
      <ul className="gx-agents-list">
        {memberImageList.map((team, index) =>
          <li key={index}>
            <div className="gx-profileon gx-pointer">
              <div className="gx-profileon-thumb"><img alt="..." src={team.avatar} className="gx-rounded-lg" /></div>
              <div className="gx-profileon-content">
                <h4 className="gx-mb-1 gx-text-truncate">{team.name}</h4>
                <h5 className="gx-mb-0 gx-fs-sm gx-text-truncate">
                  Members: {team.members.totalMembers} | {getBadge('success', team.members.presentCount)} | {getBadge('error', team.members.absentCount)}
                </h5>
                <h5 className="gx-mb-0 gx-fs-sm gx-text-truncate">
                  Guests: {team.guests.totalGuests} | {getBadge('success', team.guests.presentCount)} | {getBadge('error', team.guests.absentCount)}
                </h5>
              </div>
            </div>
          </li>
        )
        }
      </ul>
    </Aux>
  );
};

export default UserImages;
