import {
  DELETE_CONTACT_SUCCESS,
  FETCH_CONTACTS_SUCCESS,
  ON_ADD_CONTACT_SUCCESS,
  ON_UPDATE_CONTACT_SUCCESS,
  ON_DELETE_CONTACT_SUCCESS
} from "../../constants/ActionTypes";
const INIT_STATE = {
  contactList: [],
  selectedContact: [],
  fetched: false
};
export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_CONTACTS_SUCCESS: {
      return {
        ...state,
        contactList: action.payload,
        fetched: true,
      }
    }
    case ON_UPDATE_CONTACT_SUCCESS:
      return {
        ...state,
        contactList: state.contactList.map((contact) => contact.id === action.payload.id ? action.payload : contact),
      };
    case DELETE_CONTACT_SUCCESS:
      return {
        ...state,
        contactList: state.contactList.filter((contact) => contact.id !== action.payload.id),
      };
    case ON_DELETE_CONTACT_SUCCESS:
      const filteredList = state.contactList.filter(contact => contact.id !== action.payload.id);
      return {
        ...state,
        contactList: filteredList,
      };
    case ON_ADD_CONTACT_SUCCESS:
      const newData = state.contactList
      console.log("🚀 ~ file: Contact.js ~ line 40 ~ state.contactList", state.contactList)
      newData[newData.length] = action.payload
      console.log("🚀 ~ file: Contact.js ~ line 42 ~ action.payload", action.payload)
      return {
        ...state,
        contactList: newData,
      };
    default:
      return state;
  }
}