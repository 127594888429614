import React, { memo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import URLSearchParams from 'url-search-params'
import { Redirect, Route, Switch, useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { ConfigProvider } from "antd";
import { IntlProvider } from "react-intl";

import AppLocale from "lngProvider";
import MainApp from "./MainApp";
import SignIn from "../SignIn";
import ForgotPassword from "../ForgotPassword";
import ResetPassword from "../ResetPassword";
// import SignUp from "../SignUp";
import Privacy from "../Privacy";
import Terms from "../Terms";
import Support from "../Support";
import TeamInvite from "../TeamInvite";
import Join from "../Join";
import { setInitUrl } from "appRedux/actions/Auth";
import { onLayoutTypeChange, onNavStyleChange, setThemeType } from "appRedux/actions/Setting";

import {
  LAYOUT_TYPE_BOXED,
  LAYOUT_TYPE_FRAMED,
  LAYOUT_TYPE_FULL,
  NAV_STYLE_ABOVE_HEADER,
  NAV_STYLE_BELOW_HEADER,
  NAV_STYLE_DARK_HORIZONTAL,
  NAV_STYLE_DEFAULT_HORIZONTAL,
  NAV_STYLE_INSIDE_HEADER_HORIZONTAL
} from "../../constants/ThemeSetting";

const RestrictedRoute = ({ component: Component, location, authUser, ...rest }) => {

  return (
    <Route
      {...rest}
      render={props =>
        authUser || (location && location.path === "/password-rest/" && location.search !== "")
          ? <Component {...props} />
          : <Redirect
            to={{
              pathname: '/signin',
              state: { from: location }
            }}
          />}
    />
  );
}


const App = (props) => {

  const dispatch = useDispatch();
  const { locale, navStyle, layoutType } = useSelector(({ settings }) => settings);
  const { authUser, initURL } = useSelector(({ auth }) => auth);

  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch();


  useEffect(() => {
    let link = document.createElement('link');
    link.type = 'text/css';
    link.rel = 'stylesheet';
    link.href = "/css/style.css";

    link.className = 'gx-style';
    document.body.appendChild(link);
  }, []);

  useEffect(() => {
    if (initURL === '') {
      dispatch(setInitUrl(location.pathname));
    }
    const params = new URLSearchParams(location.search);

    if (params.has("theme")) {
      dispatch(setThemeType(params.get('theme')));
    }
    if (params.has("nav-style")) {
      dispatch(onNavStyleChange(params.get('nav-style')));
    }
    if (params.has("layout-type")) {
      dispatch(onLayoutTypeChange(params.get('layout-type')));
    }
    setLayoutType(layoutType);
    setNavStyle(navStyle);
  });


  const setLayoutType = (layoutType) => {
    if (layoutType === LAYOUT_TYPE_FULL) {
      document.body.classList.remove('boxed-layout');
      document.body.classList.remove('framed-layout');
      document.body.classList.add('full-layout');
    } else if (layoutType === LAYOUT_TYPE_BOXED) {
      document.body.classList.remove('full-layout');
      document.body.classList.remove('framed-layout');
      document.body.classList.add('boxed-layout');
    } else if (layoutType === LAYOUT_TYPE_FRAMED) {
      document.body.classList.remove('boxed-layout');
      document.body.classList.remove('full-layout');
      document.body.classList.add('framed-layout');
    }
  };

  const setNavStyle = (navStyle) => {
    if (navStyle === NAV_STYLE_DEFAULT_HORIZONTAL ||
      navStyle === NAV_STYLE_DARK_HORIZONTAL ||
      navStyle === NAV_STYLE_INSIDE_HEADER_HORIZONTAL ||
      navStyle === NAV_STYLE_ABOVE_HEADER ||
      navStyle === NAV_STYLE_BELOW_HEADER) {
      document.body.classList.add('full-scroll');
      document.body.classList.add('horizontal-layout');
    } else {
      document.body.classList.remove('full-scroll');
      document.body.classList.remove('horizontal-layout');
    }
  };

  useEffect(() => {
    if (location.pathname === '/') {
      if (authUser === null) {
        history.push('/signin');
      } else if (initURL === '' || initURL === '/' || initURL === '/signin' || initURL === '/teamInvite/' || initURL === '/teamInvite/' || initURL === '/index.html' || initURL === '/password-reset') {
        history.push('/main/menu');
      } else {
        history.push(initURL);
      }
    }
    if (location.pathname === '/index.html' && authUser) {
      history.push('/main/menu');
    }

  }, [authUser, initURL, location, history]);

  const currentAppLocale = AppLocale[locale.locale];

  return (
    <ConfigProvider locale={currentAppLocale.antd}>
      <IntlProvider
        locale={currentAppLocale.locale}
        messages={currentAppLocale.messages}>
        <Switch>
          <Route exact path='/password-reset' component={ResetPassword} />
          <Route exact path='/ForgotPassword' component={ForgotPassword} />
          <Route exact path='/signin' component={SignIn} />
          {/* <Route exact path='/signup' component={SignUp}/> */}
          <Route exact path='/privacy' component={Privacy} />
          <Route exact path='/terms' component={Terms} />
          <Route exact path='/support' component={Support} />
          <Route exact path='/teamInvite' component={TeamInvite} />
          <Route exact path='/join' component={Join} />
          <RestrictedRoute path={`${match.url}`} authUser={authUser} location={location}
            component={MainApp} />
        </Switch>
      </IntlProvider>
    </ConfigProvider>
  )
};

export default memo(App);
