module.exports = {
  footerText: `Copyright aiomeet.com © ${new Date().getFullYear()}`,
  zoomConnect: 'https://zoom.us/oauth/authorize?response_type=code&client_id=i3YDK75TleleFdxXD1bg&redirect_uri=https%3A%2F%2Faiomeet.ngrok.io%2Fsettings%2Fconnect%2Fzoom',
  // zoomConnect: 'https://zoom.us/oauth/authorize?response_type=code&client_id=i3YDK75TleleFdxXD1bg&redirect_uri=https%3A%2F%2Faiomeet.ngrok.io%2Fsettings%2Fconnect%2Fzoom',
  googleMeetConnect: `https://accounts.google.com/o/oauth2/v2/auth?scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fcalendar.readonly%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fcalendar.events&include_granted_scopes=true&access_type=offline&approval_prompt=force&response_type=code&redirect_uri=https://aiomeet.ngrok.io/settings/connect/google&client_id=931678611394-60us5b90o3l28rn4md6uj0tpk6ltthm9.apps.googleusercontent.com`,
  baseURL: 'https://api.aiomeet.com',
  headers: {
    'client-secret': 'oWJNMu6wgJi868F6wwUW4zw}hCFdDr3A'
  },
  query: {
    initialQuery: {
      retry: 1,
      retryDelay: 3000,
      initialStale: true,
      refetchOnWindowFocus: false
    }
  },
  rawUserData: {
    defaultSetting: {
      meetingId: '',
      userId: ''
    },
    profile: {
      avatar: '',
      city: '',
      displayName: '',
      firstName: '',
      lastName: '',
      zoomEmail: '',
      mobile: '',
      url: '',
      social: [
        { type: 'linkedin', url: '' },
        { type: 'twitter', url: '' },
        { type: 'facebook', url: '' },
        { type: 'instagram', url: '' },
      ]
    }
  },
  regex: {
    userNames: /^[a-zA-Z\s]+$/,
    teamNames: /^[a-zA-Z0-9&-_\s]+$/,
  }
}
