import React from "react";
import moment from 'moment'
import { useParams, Link } from "react-router-dom";
import { Row, Col, Table, Badge, Tabs, Slider } from "antd";
import { useQueryCache } from "react-query";
import BreakoutRooms from "./BreakoutRooms/index"
import useGetTeamMeetings from '../../../hooks/teams/useGetTeamMeetings'
import useGetTeam from '../../../hooks/teams/useGetTeam'
import Error401 from "../../errorPages/401"
import EventAnalytics from './eventAnalytics';

const TeamMeetingReport = (props) => {
  const TabPane = Tabs.TabPane;
  const { teamId, meetingId } = useParams();
  const { data } = useGetTeamMeetings(teamId);
  const cache = useQueryCache();
  const meetings = data || cache.getQueryData(["teams", teamId, 'meetings']) || [];
  const { isLoading, isError: isTeamError } = useGetTeam(teamId);
  const marks = {
    0: '',
    25: {
      style: {
        color: '#f50',
      },
      label: <span>8:00 PM</span>,
    },
    75: {
      style: {
        color: '#f50',
      },
      label: <span>9:00 PM</span>,
    },
    100: ''
  };
  function compare(a, b) {
    if (a.status > b.status) {
      return -1;
    }
    if (a.status < b.status) {
      return 1;
    }
    return 0;
  }
  const expandedRowRender = record => {
    const { att, startMarker, endMarker } = record
    if (!att) return undefined
    const RecordTime = []
    att && att.map(a => {
      const start = Math.round((a.join - startMarker) / 72000)
      const leave = Math.round(100 - ((endMarker - a.leave) / 72000))
      if ((leave - start) > 1) {
        RecordTime.push([start, leave, new Date(a.join), new Date(a.leave)])
      }
    })
    return (
      <Row key={Math.random(3)}>
        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
          {RecordTime.map(r => {
            let _recordTime = []
            return (
              <Slider
                key={Math.random()}
                tipFormatter={(value, obj) => {
                  if (_recordTime.indexOf(record.Name) == -1) {
                    _recordTime.push(record.Name)
                    return `${moment(r[2]).format('LT')}`
                  } else {
                    _recordTime = []
                    return `${moment(r[3]).format('LT')}`
                  }
                }
                }
                range defaultValue={[r[0], r[1]]}
                marks={marks}
                disabled={true} />
            )
          })}
        </Col>
      </Row>)
  };
  let registrants = []
  let guests = []
  let guestsPresent = 0
  let members = []
  let membersPresent = 0
  let columns = []
  let memColumns = []
  let meeting = []
  if (meetings && meetings.length) {
    meeting = meetings.find(m => m.id === meetingId)
    if (meeting && meeting.analytics && meeting.registrants) {
      registrants = [...registrants, ...meeting.analytics]
    } else {
      registrants = [...registrants, ...meeting.registrants]
    }
    const { startMarker, endMarker, duration } = meeting
    members = registrants.filter(r => !r['Breakout Room'] && !r['Invited By'].name).map(m => { return { ...m, startMarker, endMarker } })
    membersPresent = members.filter(m => m.att).length
    guests = registrants.filter(r => r['Invited By'].name).map(g => { return { ...g, startMarker, endMarker, duration } })
    guestsPresent = guests.filter(g => g.att).length
    if (meeting.registrants.length && !columns.length) {
      const registrant = meeting.registrants[0];
      Object.keys(registrant).map(title => {
        if (title === 'att' || title === 'Team' || title === 'id') {

        } else if (title === 'Invited By') {
          columns.push({
            title,
            dataIndex: title,
            render: (r, d) => {
              const name = d['Invited By'] ? d['Invited By'].name : ''
              return <span>{name}</span>
            }
          })
        } else if (title === 'Name') {
          columns.push({
            title,
            dataIndex: title,
            render: (r, d) => {
              return <span><Badge className="gx-mr-2" key={Math.random(3)} status={d.att ? 'success' : 'error'} dot><i className="icon icon-user-o" style={{ color: 'grey' }} /></Badge> {r}</span>
            }
          })
        } else {
          columns.push({
            title,
            dataIndex: title
          })
        }
      })
    }
    if (meeting.registrants.length && !memColumns.length) {
      const registrant = meeting.registrants[0];
      Object.keys(registrant).map(title => {
        if (title === 'att' || title === 'Team' || title === 'Invited By' || title === 'Breakout Room' || title === 'id') {

        } else if (title === 'Name') {
          memColumns.push({
            title,
            dataIndex: title,
            render: (r, d) => {
              return <span><Badge className="gx-mr-2" key={Math.random(3)} status={d.att ? 'success' : 'error'} dot><i className="icon icon-user-o" style={{ color: 'grey' }} /></Badge> {r}</span>
            }
          })
        } else {
          memColumns.push({
            title,
            dataIndex: title
          })
        }
      })
    }
  }
  return (
    <div>
      {isTeamError && <Error401 />}
      {(meetings && registrants && !isTeamError && !isLoading) && <div>
        <Link to={`/main/teams/${teamId}`}>
          <i className="icon icon-arrow-left" /> Team
        </Link>
        <EventAnalytics />
        <Tabs defaultActiveKey="guests">
          {guests.length && <TabPane tab={`Guests (${guestsPresent}/${guests.length})`} key="guests">
            <Table rowKey='id'
              className="gx-table-responsive"
              columns={columns}
              dataSource={guests}
              expandedRowRender={expandedRowRender}
              onChange={() => { }} />
          </TabPane>}
          {members.length && <TabPane tab={`Members (${membersPresent}/${members.length})`} key="members">
            <Table rowKey='id'
              className="gx-table-responsive"
              columns={memColumns}
              dataSource={members}
              expandedRowRender={expandedRowRender}
              onChange={() => { }} />
          </TabPane>}
          {/* <TabPane tab={`Breakout Rooms`} key="breakoutRooms">
            <BreakoutRooms meeting={meeting} />
          </TabPane> */}
        </Tabs>
      </div>}
    </div>
  );
}

export default TeamMeetingReport
