import { useQuery, useQueryCache } from "react-query";
import axios from '../../axiosWrapper'
import { query } from '../../util/config'

export default function useProfile() {
  const cache = useQueryCache();
  const profile = cache.getQueryData(["profile"]);
  const getProfile = async () => {
    const profile = await axios.get('users/me')
    if (profile && profile.avatar) profile.avatar = `${profile.avatar.url}` || undefined
    if (profile && !profile.displayName) profile.displayName = `${profile.firstName} ${profile.lastName}`
    return profile
  }
  return useQuery(
    ["profile"],
    () => profile || getProfile(),
    query.initialQuery
  )
}


