const createZoomPayload = (meetData) => {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const { organizationId, teams, registrants, recurrence, type = 2, namePrefix = '', topic, agenda = '', start_date, duration = 30, password, host_video, participant_video, mute_upon_entry, canRegister, waiting_room = true, host, speaker } = meetData;
  return {
    organizationId,
    teams,
    canRegister,
    provider: 'zoom',
    host,
    speaker,
    type,
    namePrefix,
    registrants,
    zoom: {
      "topic": topic,
      "type": type,
      "start_time": start_date,
      "duration": duration,
      "timezone": timezone,
      "password": password,
      "agenda": agenda,
      "settings": {
        "close_registration": true,
        "in_meeting": false,
        "auto_recording": "none",
        "watermark": false,
        "host_video": host_video,
        "allow_multiple_devices": true,
        "registrants_email_notification": true,
        "cn_meeting": false,
        "request_permission_to_unmute_participants": false,
        "registrants_confirmation_email": true,
        "contact_name": "no-reply@aiomeet.com",
        "enforce_login": false,
        "waiting_room": waiting_room,
        "participant_video": participant_video,
        "join_before_host": true,
        "mute_upon_entry": mute_upon_entry,
        "show_share_button": false,
        "use_pmi": false,
        "encryption_type": "enhanced_encryption",
        "approval_type": canRegister ? 1 : 2,
        "registration_type": 1,
        "audio": "voip",
        "alternative_hosts": "",
        "meeting_authentication": false,
        "jbh_time": 10,
        "enforce_login_domains": "",
        "contact_email": "no-reply@aiomeet.com",
        "approved_or_denied_countries_or_regions": {
          "enable": false
        },
        "breakout_room": {
          "enable": true,
          "rooms": []
        }
      },
      recurrence,
    }
  }
}

module.exports = { createZoomPayload }