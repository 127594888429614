import React, { useEffect, useState } from "react";
import { Row, Col, Tabs, Menu, Modal, notification, Input, Button, Divider } from "antd";
import { Link } from "react-router-dom";
import MemberCard from "components/Widgets/MemberCard";
import AddCard from "components/Widgets/AddCard";
import EventCard from "components/Widgets/EventCard";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faPen, faTrash, faMousePointer, faWindowMaximize, faFile } from '@fortawesome/free-solid-svg-icons'
import useAcceptPendingMember from '../../../hooks/teams/useAcceptPendingMember';
import useRejectPendingMember from '../../../hooks/teams/useRejectPendingMember';
import useRemoveMemberFromTeam from '../../../hooks/teams/useRemoveMemberFromTeam';
import { WrappedRegisterGuest } from "./registerGuest";
import { BreakoutRequest } from "./BreakoutRequest";
import useZoomRegistration from '../../../hooks/meetings/useZoomRegistration'
import useSubscriptions from 'hooks/user/useSubscriptions'
import moment from 'moment'

const confirm = Modal.confirm;
const TabPane = Tabs.TabPane;
const Search = Input.Search;

const TeamDetailsSlide = (props) => {
  const authUser = localStorage.getItem('user_id')
  const { data: subscriptions } = useSubscriptions();
  const features = []
  subscriptions && subscriptions.forEach(item => features.push(...item.features))
  const canAddNotes = features.indexOf('notes') !== -1
  const { team } = props
  const isAdmin = team.admins.find(x => x.id === authUser) !== undefined;
  const isOwner = authUser === team.owner.id;
  const [mutateAcceptMember, { error: acceptError, data: acceptData, status: acceptStatus }] = useAcceptPendingMember(team.id);
  const [mutateRejectMember, { error: rejectError, data: rejectData, status: rejectStatus }] = useRejectPendingMember(team.id);
  const [mutateRemoveMember, { error: RemoveError, data: RemoveData, status: RemoveStatus }] = useRemoveMemberFromTeam(team);
  const [mutateRegisterMe, { status: regStatus, error: regError, data: regData }] = useZoomRegistration();
  const [state, setState] = useState(false);
  const [request, toggleRequest] = useState(false);
  /**
   * WE NEED ADD BETTER ERROR HANDLING
   */

  useEffect(() => {
    if (!regError && regData && regStatus === "success") {
      notification['success']({
        message: 'Guest registered!',
        description: `You have been successfully registered for the event.`,
      });
    }
    else if (regStatus === "error") {
      notification['error']({
        message: 'Guest registration Failed!',
        description: `Unable to register you, please try after some time or contact admin.`,
      });
    }

    if (acceptError) {
      notification['error']({
        message: 'Error!',
        description: acceptError.message,
      });
    }
  }, [regStatus, acceptError]);

  const [filter, setFilter] = React.useState("");
  const changeFilter = (param) => setFilter(param);
  const handleChange = event => {
    changeFilter(event.target.value);
  };
  const resetFilter = () => {
    changeFilter("");
  }
  const RenderUserCards = (subProps) => {
    const { status, addCard } = subProps
    let { members } = subProps
    const users = [];

    const menu = (pendingMem) => {
      return (
        <Menu onClick={(e) => menuClick(e.key, pendingMem)}>
          <Menu.Item key="accept" className="member-card-menu-accept-btn">
            <FontAwesomeIcon icon={faEdit} />&nbsp;
            <span>Accept</span>
          </Menu.Item>
          <Menu.Item key="decline" className="member-card-menu-decline-btn">
            <FontAwesomeIcon icon={faTrash} />&nbsp;
            <span className="gx-chart-down">Decline</span>
          </Menu.Item>
        </Menu>
      )
    }

    const menuClick = async (key, pendingMem) => {
      if (key === 'decline') {
        showDeleteConfirm(pendingMem);
      }
      if (key === 'accept') {
        await mutateAcceptMember(pendingMem)
      }
    }

    const showDeleteConfirm = async (team) => {
      (
        await confirm({
          title: 'Are you sure reject the member?',
          okText: 'Yes',
          okType: 'danger',
          cancelText: 'No',
          async onOk() {
            if (team.id) {
              await mutateRejectMember(team.id)
              notification['success']({
                message: 'Success!',
                description: `Member has been rejected`,
              });
            }
          },
          onCancel() {
            console.log('Cancel');
          },
        })
      )
    }

    const menuMember = (mem) => {
      return (
        <Menu onClick={(e) => menuClickMember(e.key, mem)}>
          <Menu.Item key="remove">
            <FontAwesomeIcon icon={faTrash} />&nbsp;
            <span className="gx-chart-down">Remove</span>
          </Menu.Item>
        </Menu>
      )
    }
    const menuClickMember = async (key, mem) => {
      if (key === 'remove') {
        showDeleteConfirmMember(mem);
      }
    }

    const showDeleteConfirmMember = async (mem) => {
      (
        await confirm({
          title: 'Are you sure remove the member?',
          okText: 'Yes',
          okType: 'danger',
          cancelText: 'No',
          async onOk() {
            if (mem.id) {
              await mutateRemoveMember(mem.id)
              notification['success']({
                message: 'Success!',
                description: `Member has been removed`,
              });
            }
          },
          onCancel() {
            console.log('Cancel');
          },
        })
      )
    }

    (isOwner) && addCard && addCard === true && status !== "Pending" && users.push(
      <Col key={Math.random()} xl={6} lg={12} md={12} sm={12} xs={24}>
        <AddCard elem="Member" team={team} />
      </Col>
    )
    if (status === "Pending") members = props.team.pendingMembers
    for (const key in members) {
      if (members.hasOwnProperty(key)) {
        const { firstName, lastName, email, phone, avatar, displayName, id } = members[key]
        const member = { firstName, lastName, email, phone, avatar, displayName, id }
        users.push(
          <Col key={Math.random()} xl={6} lg={12} md={12} sm={12} xs={24}>
            <MemberCard user={member} teamName={team.name} menu={(isAdmin || isOwner) && (status === "Pending" ? menu : menuMember)} status={status} />
          </Col>
        )
      }
    }
    return users
  }

  const RenderEventCards = (props) => {
    const { meetings, menu, status, addCard, teamId, isAdmin, isOwner, tab } = props
    const users = [];

    const showGoToMeetingConfirm = async (url) => {
      (
        await confirm({
          title: 'Are you sure you want to join the meeting?',
          okText: 'Yes',
          okType: 'danger',
          cancelText: 'No',
          async onOk() {
            if (url && url !== "") {
              const win = window.open(url, '_blank');
              win.focus();
            }
          },
          onCancel() {
            console.log('Cancel');
          },
        })
      )
    }

    const showRegisterMeConfirm = async (meeting) => {
      (
        await confirm({
          title: 'Are you sure you want to register yourself for this event/meeting?',
          okText: 'Yes',
          okType: 'danger',
          cancelText: 'No',
          async onOk() {
            if (meeting) {
              const teamPayload = { id: team.id, name: team.name }
              const payload = { registrant: "me", team: teamPayload }
              await mutateRegisterMe({ payload, meetingId: meeting.id, teamId: team.id })
            }
          },
          onCancel() {
            console.log('Cancel');
          },
        })
      )
    }
    const canViewBreakoutReq = () => {
      return (isOwner || isAdmin) ? true : false;
    }

    const meetingMenu = (meeting) => {
      return (
        <Menu onClick={(e) => menuClick(e.key, meeting)}>
          {((isOwner) && meeting.canRegister) && <Menu.Item className="gx-text-blue" key="breakoutRooms">
            <FontAwesomeIcon icon={faWindowMaximize} />&nbsp;
            <span><Link to={`./${teamId}/breakoutRooms/${meeting.id}`}>Breakout Rooms</Link></span>
          </Menu.Item>}
          {/* {canViewBreakoutReq() && <Menu.Item key="breakoutRequest">
            <FontAwesomeIcon icon={faFile} />&nbsp;
            <span>Breakout Request</span>
          </Menu.Item>} */}
          {((isAdmin || isOwner) && canAddNotes) && <Menu.Item key="addNote" className="meeting-card-menu-addNote-btn">
            <Link to={`/main/teams/${team.id}/addMeetingNote/${meeting.id}`}>
              <FontAwesomeIcon icon={faFile} />&nbsp;
              <span>Add Note</span>
            </Link>
          </Menu.Item>}
          {((isAdmin || isOwner) && canAddNotes) && <Menu.Item key="viewNotes" className="meeting-card-menu-viewNote-btn">
            <Link to={`/main/teams/${team.id}/meeting/${meeting.id}/viewNotes`}>
              <FontAwesomeIcon icon={faFile} />&nbsp;
              <span>View Notes</span>
            </Link>
          </Menu.Item>}
          {meeting.canRegister && meeting.provider && meeting.provider === "zoom" && <Menu.Item key="registerGuest" className="meeting-card-menu-registerGuest-btn">
            <FontAwesomeIcon icon={faPen} />&nbsp;
            <span>Register Guest</span>
          </Menu.Item>}
          {(meeting.canRegister && meeting.provider && meeting.provider === "zoom" && !isOwner && !meeting.myJoinLink) && <Menu.Item key="registerMe" className="meeting-card-menu-registerMe-btn">
            <FontAwesomeIcon icon={faPen} />&nbsp;
            <span>Register Me</span>
          </Menu.Item>}
          {meeting.myJoinLink && <Menu.Item className="gx-text-red meeting-card-menu-join-btn" key="join">
            <FontAwesomeIcon icon={faMousePointer} />&nbsp;
            <span>Join Meeting</span>
          </Menu.Item>}
        </Menu>
      )
    }
    const menuClick = async (key, meeting) => {
      if (key === 'registerGuest') {
        setState(meeting.id);
      }
      else if (key === 'registerMe') {
        //function to register
        showRegisterMeConfirm(meeting);
      }
      else if (key === 'join') {
        // redirect to link of meeting on new tab #meeting.url
        showGoToMeetingConfirm(meeting.myJoinLink)

      }
      else if (key === 'breakoutRooms') {
        //route to main/teams/:id/breakoutRooms
      }
      else if (key === 'breakoutRequest') {
        toggleRequest(true)
      }
    }

    if (meetings) {
      let anyTimeMeet = meetings.filter(m => !m.startTime)
      if (anyTimeMeet && anyTimeMeet.length > 0) {
        users.push(<Divider key={Math.random()} orientation="left">Anytime Meetings</Divider>)
        for (const mkey in anyTimeMeet) {
          if (anyTimeMeet.hasOwnProperty(mkey)) {
            const meetingElem = anyTimeMeet[mkey]
            users.push(
              <Col key={Math.random()} xl={8} lg={12} md={12} sm={12} xs={24}>
                {state && state === meetingElem.id && <WrappedRegisterGuest meeting={meetingElem} userId={authUser} teamId={team.id} teams={[team]} teamName={team.name} meetingId={meetingElem} updateRequest={false} onContactClose={setState} />}
                <EventCard meeting={meetingElem} isOwner={isOwner} isAdmin={isAdmin} menu={(tab === 'upcoming') ? meetingMenu : null} teamId={teamId} />
              </Col>
            )
          }
        }
      }
      let sortedByWeek = meetings.filter(m => m.startTime).reduce((res, meet) => {
        let startOfWeek = moment(meet.startTime, 'YYYY-MM-DDTHH:mm:ssZ').startOf('day').format('dddd, DD MMM YYYY');
        res[startOfWeek] = res[startOfWeek] || [];
        res[startOfWeek].push(meet);
        return res;
      }, {});
      let orderedDates = {};
      Object.keys(sortedByWeek).sort(function (a, b) {
        return moment(b, 'dddd, DD MMM YYYY').toDate() - moment(a, 'dddd, DD MMM YYYY').toDate();
      }).forEach(function (key) {
        orderedDates[key] = sortedByWeek[key];
      })
      for (const mkey in orderedDates) {
        if (orderedDates.hasOwnProperty(mkey)) {
          const today = moment().format("dddd, DD MMM YYYY");
          users.push(<Divider key={Math.random()} orientation="left">{mkey === today ? "Today, " + mkey : mkey}</Divider>)
          for (const skey in orderedDates[mkey]) {
            if (orderedDates[mkey].hasOwnProperty(skey)) {
              const meetingElem = orderedDates[mkey][skey]
              users.push(
                <Col key={Math.random()} xl={8} lg={12} md={12} sm={12} xs={24}>
                  {request && <BreakoutRequest team teams meeting toggleRequest={toggleRequest} />}
                  {state && state === meetingElem.id && <WrappedRegisterGuest meeting={meetingElem} userId={authUser} teamId={team.id} teams={[team]} teamName={team.name} meetingId={meetingElem} updateRequest={false} onContactClose={setState} />}
                  <EventCard meeting={meetingElem} isOwner={isOwner} isAdmin={isAdmin} menu={(tab === 'upcoming') ? meetingMenu : null} teamId={teamId} />
                </Col>
              )
            }
          }
        }
      }
    }

    // for (const key in meetings) {
    //   if (meetings.hasOwnProperty(key)) {
    //     // const { agenda, duration, host, speaker, startTime, endTime, timezone, topic, provider, registrants } = meetings[key]
    //     const meetingElem = meetings[key] 
    //     users.push(
    //       <Col key={Math.random()} xl={8} lg={12} md={12} sm={12} xs={24}>
    //         {state && state === meetingElem.id && <WrappedRegisterGuest meeting={meetingElem} userId={authUser} teamId={team.id} teams={[team]} teamName={team.name} meetingId={meetingElem} updateRequest={false} onContactClose={setState} />}
    //         <EventCard meeting={meetingElem} isOwner={isOwner} isAdmin={isAdmin} menu={(tab === 'upcoming') ? meetingMenu : null} teamId={teamId} />
    //       </Col>
    //     )
    //   }
    // }
    return users
  }
  let filteredMemberData = team.members
  if (filter !== "") {
    const lowercasedFilter = filter.toLowerCase();
    filteredMemberData = team.members.filter(item => {
      return Object.keys(item).some(key =>
        key !== "id" && item[key] !== null && item[key].toLowerCase().includes(lowercasedFilter)
      );
    });
  }
  let upcomingMeetings = []
  let pastMeetings = []
  const today = new Date().getTime() - (75 * 60 * 1000)
  if (team && team.meetings && team.meetings.length) {
    upcomingMeetings = team.meetings.filter(m => {
      if (new Date(m.startTime).getTime() >= today) {
        if (m.registrants) {
          m.members = m.registrants.filter(r => !r['Invited By'].name).length
          m.guests = m.registrants.filter(r => r['Invited By'].name).length
        }
        return m
      }
      if (!m.startTime) {
        return m
      }
    })
    pastMeetings = team.meetings.filter(m => {
      if (new Date(m.startTime).getTime() <= today) {
        const registrants = m.analytics || m.registrants
        if (registrants) {
          m.members = registrants.filter(r => !r['Invited By'].name).length
          m.guests = registrants.filter(r => r['Invited By'].name).length
        }
        return m
      }
    })
  }
  return (
    <Tabs defaultActiveKey="upcoming-meetings">
      <TabPane tab={`Upcoming (${upcomingMeetings.length})`} key="upcoming-meetings" className="team-tab-upcoming">
        <Row className="gx-mt-3">

          {(team.meetings && !team.meetings.length) && <h2 className="gx-ml-3">There are no upcoming meetings to show!</h2>}
          <RenderEventCards meetings={upcomingMeetings}
            teamId={team.id}
            addCard={true}
            isAdmin={isAdmin}
            isOwner={isOwner}
            tab="upcoming" />
        </Row>
      </TabPane>
      <TabPane tab={`Past (${pastMeetings.length})`} key="past-meetings" className="team-tab-past">
        <Row className="gx-mt-3">
          {(team.meetings && !pastMeetings.length) && <h2 className="gx-ml-3">There are no upcoming meetings to show!</h2>}
          <RenderEventCards meetings={pastMeetings}
            teamId={team.id}
            addCard={true}
            isAdmin={isAdmin}
            isOwner={isOwner}
            tab="past" />
        </Row>
      </TabPane>

      {(isOwner || isAdmin) && <TabPane tab={`Members (${team.members.length})`} key="members" className="team-tab-member">
        <Row style={{ display: 'flex' }} className="gx-mt-1 gx-pr-3">
          {(team && team.members && team.members.length > 3) && <span style={{ marginLeft: "auto" }} justify="flex-end">
            <Search
              placeholder="Looking for anyone .."
              onSearch={value => console.log(value)}
              onChange={handleChange}
              style={{ width: 222 }}
              className="gx-ml-3"
              value={filter}
            />
            {filter !== "" && <Button disabled={filter.length === 0} onClick={resetFilter} className="gx-mb-0 gx-ml-2 danger" shape="circle" icon="close" size='small' />}
          </span>}
        </Row>
        <Row className="gx-mt-1">
          <RenderUserCards members={filteredMemberData} status='Member' addCard={isOwner} />
        </Row>
      </TabPane>}
      {(isOwner || isAdmin) && <TabPane tab={`Pending Members (${team.pendingMembers.length})`} key="pending-members" className="team-tab-pendingMembers">
        <Row className="gx-mt-3">
          {team.pendingMembers.length > 0 ?
            <RenderUserCards members={team.members} status='Pending' addCard={true} />
            :
            <h2 className="gx-ml-3">There are no more pending members!</h2>
          }
        </Row>
      </TabPane>}
    </Tabs>
  );
}

export default TeamDetailsSlide;
