const createAIOMeetPayload = (meetData) => {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const { organizationId, teams, registrants, type = 2, prefix, topic, agenda = '', start_date, end_date, duration, password, canRegister, host, speaker, provider, joinUrl } = meetData;
  return {
    organizationId,
    provider: "aiomeet",
    topic,
    startTime: start_date,
    endTime: end_date,
    duration,
    timezone,
    canRegister: true,
    type,
    registrants
  }
}

module.exports = { createAIOMeetPayload }