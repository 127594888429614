import React from "react";
import { Button } from "antd";

import Widget from "components/Widget/index";

class FlyingBird extends React.Component {

  render() {
    const { app, appName, appSubtitle, appLogo, href, coming } = this.props;
    return (
      <Widget>
        <div className="gx-media gx-align-items-center gx-mb-4">
          <div className="gx-mr-3">
            <img src={require(`assets/images/appLogos/${appLogo || 'flying'}.png`)} width="50" alt='flying' />
          </div>
          <div className="gx-media-body">
            <h2 className="gx-mb-1">
              {appName || 'Flying bird'}
            </h2>
            <p className="gx-text-grey gx-mb-0">{appSubtitle || 'Bob Bush'}</p>
          </div>
        </div>
        {coming && <p className="gx-mb-4">Coming soon...</p>}
        {!coming && <p className="gx-mb-4">Connect the app to start using the supported features.</p>}

        {href && !app && <Button
          disabled={!href || coming ? 'disabled' : ''}
          type="primary"
          icon="plus"
          href={href}
          className="gx-mb-1" >
          Connect
        </Button>}

        {app && <Button disabled={!app || coming ? 'disabled' : ''} href={href} type="primary" icon="redo" className="gx-mb-1" htmlType="submit">
          Reconnect
        </Button>}
        {app && <Button disabled={!app || coming ? 'disabled' : ''} type="danger" icon="minus" className="gx-mb-1" htmlType="submit">
          Disconnect
        </Button>}
      </Widget >
    );
  }
};

export default FlyingBird;
