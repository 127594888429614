import { useQuery, useQueryCache } from "react-query";
import axios from '../../axiosWrapper'
import { query } from '../../util/config'

export default function useOrganizations() {
  const cache = useQueryCache();
  const orgs = cache.getQueryData(["organizations"]);
  return useQuery(
    ["organizations"],
    async () => await orgs || await axios.get('organizations'),
    query.initialQuery
  )
}
