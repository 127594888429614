import React, { useEffect } from "react";
import { Select, Tooltip, notification, message, Button } from "antd";
import Auxiliary from "util/Auxiliary";
import useAddAdminToTeam from '../../../hooks/teams/useAddAdminToTeam';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import _ from 'lodash';

const Option = Select.Option;

const AddAdmin = (props) => {
  const { teamAdmins, teamMembers, orgMembers, teamId } = props;
  const [addState, setState] = React.useState(false);
  const toggleAdd = () => setState(!addState);
  const [mutateAddAdmin, { error, data, status }] = useAddAdminToTeam(teamId);
  useEffect(() => {
    if (data && !addState && status === "success") {
      if (data && data === -1) {
        message.info("Selected member is already an admin!")
      }
      else if (!error) {
        notification['success']({
          message: 'Success!',
          description: `Successfully added admin!`,
        });
      }
      else {
        notification['warning']({
          message: 'Warning!',
          description: `Failed to add new admin! Retry later`,
        });
      }
    }
  }, [addState]);
  if (!(orgMembers && teamMembers)) return null;
  const existingAdmins = teamAdmins.map(admin => admin.id)
  const resultFromTeam = teamMembers.filter(o => existingAdmins.indexOf(o.id) === -1);
  const resultFromOrg = orgMembers.filter(o => existingAdmins.indexOf(o.id) === -1);
  const result = _.set(resultFromOrg, resultFromTeam)
  async function handleChange(value) {
    const adm = result.find(mem => mem.id === value)
    await mutateAddAdmin(adm)
    toggleAdd();
  }
  return (
    <Auxiliary>
      {!teamAdmins.length && <h3>Add members to your team then you can assign them as your team admin.</h3>}
      {addState && <Select className="gx-mr-2"
        showSearch
        style={{ width: 200 }}
        placeholder="Add member to Admins"
        optionFilterProp="children"
        onChange={handleChange}
        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
        {result.map(mem => <Option key={mem.id} value={mem.id}>{mem.firstName + " " + mem.lastName}</Option>)}
      </Select>}
      {addState && <Tooltip className="gx-mb-0"  key={Math.random(3)} title={`${"Cancel"}`}>
        {status !== 'loading' && <Button disabled={result.length === 0} onClick={toggleAdd} className="gx-aio-gray-btn gx-mb-0" shape="circle" icon="close" size='default' />}
        {status === 'loading' && <FontAwesomeIcon size="lg" className="fa-spin" icon={faSpinner} />}
      </Tooltip>}
      {!addState && <Tooltip className="gx-mb-0" key={Math.random(3)} title={`${"Add Admin"}`}>
        <Button disabled={result.length === 0} onClick={toggleAdd} className={result.length === 0 ? '' : 'gx-aio-gray-btn'} shape="circle" icon="plus" size='default' />
      </Tooltip>}
    </Auxiliary>
  );
};

export default AddAdmin;
