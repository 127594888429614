import { useMutation, useQueryCache } from "react-query";
import axios from '../../axiosWrapper'
import { query } from '../../util/config'

export default function useFetchNotes() {
  const cache = useQueryCache();
  return useMutation((id) => axios.get(`notes`), {
    onSuccess: async (notes) => {
      cache.setQueryData(["notes"], notes)
    }
  }, query.initialQuery)
}
