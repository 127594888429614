import { useMutation } from "react-query";
import axios from 'axios'
import config,{ query } from '../../util/config'

export default function useResetPassword() {
  return useMutation(async (newCred)=>{
    try {
      const { data } = await axios.post(`${config.baseURL}/auth/reset-password`, newCred)
      return data
    } catch (error) {
      console.log("🚀 ~ file: useResetPassword.js ~ line 11 ~ returnuseMutation ~ error", error)
      throw new Error(error)
    }
  },query.initialQuery)
}