import React from "react";
import { Col, Row } from "antd";
import Auxiliary from "util/Auxiliary";
import { zoomConnect } from 'util/config'
import * as QueryString from "query-string"
import CircularProgress from "components/CircularProgress/index";
import useZoomConnect from '../../../../hooks/connect/useZoomConnect'

const Zoom = (props) => {
  const { code } = QueryString.parse(props.location.search);
  const { status, isLoading } = useZoomConnect(code);

  return (
    <Auxiliary>
      {isLoading && <div className="gx-loader-view ant-modal-mask">
        <CircularProgress className='gx-h1-lg' />
      </div>}
      {status === 'success' && <h2>Successfully added the zoom app.</h2>}
    </Auxiliary>
  );
};

export default Zoom;
