import React from "react";
import { Link } from "react-router-dom";
import IntlMessages from "util/IntlMessages";
import Kick401 from "../../../assets/images/401.png"

const Error401 = () => (
  <div className="gx-page-error-container">
    <div className="gx-page-error-content">
      <div className="gx-error-code gx-mt-4">401</div>
      <h3 className="gx-text-center">
        <IntlMessages id="extraPages.401Msg" />
      </h3>
      <img src={Kick401} />
      <p className="gx-text-center">
        <Link className="gx-btn gx-btn-primary" to="/user/myprofile"><IntlMessages id="extraPages.goProfile" /></Link>
      </p>
    </div>
  </div>
);

export default Error401;
