import {
  FETCH_START,
  FETCH_SUCCESS,
  FETCH_ERROR,
  GET_ALL_USER_MEETINGS_SUCCESS,
  GET_ALL_MEETINGS_REGISTRANTS_SUCCESS,
  UPDATE_REGISTRANTS_SUCCESS,
  FILTER_REGISTRANTS_SUCCESS,
  FAIL_TO_GET_DATA
} from "../../../constants/ActionTypes";
// import meeting from '../../../services/meeting'

export const onGetAllUserRegistrants = (meetingId) => {
  return (dispatch) => {
    const authUser = localStorage.getItem('user_id')
    dispatch({ type: FETCH_START });
    if (meetingId && authUser) {
      // meeting.getRegistrants(meetingId)
      //   .then(resp => {
      //     dispatch({ type: FETCH_SUCCESS });
      //     const { registrants } = resp.data
      //     dispatch({
      //       type: GET_ALL_MEETINGS_REGISTRANTS_SUCCESS,
      //       payload: registrants
      //     });
      //   }).catch(err => {
      //     console.log(err)
      //     dispatch({ type: FETCH_ERROR });
      //     dispatch({ type: FAIL_TO_GET_DATA });
      //   })
    }
    dispatch({ type: FETCH_ERROR });
    dispatch({ type: FAIL_TO_GET_DATA });
  };
};

export const onGetAllUserMeetings = (userId) => {
  return (dispatch) => {
    const authUser = localStorage.getItem('user_id')
    dispatch({ type: FETCH_START });
    if (authUser && userId) {
      // meeting.getMeetings(userId)
      //   .then(resp => {
      //     dispatch({ type: FETCH_SUCCESS });
      //     const { meetings } = resp.data
      //     dispatch({
      //       type: GET_ALL_USER_MEETINGS_SUCCESS,
      //       payload: meetings
      //     });
      //   }).catch(err => {
      //     console.log(err)
      //     dispatch({ type: FETCH_ERROR });
      //     dispatch({ type: FAIL_TO_GET_DATA });
      //   })
    }
    dispatch({ type: FETCH_ERROR });
    dispatch({ type: FAIL_TO_GET_DATA });
  };
};

export const updateRegistrants = (allRegistrants) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    const renderRegistrants = allRegistrants
    dispatch({ type: FETCH_SUCCESS });
    dispatch({
      type: UPDATE_REGISTRANTS_SUCCESS,
      payload: { renderRegistrants }
    });
  };
}

export const filterRegistrants = (allRegistrants, key, filterBy) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    const registrants = allRegistrants.filter(r => r[key] === filterBy)
    dispatch({ type: FETCH_SUCCESS });
    dispatch({
      type: FILTER_REGISTRANTS_SUCCESS,
      payload: registrants
    });
  };
}

export const dataFeched = () => {
  return (dispatch) => { dispatch({ type: FAIL_TO_GET_DATA }); }
}