import { useMutation, useQueryCache } from "react-query";
import axios from '../../axiosWrapper'
import { query } from '../../util/config'

export default function useCreateTeam() {
  const cache = useQueryCache();
  return useMutation(inviteData => {
    return axios.post('invites', inviteData), 
  {
    onSuccess: (data) => {
    console.log("🚀 ~ file: useSendInviteRequest.js ~ line 8 ~ useCreateTeam ~ team", data)
    }
  }, query.initialQuery
})
}