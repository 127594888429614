import { useMutation, useQueryCache } from "react-query";
import axios from '../../axiosWrapper'
import { query } from '../../util/config'

export default function useRejectPendingMember(id) {
  const cache = useQueryCache();
  const team = cache.getQueryData(["teams", id]);
  if (team) {
    team.pendingMembers = team && team.pendingMembers ? team.pendingMembers : [];
    team.members = team && team.members ? team.members : [];
  }
  return useMutation(async (memId) => {
    let pendingIds = team.pendingMembers.map(pending => pending.id)
    if (pendingIds.includes(memId)) {
      team.pendingMembers = [...team.pendingMembers.filter(p => p.id !== memId)]
      const uniquePending = team.pendingMembers.map(item => item.id).filter((value, index, self) => self.indexOf(value) === index)

      return await axios.put(`teams/${id}`, { pendingMembers: uniquePending })
    }
    return -1;
  }, {
    onSuccess: (updated) => {
      if (updated && updated.id) {
        cache.setQueryData(["teams", updated.id], (prev) => {
          prev.pendingMembers = team.pendingMembers;
          return prev;
        })
      }
    }
  }, query.initialQuery)
}
