import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { fetchProfileSuccess } from '../actions/user/Profile';
import { db, auth } from '../../firebase/firebase';
import { GET_USER_PROFILE, UPDATE_USER_PROFILE, SIGNIN_USER_SUCCESS } from '../../constants/ActionTypes';
import { fetchError } from "../actions/Common"
import userService from "../../api/user"
const authUser = localStorage.getItem('user_id')

const getUserProfile = async (uid) => {
  if (!uid) uid = localStorage.getItem('user_id')
  if (uid) {
    return {
      profile: {},
      config: {},
      defaultSetting: {},
      teamsMetadata: [],
      meetsMetadata: {},
      roles: []
    }
  }
}

const updateUserProfile = async ({ user }) => {
  if (auth.currentUser && user) {
    const userRef = db.collection("users").doc(auth.currentUser.uid)
    return await userRef.set(user).then(function () {
      console.log("User updated");
      return user
    }).catch(function (error) {
      console.log("Error getting document:", error);
    });
  }
}

function* fetchUserRequest() {
  try {
    const uid = authUser || localStorage.getItem('user_id')
    const fetchedUser = yield call(getUserProfile, uid);
    if (fetchedUser && fetchedUser.defaultSetting && fetchedUser.defaultSetting.meetingId) {
      //disabled getting meetings registrant on refresh
      // yield put(onGetAllUserRegistrants(fetchedUser.defaultSetting.meetingId))
    }
    yield put(fetchProfileSuccess(fetchedUser));
  } catch (error) {
    console.log(error)
    yield put(fetchError(error));
  }
}

function* fetchUserRequestOnSuccess() {
  try {
    const uid = authUser || localStorage.getItem('user_id')
    const fetchedUser = yield call(getUserProfile, uid);
    yield put(fetchProfileSuccess(fetchedUser));
  } catch (error) {
    console.log(error)
    yield put(fetchError(error));
  }
}


export function* fetchUser() {
  yield takeEvery(GET_USER_PROFILE, fetchUserRequest);
}

export function* fetchUserOnSuccess() {
  yield takeEvery(SIGNIN_USER_SUCCESS, fetchUserRequestOnSuccess);
}

export function* updateUser() {
  yield takeEvery(UPDATE_USER_PROFILE, updateUserProfile);
}

export default function* rootSaga() {
  yield all([fork(fetchUser), fork(updateUser), fork(fetchUserOnSuccess)]);
}
